export default class Globals {
	static OAuthClientId = process.env.REACT_APP_OAUTH_CLIENT_ID;
	static OAuthClientSecret = process.env.REACT_APP_OAUTH_CLIENT_SECRET;
	static Stage = process.env.NODE_ENV;
	static GoogleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
	static GA = process.env.REACT_APP_GOOGLE_ANALYTICS;
	static GAEnabled = (process.env.REACT_APP_GOOGLE_ANALYTICS_ENABLED === 'true') || (process.env.REACT_APP_GOOGLE_ANALYTICS_ENABLED === true);
	static GAOptOutVar = `ga-disable-${Globals.GA}`;
	static FbAppId = process.env.REACT_APP_FB_APP_ID;
	static FbAPIVersion = process.env.REACT_APP_FB_API_VERSION;
	static GoogleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
	static GoogleMapsDefaultCenter = { lat: 38.0138902, lng: 23.7833828 };
	static BookingEnabled = process.env.REACT_APP_BOOKING_ENABLED === 'true';
	static EthereumNodeURL = process.env.REACT_APP_ETHEREUM_NODE_URL;
	static EdlEnabled = process.env.REACT_APP_EDL_ENABLED === 'true';
	static NotarizationEnabled = process.env.REACT_APP_NOTARIZATION_ENABLED === 'true'
}