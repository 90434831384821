// @flow
import { useMemo, useState } from 'react';
import { Roles } from 'auth';
import { Form, Formik } from 'formik';
import useI18n from 'hooks/i18n/useI18n';
import { Link, useLocation } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import FormHelperText from '@mui/material/FormHelperText/FormHelperText';
import { Button } from 'components/general/button';
import useSocialLogin from 'components/auth/social/useSocialLogin';
import SocialButtonGoogle from 'components/auth/social/social-button-google';
import SocialButtonTwitter from 'components/auth/social/social-button-twitter';
import SocialButtonFacebook from 'components/auth/social/social-button-facebook';
import { ElyCheckboxField, ElyPasswordField, ElyTextField } from 'components/general/input/fields';
import 'scss/components/auth/join/join-business-form.scss';

type Props = {
  role: string,
  onSubmit: Object => void,
};
const InitialValues = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  repeatPassword: '',
  acceptTerms: false,
  reCaptcha: false
};

const JoinBusinessForm = ({ role, onSubmit }: Props) => {
  const { t } = useI18n();
  const location = useLocation();
  const [error, setError] = useState(undefined);
  const { onFbLogin, onTwitterToken, onGoogleLogin } = useSocialLogin(role, location);
  const roleLocal = useMemo(() => role?.replace("role.", ""), [role]);
  const termsLabel = useMemo(() => (
    <label htmlFor="acceptTerms">
      I accept the <Link className="terms-link" target="_blank" to={`/legal/${roleLocal}/terms`}>Terms and Conditions</Link>
    </label>
  ), [roleLocal]);

  return (
    <Formik
      initialValues={{...InitialValues, role}}
      validate={validate}
      onSubmit={values =>
        onSubmit(values)
          .catch(err => setError(err.response.status))
      }
    >
      {
        ({ errors, setFieldValue }) => (
          <Form className="JoinBusinessForm">
            <h2 className="JoinBusinessForm__title">Register to Experly</h2>
            <ElyTextField
              required={true}
              name="firstName"
              label="First Name"
            />
            <ElyTextField
              required={true}
              name="lastName"
              label="Last Name"
            />
            <ElyTextField
              required={true}
              name="email"
              label="Email"
            />
            <ElyPasswordField
              name="password"
              label="Password"
            />
            <ElyPasswordField
              name="repeatPassword"
              label="Repeat Password"
            />
            <div className="JoinBusinessForm__accept">
              <ElyCheckboxField
                id="acceptTerms"
                name="acceptTerms"
                label={termsLabel}
                onChange={e => setFieldValue("acceptTerms", e.currentTarget.checked)}
              />
            </div>
            <div className="JoinBusinessForm__captcha">
              <ReCAPTCHA
                onChange={value => setFieldValue("reCaptcha", value)}
                sitekey="6LdPRnQUAAAAAJr-BJzIePOJoA7z2_N7QqaXuCLn"
              />
              { !!errors["reCaptcha"] && <FormHelperText className="u-color-problem">{errors["reCaptcha"]}</FormHelperText> }
            </div>
            <div className="JoinBusinessForm__submit">
              {
                error &&
                <span className="login-register__submit__error">{t(`register.form.error.${error}`)}</span>
              }
              <Button type="submit" style={{width:'100%'}}>Register</Button>
            </div>
            <div className="JoinBusinessForm__social">
              <span className="JoinBusinessForm__social__text"> Or you can join with</span>
              <div className="JoinBusinessForm__social__wrapper">
                <div className="JoinBusinessForm__social__item facebook-login-icon">Login with
                  <SocialButtonFacebook
                    scope='email'
                    onConnect={onFbLogin}
                  />
                </div>
                <div className="JoinBusinessForm__social__item twitter-login-icon">Login with
                  <SocialButtonTwitter
                    role={role}
                    onConnect={onTwitterToken}
                  />
                </div>
                <div className="JoinBusinessForm__social__item google-login-icon">Login with <SocialButtonGoogle onClick={onGoogleLogin}/></div>
              </div>
            </div>
            <div className="JoinBusinessForm__login">
              <span>Already have an account? <Link to="/auth/login">Login</Link></span>
            </div>
          </Form>
        )
      }
    </Formik>
  );
}

function validate(values) {
  const errors = {};

  if ( values.role !== Roles.USER ) {
    if (!values.firstName) {
      errors.firstName = 'First Name is required!';
    } else if ( values.firstName.length < 2 ) {
      errors.firstName = 'First Name should be at least 2 characters';
    }
    if (!values.lastName) {
      errors.lastName = 'Last Name is required!';
    } else if ( values.lastName.length < 2 ) {
      errors.lastName = 'Last Name should be at least 2 characters';
    }
  }

  if (!values.email) {
    errors.email = 'Email is required!';
  }
  // eslint-disable-next-line
  else if ( !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(values.email) ) {
    errors.email = 'Invalid email address!';
  }
  if (!values.password) {
    errors.password = 'Enter Password!';
  } else if ( values.password.length < 8 ) {
    errors.password = 'Password must be at least 8 characters!';
  }
  if (!values.repeatPassword) {
    errors.repeatPassword = 'Enter Password again!';
  }
  if ( values.password && values.repeatPassword && values.password !== values.repeatPassword ) {
    errors.repeatPassword = 'Passwords do not match!';
  }
  if (!values.acceptTerms) {
    errors.acceptTerms = 'You must read and accept the Terms and Conditions!';
  }
  if (!values.reCaptcha) {
    errors.reCaptcha = 'Please verify that you are not a robot!';
  }
  return errors;
}

export default JoinBusinessForm;