// @flow
import type { PhotoProps } from 'types/general/photo-props';
import { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { getUserImagePlaceholder, transformPhoto } from 'utils/transform/transform-media';
import Button from 'components/general/button';
import 'scss/components/auth/register/registration-photo.scss';

type Props = {
	onUpload: Object => Promise<any>,
	onDelete: string => Promise<any>,
	onSkip: void => void,
	onNext: string => void,
	t: string => string,
};

const RegistrationPhoto = ({ onDelete, onUpload, onSkip, onNext, t }: Props): React$Node => {
	const [photo, setPhoto] = useState<PhotoProps>(getUserImagePlaceholder('profile'));
	const onDrop = (acceptedFiles, rejectedFiles, options) => {
		onUpload(acceptedFiles, rejectedFiles, options)
			.map(promise => promise.then(res => setPhoto(transformPhoto(res.data, "profile"))));
	};

	const onDeleteLocal = () => {
		onDelete(photo.key)
			.then(() => setPhoto(getUserImagePlaceholder('profile')));
	}

	const { getRootProps, getInputProps, open } = useDropzone({ onDrop, onDrag: false, noClick: true, multiple: false });
	return (
		<div className="RegistrationPhoto">

			<div className="RegistrationPhoto__dropzone" {...getRootProps()}>
				<input {...getInputProps()} />
				<img
					src={photo.thumbnail}
					alt={photo.alt}
					className="RegistrationPhoto__dropzone__img"
				/>
			</div>

			{
				(photo.key === 'placeholder') && (
					<>
						<Button onClick={open} style={{width:'100%'}}>{t('registration.photo.upload')}</Button>
						<Button onClick={onSkip} style={{width:'100%'}} color="hollow">{t('registration.photo.skip')}</Button>
					</>
				)
			}

			{
				(photo.key !== 'placeholder') && (
					<>
						<Button onClick={onDeleteLocal} style={{width:'100%'}} color="warning">{t('registration.photo.delete')}</Button>
						<Button onClick={() => onNext(photo.filename)} style={{width:'100%'}}>{t('registration.photo.next')}</Button>
					</>
				)
			}
		</div>
	);
}

export default RegistrationPhoto;
