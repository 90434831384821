// @flow
import withI18n from 'hoc/with-i18n';
import Icon from 'components/general/icon';
// import Emote from 'components/general/emote';
import 'scss/components/public/profile/profile-status.scss';

type ProfileStatusProps = {
	status: string,
	tag: Object,
	location?: Object,
	onEdit?: void => vod,
	className?: string,
	t: string => string,
}

const ProfileStatus = ({ status, tag, location, onEdit, className = '', t }: ProfileStatusProps) => (
	<div className={`profile-status ${className}`}>
		<div className="profile-status-feeling">
			<span>{status ?? ''}</span>
			{/*<Emote*/}
			{/*	code={tag?.title?.toLowerCase()}*/}
			{/*	title={tag?.title}*/}
			{/*	className="profile-status-feeling__emote"*/}
			{/*/>*/}
			<button type="button" onClick={onEdit}>
				<Icon code="edit" />
			</button>
		</div>
		{
			location?.city &&
			<div className="profile-status-location">
				<span>
					{t('profile.status.location.in')}
					<span className="icon-pin">{location.city}</span>
				</span>
			</div>
		}
	</div>
);

export default withI18n(ProfileStatus);