// @flow
import type { RoleProps } from 'auth/types';
import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMe } from 'auth';
import AuthUtils from 'auth/AuthUtils';
import useI18n from 'hooks/i18n/useI18n';
import { routes } from 'utils/routes';
import MediaType from 'utils/variables/media-type';
import { useImageApi } from 'hooks/general/image';
import { Backend, get, post, put } from 'actions/backend';
import { TagGroup } from 'utils/variables/tag-group-vars';
import { transformTag } from 'utils/transform/transform-tag';
import { AuthTypes } from 'components/auth/auth-utils';
import AuthLayout from 'components/auth/auth-layout';
import { useAuthModal } from 'components/auth/auth-modal-context';
import RegistrationForm from 'components/auth/register/registration-form';
import RegistrationName from 'components/auth/register/registration-name';
import RegistrationPhoto from 'components/auth/register/registration-photo';
import RegistrationInterests from 'components/auth/register/registration-interests';
import RegistrationSuccess from 'components/auth/register/registration-success';
import 'scss/components/auth/register/registration-wizard.scss';

const Steps = {
	CREDENTIALS: {
		code: 'credentials',
		next: 'NAME',
	},
	NAME: {
		code: 'name',
		next: 'PHOTO',
	},
	PHOTO: {
		code: 'photo',
		next: 'INTERESTS',
	},
	INTERESTS: {
		code: 'interests',
		next: 'SUCCESS',
	},
	SUCCESS: {
		code: 'success',
	}
};

type Props = {
	role: RoleProps,
	isModal?: boolean,
};

const RegistrationWizard = ({ role, isModal = true }: Props): React$Node => {
	const { t } = useI18n();
	const me = useMe();
	const navigate = useNavigate();
	const [step, setStep] = useState(Steps.CREDENTIALS);
	const { onUpload, onDelete } = useImageApi();
	const [interestOptions, setInterestOptions] = useState();
	const { openAuth, closeAuth } = useAuthModal();

	useEffect(() => {
		get(Backend.COMMONS_TAGS_GROUPS, { groups: [
				TagGroup.ACTIVITIES_INTERESTS,
				TagGroup.FOOD_AND_BEVERAGE,
				TagGroup.CUISINE_TYPE,
			]})
			.then(res => setInterestOptions(
				res.data
					.flatMap(group => group.tags.map(tag => ({ ...tag, tagGroup: { key: group.key, code: group.code } })))
					.map(tag => transformTag(tag))
			));
	}, []);

	const onRegister = useCallback(values => {
		return post(Backend.REGISTER, values, { showErrors: false })
			.then(res => AuthUtils.load(res.data))
			.then(() => setStep(state => Steps[state.next]));
	}, []);

	const onName = useCallback(values => {
		return put(Backend.PROFILE_DISPLAY_NAME, values, { showErrors: false })
			.then(res => AuthUtils.loadIdToken(res.data.id_token))
			.then(() => setStep(state => Steps[state.next]))
	}, []);

	const onPhoto = useCallback((acceptedFiles, rejectedFiles, options) => {
		return onUpload([{
			file: acceptedFiles[0],
			owner: me?.id,
			mediaType: MediaType.IMAGE,
			override: true,
		}]);
			// .map(promise => promise.then(() => setStep(state => Steps[state.next])));
	}, [me?.id, onUpload]);

	const onInterests = useCallback(values => {
		return put(Backend.PROFILE_INTERESTS, { interests: values }, { showErrors: false })
			.then(() => setStep(state => Steps[state.next]))
	}, []);

	const onSuccess = useCallback(() => {
		navigate(routes.HOME);
		closeAuth();
	}, [navigate, closeAuth]);

	return (
		<AuthLayout
			title={t('registration.title')}
			subtitle={t(`registration.${step.code}.title`)}
			description={step.code !== Steps.SUCCESS.code ? t(`registration.${step.code}.description`): undefined}
			onClose={isModal ? closeAuth : undefined}
			hideTopHeader={step.code === Steps.SUCCESS.code}
			className={`RegistrationWizard RegistrationWizard--${step.code}`}
		>
			{
				Steps.CREDENTIALS.code === step.code &&
				<RegistrationForm role={role} onSubmit={onRegister} onLogin={() => openAuth(AuthTypes.LOGIN)} t={t} />
			}
			{
				Steps.NAME.code === step.code &&
				<RegistrationName onSubmit={onName} t={t} />
			}
			{
				Steps.PHOTO.code === step.code &&
				<RegistrationPhoto
					onUpload={onPhoto}
					onDelete={key => onDelete(key, { disableConfirmation: true })}
					onNext={photo => {
						me.updatePhoto(photo);
						setStep(state => Steps[state.next]);
					}}
					onSkip={() => setStep(state => Steps[state.next])}
					t={t}
				/>
			}
			{
				Steps.INTERESTS.code === step.code &&
				<RegistrationInterests options={interestOptions} onSubmit={onInterests} t={t} />
			}
			{
				Steps.SUCCESS.code === step.code &&
				<RegistrationSuccess image={me?.photo?.thumbnail} onSubmit={onSuccess} t={t} />
			}
		</AuthLayout>
	);
};

export default RegistrationWizard;
