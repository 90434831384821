import { useReducer, useCallback } from 'react';
import { transformPublicUsers } from 'utils/transform/transform-user';

export const LIMIT = 10;
const InitialPayload = { data: [], offset: 0, hasMore: undefined, total: undefined };
const userReducer = (state, action) => {
	switch (action.type) {
		case 'init':
			return {
				data: transformPublicUsers(action.payload.content),
				offset: state.offset + LIMIT,
				hasMore: !action.payload.last,
				total: action.payload.totalElements
			}
		case 'load':
			return {
				data: [...state.data, ...transformPublicUsers(action.payload.content)],
				offset: state.offset + LIMIT,
				hasMore: !action.payload.last,
				total: action.payload.totalElements
			};
		case 'update':
			return {
				...state,
				data: action.payload
			};
		case 'reset':
			return InitialPayload;
		default:
			throw new Error();
	}
}

export default function usePeopleReducer() {
	const [users, dispatchUsers] = useReducer(userReducer, InitialPayload);

	const initUsers = useCallback(payload => {
		dispatchUsers({ type:'init', payload });
	}, [])

	const loadUsers = useCallback(payload => {
		dispatchUsers({ type:'load', payload });
	}, []);

	const updateUsers = useCallback(payload => {
		dispatchUsers({ type:'update', payload });
	}, []);

	const resetUsers = useCallback(() => {
		dispatchUsers({ type:'reset' });
	}, []);

	return {
		users,
		initUsers,
		loadUsers,
		updateUsers,
		resetUsers,
		dispatchUsers
	};
}