// @flow
import type { TagProps } from 'types/general/tag-props';
import { useCallback, useState } from 'react';
import Button from 'components/general/button';
import 'scss/components/auth/register/registration-interests.scss';

type Props = {
	options: Array<TagProps>,
	onSubmit: Object => Promise<any>,
	t: string => string,
};

const RegistrationInterests = ({ options, onSubmit, t }: Props): React$Node => {
	const [selected, setSelected] = useState<Array<TagProps>>([]);
	const selectedKeys = selected.map(interest => interest.key);

	const onInterestClick = useCallback((option) => {
		setSelected(state => {
			const isActive = Number(state.filter(interest => interest.key === option.key).length) > 0;
			return isActive
				? state?.filter(int => int.key !== option.key)
				: [...state, option];
		});
	}, []);

	return (
		<div className="RegistrationInterests">

			<div className="RegistrationInterests__container">
				{
					options.map(option => {
						const isActiveClass = selectedKeys.includes(option.key) ? 'is-active' : '';
						return (
							<Button
								key={option.key}
								color=""
								className={`RegistrationInterests__item ${isActiveClass}`}
								onClick={() => onInterestClick(option)}
							>
								{option.title}
							</Button>
						);
					})
				}
			</div>

			<Button onClick={() => onSubmit(selected)} {...(selected.length < 5 ? {disabled: true} : {})} style={{width:'100%'}} className="RegistrationInterests__submit">
				{ selected.length < 5 && t('registration.interests.pick', { remaining: 5 - selected.length })}
				{ selected.length >= 5 && t('registration.interests.submit')}
			</Button>
		</div>
	);
};

export default RegistrationInterests;
