// @flow
import "scss/components/general/account/settings/interest.scss";

type Props = {
	code: string,
	title: string,
	className?: string
};

const Interest = ({ code, title, className }: Props): React$Node => (
	<div className={`interest interest--${code} ${className ?? ''}`}>
		{/* TODO: Interest labels should probably be translated */}
		{title}
	</div>
);

export default Interest;