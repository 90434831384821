// @flow
import { useState, useEffect, forwardRef } from 'react';
import withI18n from 'hoc/with-i18n';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import useAuthActions from 'auth/useAuthActions';
import useClickOutside from 'hooks/general/useClickOutside';
import Icon from 'components/general/icon';
import { Button } from 'components/general/button';
import { ButtonLink } from 'components/general/link';
import ProfileUser from 'components/public/profile/profile-user';
import ProfilePhotos from 'components/public/profile/profile-photos';
import ProfileFriends from 'components/public/profile/profile-friends';
import ProfileAchievements from 'components/public/profile/profile-achievements';
import ProfileFeeling from 'components/public/profile/profile-feeling';
import ProfileTabHistory from 'components/public/profile/profile-tab-history';
import ProfileTabFriends from 'components/public/profile/profile-tab-friends';
import ProfileSettingsItem from 'components/public/profile/profile-settings-item';
import ProfileTabContributions from 'components/public/profile/profile-tab-contributions';
import experlyLogo from 'img/logo-experly-white.svg';
import 'scss/components/public/profile/user-profile.scss';

type Props = {
	isLoggedIn: boolean,
	profile: Object,
	isMe: boolean,
	isFriend: Object,
	feel: Object,
	feelings: ?Array<Object>,
	tabProps: Object,
	achievements?: ?Array<Object>,
	addFriend: string => void,
	removeFriend: string => void,
	acceptFriend: (string, string) => void,
	ignoreFriend: (string, string) => void,
	onStatusSubmit: Object => void,
	history: Object,
	onLoadHistory: void => void,
	contributions: Object,
	onLoadContributions: void => void,
	t: string => string
};

const TabLink = forwardRef((props, ref) => <Link {...props} ref={ref} />);

const UserProfile = ({
	isLoggedIn,
	profile,
	isMe,
	isFriend,
	feel,
	feelings,
	achievements,
	addFriend,
	removeFriend,
	acceptFriend,
	ignoreFriend,
	tabProps,
	onStatusSubmit,
	history,
	onLoadHistory,
	contributions,
	onLoadContributions,
	friends,
	t,
}: Props): React$Node => {
	const { doLogout } = useAuthActions();
	const [isSettingsOpen, setSettingsOpen] = useState<boolean>(false);
	const { elementRef, addListener, removeListener } = useClickOutside(() => setSettingsOpen(false));

	useEffect(() => {
		if (isSettingsOpen) addListener();
		else removeListener();
	}, [isSettingsOpen, addListener, removeListener]);

	return (
		<>
			<Helmet>
				<title>{`${profile.name} | Experly`}</title>
				<meta property="og:title" content={`${profile.name} | Experly`}/>
				<meta property="og:description" name="description" content={profile.name}/>
				<meta property="og:image" content={profile.profilePhoto}/>
				<meta property="og:url" content={encodeURI(profile.link)}/>
			</Helmet>
			<div className="profile">
				<div className="profile__header">
					<div className="profile__header__inner">
						<Link to="/" className="logo">
							<img
								src={experlyLogo}
								alt="experly logo"
								className="img"
							/>
						</Link>
						{
							isLoggedIn && isMe && (
								<div className="profile__settings">
									<button type="button" className="settings-btn" onClick={() => setSettingsOpen(state => !state)}>
										<Icon code="settings" />
									</button>
									{
										isSettingsOpen &&
										<div className="modal">
											<div ref={elementRef} className="modal__content">
												<ul className="nav">
													<ProfileSettingsItem icon="edit" title={t('profile.settings.edit')} href="/settings/profile/edit" />
													<ProfileSettingsItem icon="notification" title={t('profile.settings.notifications')} href="/notifications" />
													<ProfileSettingsItem icon="favourite" title={t('profile.settings.favourites')} href="/favourites" />
													<ProfileSettingsItem icon="tag" title={t('profile.settings.tags')} href="/tags" />
													<ProfileSettingsItem icon="settings3" title={t('profile.settings.settings')} href="/settings" />
													<ProfileSettingsItem icon="contribute" title={t('profile.settings.contribute')} href="/contribute" />
												</ul>
												<div className="settings-divider" />
												<Link to="/cms">{t('profile.settings.switch')}</Link>
												<div className="settings-divider" />
												<button type="button" onClick={doLogout}>{t('profile.settings.logout')}</button>
											</div>
										</div>
									}
								</div>
							)
						}
					</div>
				</div>

				<div className="profile__main">
					<div className="profile__left">
						<ProfileUser
							isLoggedIn={isLoggedIn}
							isMe={isMe}
							isFriend={isFriend}
							image={profile.profilePhoto}
							name={profile.name}
							location={profile.location}
							points={profile.points}
							feel={feel}
							contributions={profile.contributionsOverview}
							bio={profile.bio}
							socials={profile.socialLinks}
							interests={profile.interests}
							feelings={feelings}
							onStatusSubmit={onStatusSubmit}
							addFriend={() => addFriend('profile', profile.key)}
							removeFriend={() => removeFriend('profile', profile.key, profile.name)}
							acceptFriend={() => acceptFriend('profile', profile.key, isFriend.token)}
							ignoreFriend={() => ignoreFriend('profile', profile.key, isFriend.token, profile.name)}
						/>
						{
							achievements?.length > 0 &&
							<ProfileAchievements
								achievements={achievements}
								count={23}
							/>
						}
						{
							profile.friends?.length > 0 &&
							<ProfileFriends
								friends={profile.friends}
								count={profile.friendsCount}
								allPath={`${profile.path}/friends`}
							/>
						}
						{
							// TODO: (EXP-732) Temporarily hide photos section in user profile,
							//  until full photo functionality has been discussed and developed.
							false &&
							<ProfilePhotos
								photos={profile.photos}
								count={profile.photosCount}
							/>
						}
					</div>

					<div className="profile__right">
						{
							!isLoggedIn &&
							<div className="profile__new-card">
								<h2>{t('profile.new.title')}</h2>
								<p>{t('profile.new.descr')}</p>
								<ButtonLink to="/auth/register/user">{t('profile.new.register')}</ButtonLink>
							</div>
						}
						{
							isLoggedIn && !isMe && !isFriend?.active &&
							<div className="profile__non-friend-card">
								<h2>{t('profile.non.friend.title')}</h2>
								<p>{t('profile.non.friend.descr')}</p>
								{
									!isFriend &&
									<Button styling={["small", "round"]} onClick={() => addFriend(profile.key)}>{t('profile.non.friend.connect')}</Button>
								}
								{
									isFriend && !isFriend.active && isFriend.isOutbound &&
									<Button
										color="hollow"
										styling={["small", "round"]}
										onClick={() => removeFriend('profile', profile.key, profile.name)}
									>
										{t('profile.user.cancel.friend')}
									</Button>
								}
								{
									isFriend && !isFriend.active && !isFriend.isOutbound &&
									<>
										<Button
											color="hollow"
											styling={["small", "round"]}
											className="accept"
											onClick={() => acceptFriend(profile.key, isFriend.token)}
										>
											{t('profile.user.accept.friend')}
										</Button>
										<Button
											color="hollow"
											styling={["small", "round"]}
											onClick={() => ignoreFriend('profile', profile.key, isFriend.token, profile.name)}
										>
											{t('profile.user.ignore.friend')}
										</Button>
									</>
								}
							</div>
						}
						{
							isLoggedIn && (isMe || isFriend?.active) &&
							<>
								{
									isMe &&
									<ProfileFeeling
										feelings={feelings}
										onSubmit={onStatusSubmit}
										className="profile__status-update--desktop"
									/>
								}
								<div className="profile__tabs">
									<Tabs
										value={tabProps.current}
										onChange={(e, selected) => tabProps?.setTab(selected)}
									>
										<Tab key={0} label={t('profile.actions.tab.all')} to={tabProps?.all?.to} component={TabLink} />
										<Tab key={1} label={t('profile.actions.tab.contributions')} to={tabProps?.contributions?.to} component={TabLink} />
										<Tab key={2} label={t('profile.actions.tab.friends')} to={tabProps?.friends?.to} component={TabLink} />
									</Tabs>
								</div>
								{
									tabProps?.current === 0 &&
									<ProfileTabHistory
										data={history.data}
										hasMore={history.hasMore}
										onLoadMore={onLoadHistory}
									/>
								}
								{
									tabProps?.current === 1 &&
									<ProfileTabContributions
										data={contributions.data}
										hasMore={contributions.hasMore}
										onLoadMore={onLoadContributions}
									/>
								}
								{
									tabProps?.current === 2 &&
									<ProfileTabFriends
										isMe={isMe}
										total={friends.total}
										friends={friends.data}
										hasMore={friends.hasMore}
										onLoadMore={friends.onLoadMore}
										onSearchFriends={friends.onSearchFriends}
										addFriend={addFriend}
										removeFriend={removeFriend}
										acceptFriend={acceptFriend}
										ignoreFriend={ignoreFriend}
									/>
								}
							</>
						}
					</div>
				</div>
			</div>
		</>
	);
}

export default withI18n(UserProfile);