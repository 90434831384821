// @flow
import { useState, useEffect } from 'react';
import { Globals } from 'utils/variables';
import loadGoogleMapsAPI from 'components/general/map/load-google-maps-api';

export default function useGoogleApi():?Object {
	const [google, setGoogle] = useState();

	//
	// Load the google maps api
	//
	useEffect(() => {
		let isMounted = true
		loadGoogleMapsAPI({ apiKey: Globals.GoogleMapsApiKey, libraries: ['places'] })
			.then(google => isMounted && setGoogle(google))
		return () => { isMounted = false; };
	}, []);

	return google;
}
