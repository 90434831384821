import { useMemo } from 'react';
import { ElyDateRangePicker } from 'components/general/input';

const DefaultNames = {
	start: 'start',
	end: 'end',
}

const FormikDateRangePicker = ({ names = DefaultNames, field, form, ...props } ) => {
	const { start: startName, end: endName } = names;
	const value = useMemo(() => [form.values[startName] ?? null, form.values[endName] ?? null], [startName, endName, form.values]);
	return (
		<ElyDateRangePicker
			{...field}
			{...props}
			value={value}
			errors={form.errors}
			onChange={([startValue, endValue]) => {
				form.setFieldValue(startName, startValue);
				form.setFieldValue(endName, endValue)
				form.setFieldTouched(startName, true);
				form.setFieldTouched(endName, true);
			}}
			onClose={() => {
				form.setFieldTouched(startName, true);
				form.setFieldTouched(endName, true);
				props.onClose?.();
			}}
		/>
	);
}

export default FormikDateRangePicker;