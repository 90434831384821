// @flow
import { getPickerInstance } from 'utils/date';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro';
import { LocalizationProvider } from '@mui/x-date-pickers';

type ElyDateRangePickerProps = {
	names?: {
		start?: string,
		end?: string,
	},
	value?:Object,
	labels?: {
		start?: string,
		end?: string,
	},
	placeholders?: {
		start?: string,
		end?: string,
	},
	disabled?:boolean,
	shouldDisableDate?:boolean,
	disablePast?:boolean,
	InputProps?: Object,
	inputFormat?: string,
	variant?: 'standard' | 'outlined' | 'filled',
	classes?: {
		start?: string,
		end?: string,
	},
	className?: string,
	open?: boolean,
	onChange?: SyntheticEvent<HTMLInputElement> => void,
	onBlur?: SyntheticEvent<HTMLInputElement> => void,
	onOpen?: SyntheticEvent<HTMLInputElement> => void,
	onClose?: SyntheticEvent<HTMLInputElement> => void
}

const InputField = props => {
	let value = '';
	if ( // Check if there is a value for start or end. if yes set the value else leave it undefined
		(props.ownerState.position === 'start' && props.ownerState.value[0] !== null) ||
		(props.ownerState.position === 'end' && props.ownerState.value[1] !== null)
	) {
		value = props.value;
	}
	return (
		<TextField
			{...props}
			value={value}
		/>
	)
}

const ElyDateRangePicker = ({
	names = {},
	value,
	labels = {},
	placeholders = {},
	disabled,
	shouldDisableDate,
	disablePast = false,
	InputProps  = {},
	inputFormat = 'DD/MM/YYYY',
	variant = 'standard',
	classes = { start: '', end: ''},
	className = '',
	open,
	onBlur,
	onChange,
	onOpen,
    onClose,
}: ElyDateRangePickerProps): React$Node => (
	<LocalizationProvider dateAdapter={AdapterDayjs}>
		<DateRangePicker
			localeText={labels}
			value={[getPickerInstance(value?.[0]), getPickerInstance(value?.[1])]}
			open={open}
			onBlur={onBlur}
			onChange={onChange}
			onOpen={onOpen}
			onClose={onClose}
			disabled={disabled}
			disablePast={disablePast}
			shouldDisableDate={shouldDisableDate}
			format={inputFormat}
			slots={{
				textField: InputField,
				fieldSeparator: () => null
			}}
			slotProps={{
				textField: props => ({
					InputProps: InputProps,
					name: props.position === 'start' ? names.start : names.end,
					placeholder: props.position === 'start' ? (placeholders.start ?? inputFormat) : (placeholders.end ?? inputFormat),
					className: props.position === 'start' ? classes?.start : classes?.end,
					helperText: "",
					error: false,
					variant
				}),
				fieldRoot: props => ({
					className
				})
			}}
			className={className}
		/>
	</LocalizationProvider>
);

export default ElyDateRangePicker;