import React from 'react';
import isEmpty from 'lodash/isEmpty';
import Select from 'react-select';
import FormControl from '@mui/material/FormControl'
import NoSsr from '@mui/material/NoSsr';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import CancelIcon from '@mui/icons-material/Cancel';
import FormHelperText from '@mui/material/FormHelperText/FormHelperText';
import OptionalLabel from 'components/general/input/ely/optional-label';

const customStyles = {
	indicatorsContainer: (provided) => ({
		...provided,
		indicator: (provided) => ({
			...provided,
			cursor: 'pointer',
		})
	}),
	valueContainer: (provided, state) => ({
		display: 'flex',
		flexWrap: 'wrap',
		flex: 1,
		alignItems: 'center',
		overflow: 'hidden',
	}),
}

const inputComponent = React.forwardRef((props, ref) => (
	<div ref={ref} {...props}>
		{props.children}
	</div>
));

function Control(props) {
	return (
		<TextField
			fullWidth
			InputProps={{
				inputComponent,
				inputProps: {
					children: props.children,
					...props.innerProps,
				},
			}}
			variant="standard"
			{...props.selectProps.textFieldProps}
			sx={{
				"& .MuiInput-input": {
					display: 'flex',
					padding: 0,
					height: 'auto'
				}
			}}
		/>
	);
}

function MultiValue(props) {
	return (
		<Chip
			tabIndex={-1}
			label={props.children}
			onDelete={props.removeProps.onClick}
			deleteIcon={<CancelIcon {...props.removeProps} />}
			sx={{
				margin: theme => `${theme.spacing(1/2)} ${theme.spacing(1/4)}`,
			}}
		/>
	);
}

const ElyReactSelect = ({
	name,
	label,
	placeholder,
	value,
	options,
	keyProp = "value",
	descrProp = "label",
	keepObjectAsValue,
	isMulti,
	required,
	error,
	warning,
	classes = {},
	theme,
	onChange,
	onBlur,
	...other
}) => {
	const optionsFormatted = options.map(opt => {
		const value = keepObjectAsValue ? opt : opt[keyProp];
		return ({ label: opt[descrProp], value });
	});
	const values = isMulti
		? ( !isEmpty(value) && !isEmpty(optionsFormatted) )
			? value.map(val => ({
				label: optionsFormatted
					.filter(opt => keepObjectAsValue
						? opt.value[keyProp] === val[keyProp]
						: opt.value === val
					)[0]
					?.label,
				value: val
			}))
			: []
		: ( value && !isEmpty(optionsFormatted) )
			? { label: optionsFormatted.filter(opt => opt.value === value)[0].label, value: value }
			: null;

	const shrink = !!placeholder ? true : isMulti ? !isEmpty(value) : !!value;
	const textFieldProps = !!label
		? {
			label: <OptionalLabel label={label} required={required} />,
			InputLabelProps: { shrink }
		}
		: {};

	return (
		<FormControl error={!!error}>
			<NoSsr>
				<Select
					placeholder={placeholder || ''}
					value={values}
					options={optionsFormatted}
					isMulti={isMulti}
					components={{ Control, MultiValue }}
					textFieldProps={textFieldProps}
					onBlur={onBlur}
					onChange={values => isMulti
						? (values && values.length > 0
							? onChange(values.map(option => option.value))
							: onChange([]))
						: onChange(values.value)
					}
					classes={classes}
					styles={customStyles}
					{...other}
				/>
				{ error && <FormHelperText className="u-color-problem">{error}</FormHelperText> }
				{ warning && <FormHelperText className="u-color-warning">{warning}</FormHelperText> }
			</NoSsr>
		</FormControl>
	);
};

export default ElyReactSelect;
