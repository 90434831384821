import { Field } from 'formik';
import {
	FormikText,
	FormikNumber,
	FormikPassword,
	FormikListText,
	FormikSelect,
	FormikCheckbox,
	FormikRadioGroup,
	FormikDatepicker,
	FormikTimePicker,
	FormikDateRangePicker,
	FormikLocationAutocomplete,
	FormikSearchSelect,
	FormikSearch,
} from 'components/general/input/formik';

export const ElyTextField = props => <Field {...props} component={FormikText} />;
export const ElyNumberField = props => <Field {...props} component={FormikNumber} />;
export const ElyPasswordField = props => <Field {...props} component={FormikPassword} />;
export const ElyListTextField = props => <Field {...props} component={FormikListText} />;
export const ElySelectField = props => <Field {...props} component={FormikSelect} />;
export const ElyCheckboxField = props => <Field {...props} component={FormikCheckbox} />;
export const ElyRadioGroupField = props => <Field {...props} component={FormikRadioGroup} />;
export const ElyDatepickerField = props => <Field {...props} component={FormikDatepicker} />;
export const ElyTimePickerField = props => <Field {...props} component={FormikTimePicker} />;
export const ElyDateRangePickerField = props => <Field {...props} component={FormikDateRangePicker} />;
export const ElyLocationAutocompleteField = props => <Field {...props} component={FormikLocationAutocomplete} />;
export const ElySearchSelectField = props => <Field {...props} component={FormikSearchSelect} />;
export const FormikSearchField = props => <Field {...props} component={FormikSearch} />;
