import { useEffect, useCallback } from 'react';
import { Backend, post, put, del, get } from 'actions/backend';
import usePeopleReducer, { LIMIT } from 'hooks/public/people/usePeopleReducer';
import confirm from 'components/general/modal/confirm';

export default function useProfileFriends(key, isMe, updateProfileConnection) {
	const { users: friends, loadUsers, updateUsers, resetUsers } = usePeopleReducer();

	//
	// Add Friend
	//
	const addFriend = useCallback((context, friendKey) => {
		post(Backend.PROFILE_FRIENDS, null, {params:friendKey})
			.then(res => {
				if ( context === 'profile ') {
					updateProfileConnection({
						active: false,
						isOutbound: true,
						token: res.data
					})
				}
				if ( context === 'friends' ) {
					const friendsNew = friends.data.map(friend => {
						if ( friend._key === friendKey ) {
							return ({
								...friend,
								isFriend: {
									token: res.data,
									active: false,
									isOutbound: true
								}
							})
						}
						return friend;
					})
					updateUsers(friendsNew);
				}
			});
	}, [friends, updateUsers, updateProfileConnection]);

	//
	// Remove Friend
	//
	const removeFriend = useCallback((context, friendKey, friendName) => {
		confirm({message:`Are you sure you want to unfriend ${friendName}?`})
			.then(() => {
				del(Backend.PROFILE_FRIENDS, friendKey)
					.then(() => {
						if ( context === 'profile' ) {
							updateProfileConnection(undefined);
						}
						if ( context === 'friends' ) {
							if ( isMe ) {
								const friendsNew = friends.data.filter(friend => friend._key !== friendKey);
								updateUsers(friendsNew);
							}
							else {
								const friendsNew = friends.data.map(friend => friend._key === friendKey ? ({ ...friend, isFriend: undefined }) : friend)
								updateUsers(friendsNew);
							}
						}
					});
			});
	}, [isMe, friends, updateUsers, updateProfileConnection]);

	//
	// Accept Friend Request
	//
	const acceptFriend = useCallback((context, friendKey, token) => {
		put(Backend.PROFILE_FRIENDS_ACCEPT, null, {params:token})
			.then(() => {
				if ( context === 'profile' ) {
					updateProfileConnection({active: true});
				}
				if ( context === 'friends' ) {
					const friendsNew = friends.data.map(friend => friend._key === friendKey ? ({ ...friend, isFriend: { active: true }}) : friend);
					updateUsers(friendsNew);
				}
			});
	}, [friends, updateUsers, updateProfileConnection]);

	//
	// Ignore Friend Request
	//
	const ignoreFriend = useCallback((context, friendKey, token, friendName) => {
		confirm({message:`Are you sure you want to unfriend ${friendName}?`})
			.then(() => {
				del(Backend.PROFILE_FRIENDS_IGNORE, token)
					.then(() => {
						if ( context === 'profile' ) {
							updateProfileConnection(undefined)
						}
						if ( context === 'friends' ) {
							const friendsNew = friends.data.map(friend => friend._key === friendKey ? ({ ...friend, isFriend: undefined }) : friend);
							updateUsers(friendsNew);
						}
					});
			});
	}, [friends, updateUsers, updateProfileConnection]);

	//
	// Load friends
	//
	const onLoadFriends = useCallback(() => {
		get(Backend.PROFILE_FRIENDS_GET(key), { offset:friends.offset, limit:LIMIT })
			.then(res => loadUsers(res.data));
	}, [friends, key, loadUsers]);

	//
	// Reset friends on key change
	//
	useEffect(() => {
		resetUsers();
	}, [key, resetUsers]);

	return {
		friends,
		onLoadFriends,
		addFriend,
		removeFriend,
		acceptFriend,
		ignoreFriend
	}
}