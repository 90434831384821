import NotificationClose from './notification-close';

import "scss/components/shared/notifications.scss"

const NotificationHeader = props =>
	<header className="u-notification__header">
		<h3>{props.title}</h3>
		<NotificationClose onClose={props.onClose}/>
	</header>;

export default NotificationHeader;