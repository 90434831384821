//
// General types
//
export const ACTION_IS_LOADING                          = 'IS_LOADING';
export const ACTION_HAS_ERRORS                          = 'HAS_ERRORS';
export const ACTION_SET_ERRORS                          = 'SET_ERRORS';
export const ACTION_ADD_ERROR                           = 'ADD_ERROR';
export const ACTION_REMOVE_ERROR                        = 'REMOVE_ERROR';
export const ACTION_IS_ASIDE_OPEN                       = 'IS_ASIDE_OPEN';
export const ACTION_IS_GOOGLE_MAP_LOADING               = "IS_GOOGLE_MAP_LOADING";
export const ACTION_IS_GOOGLE_MAP_LOADED                = "IS_GOOGLE_MAP_LOADED";

//
// Auth types
//
export const SET_ME                                     = 'SET_ME';
export const RESET_ME                                   = 'RESET_ME';
export const REFRESHING_TOKEN                           = 'REFRESHING_TOKEN';
export const DONE_REFRESHING_TOKEN                      = 'DONE_REFRESHING_TOKEN';