// @flow
import { Routes, Route } from 'react-router-dom';
import useI18n from 'hooks/i18n/useI18n';
import { CmsNav, CmsNavLink } from 'components/cms/general/nav';
import ProfilePeopleSearchWrapper from 'components/public/profile/people/profile-people-search-wrapper';
import ProfilePeopleRequestsWrapper from 'components/public/profile/people/profile-people-requests-wrapper';
import ProfilePeopleSentWrapper from 'components/public/profile/people/profile-people-sent-wrapper';
import "scss/components/public/profile/people/profile-people-router.scss";

const ProfilePeopleRouter = (): React$Node => {
	const { t } = useI18n();
	return (
		<div className="profile-people">
			<CmsNav className="profile-people__aside">
				<CmsNavLink to="/profile/people/search">{t('profile.friends.add.link')}</CmsNavLink>
				<CmsNavLink to="/profile/people/requests">{t('profile.friends.requests.link')}</CmsNavLink>
				<CmsNavLink to="/profile/people/sent">{t('profile.friends.sent.link')}</CmsNavLink>
			</CmsNav>
			<div className="profile-people__content">
				<Routes>
					<Route path="search" element={<ProfilePeopleSearchWrapper />} />
					<Route path="requests" element={<ProfilePeopleRequestsWrapper />} />
					<Route path="sent" element={<ProfilePeopleSentWrapper />} />
				</Routes>
			</div>
		</div>
	);
}

export default ProfilePeopleRouter;
