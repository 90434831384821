import { getIn } from 'formik';
import { ElyNumber } from 'components/general/input/index';

const FormikNumber  = ({ field, form: { touched, errors }, ...other }) => {
	const error = (getIn(touched, field.name) && getIn(errors, field.name));
	return (
		<ElyNumber
			{...field}
			{...other}
			error={error}
		/>
	);
};

export default FormikNumber;