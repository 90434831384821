// @flow
import { useRef, useCallback } from 'react';

type onClickOutsideFunc = void => void;
type ReturnProps = {
	elementRef: React$Ref,
	onClickOutside: void => void,
	addListener: void => void,
	removeListener: void => void,
};

export default function useClickOutside(onClickOutside: onClickOutsideFunc): ReturnProps {
	const elementRef = useRef(null);

	const onClickOutsideLocal = useCallback((e: MouseEvent) => {
		if ( elementRef?.current?.contains(e.target) ) return;
		document.removeEventListener("mousedown", onClickOutsideLocal);
		onClickOutside && onClickOutside();
	}, [elementRef, onClickOutside]);

	const addListener = useCallback(() => document.addEventListener("mousedown", onClickOutsideLocal), [onClickOutsideLocal]);
	const removeListener = useCallback(() => document.removeEventListener("mousedown", onClickOutsideLocal), [onClickOutsideLocal]);

	return {
		elementRef,
		onClickOutside,
		addListener,
		removeListener
	};
}
