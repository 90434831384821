function geocoderFormat(results) {

	let country = results[0].address_components.filter(ac => ac.types[0] === 'country');
	let locality = results[0].address_components.filter(ac => ac.types[0] === 'locality');
	let admin1 = results[0].address_components.filter(ac => ac.types[0] === 'administrative_area_level_1');
	let admin2 = results[0].address_components.filter(ac => ac.types[0] === 'administrative_area_level_2');
	let admin3 = results[0].address_components.filter(ac => ac.types[0] === 'administrative_area_level_3');
	let admin4 = results[0].address_components.filter(ac => ac.types[0] === 'administrative_area_level_4');
	let admin5 = results[0].address_components.filter(ac => ac.types[0] === 'administrative_area_level_5');
	let postCode = results[0].address_components.filter(ac => ac.types[0] === 'postal_code');
	let route = results[0].address_components.filter(ac => ac.types[0] === 'route');
	let number = results[0].address_components.filter(ac => ac.types[0] === 'street_number');

	const countryISO = country.length > 0 && country[0] ? country[0].short_name : null;

	country = country.length > 0 && country[0] ? country[0].long_name : null;
	locality = locality.length > 0 && locality[0] ? locality[0].long_name : null;
	admin1 = admin1.length > 0 && admin1[0] ? admin1[0].long_name : null;
	admin2 = admin2.length > 0 && admin2[0] ? admin2[0].long_name : null;
	admin3 = admin3.length > 0 && admin3[0] ? admin3[0].long_name : null;
	admin4 = admin4.length > 0 && admin4[0] ? admin4[0].long_name : null;
	admin5 = admin5.length > 0 && admin5[0] ? admin5[0].long_name : null;
	postCode = postCode.length > 0 && postCode[0] ? postCode[0].long_name : '';
	route = route.length > 0 && route[0] ? route[0].long_name : '';
	number = number.length > 0 && number[0] ? number[0].long_name : null;

	const city = locality || admin5 || admin4 || admin3 || admin2 || admin1 || '';

	return {
		country,
		countryISO,
		city,
		postCode,
		street: number ? route + ' ' + number : route,
		geolocation: {
			latitude: results[0].geometry.location.lat(),
			longitude: results[0].geometry.location.lng()
		}
	};
}

export { geocoderFormat };