// @flow
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

type Props = {
	name: string,
	label: string,
	value: string,
	options: Array<{
		label: string,
		value: string,
	}>,
	className?: string,
	classes?: {
		label?: Object,
		group?: Object,
		radio?: Object,
	},
	onChange?: SyntheticEvent<HTMLInputElement> => void,
};

const ElyRadioGroup = ({ name, label, value, options, onChange, className = '', classes }: Props): React$Node => (
	<FormControl component="fieldset" className={className}>
		<FormLabel component="legend" classes={classes?.label}>{label}</FormLabel>
		<RadioGroup name={name} value={value} onChange={onChange} classes={classes?.group}>
			{
				options?.map(option => (
					<FormControlLabel
						key={option.value}
						label={option.label}
						value={option.value}
						control={<Radio />}
						classes={classes?.radio}
						className={value === option.value ? 'checked' : ''}
					/>
				))
			}
		</RadioGroup>
	</FormControl>
);

export default ElyRadioGroup;
