import { useState, useEffect } from 'react';
import { loginRefresh } from 'auth';
import { InitialJwt } from 'auth/JwtContext'
import { InitialIdToken } from 'auth/IdTokenContext';
import AuthUtils from 'auth/AuthUtils';
import AuthService from 'auth/AuthService';

export default function useInitializeAuth() {
	const [jwt, setJwt] = useState(InitialJwt);
	const [idToken, setIdToken] = useState(InitialIdToken);
	const [initialized, setInitialized] = useState(false);

	//
	// Initialize authentication in first land
	//
	useEffect(() => {
		// Initialize AuthService
		AuthUtils.service = new AuthService(setJwt, setIdToken);

		// Try refresh token in case we have an active one
		AuthUtils.refreshPromise = loginRefresh()
			.finally(() => {
				AuthUtils.refreshPromise = null;
				setInitialized(true);
			});

		// Add listener to localStorage to trigger logout in the non active tabs
		const syncLogout = event => {
			if ( event.key === 'logout' ) {
				AuthUtils.logout(false);
			}
		}
		window.addEventListener('storage', syncLogout)
		return () => {
			window.removeEventListener('storage', syncLogout);
		}
	}, []);

	return {
		jwt : { ...jwt, initialized },
		idToken: { ...idToken, initialized }
	};
}