// @flow
const Permission = {
    ADMIN: 'ADMIN',
    READ: 'READ',
    WRITE: 'WRITE',

    // Activity
    POI_MANAGE: 'POI_MANAGE',
    POI_DELETE: 'POI_DELETE',

	// User Experience
	USER_EXPERIENCE_REVIEW: 'USER_EXPERIENCE_REVIEW',
	USER_EXPERIENCE_EDIT: 'USER_EXPERIENCE_EDIT',
	USER_EXPERIENCE_DELETE: 'USER_EXPERIENCE_DELETE',
};

export default Permission;
