// @flow
import { useState } from 'react';
import ModalTrigger from 'components/general/modal/modal-trigger';
import ModalWindow from 'components/general/modal/modal-window';

type Props = {
	triggerText: string,
	className?: string,
	triggerClass?: string,
	children: Object => React$Node,
};

const Modal = ({ triggerText, className, triggerClass, children }: Props): React$Node => {
    const [isOpen, setIsOpen] = useState(false);
    const onOpen = () => setIsOpen(true);
    const onClose = () => setIsOpen(false);
    return (
        <>
            <ModalTrigger label={triggerText} className={triggerClass} onOpen={onOpen}/>
            <ModalWindow isOpen={isOpen} classes={{ modal: className }}>
                {children({ onClose })}
            </ModalWindow>
        </>
    )
};

export default Modal;
