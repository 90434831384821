import { Component } from 'react';
import { Button } from 'components/general/button';

import 'scss/components/shared/small-components-used-throught-site/modal-confirm.scss';

const modal = document.getElementById('ely-modal');

class ConfirmModal extends Component {

    componentDidMount() {
        modal.classList.add('is-modal-open');
    }

    componentWillUnmount() {
        modal.classList.remove('is-modal-open');
    }

    onConfirmClick() {
        this.props.resolve();
        this.props.dispose();
    }

    onCancelClick() {
        this.props.reject();
        this.props.dispose();
    }

    render() {
        const { hasChoice = true, confirmButton = `Yes`, cancelButton = `No` } = this.props;
        return (
            <div className="modal-confirm">
                <div className="modal-confirm__wrapper">
                    <div className="modal-confirm__description">
                        <div>{this.props.message}</div>
                    </div>
                    <div className="modal-confirm__answer-wrapper">
                        {hasChoice &&
                        <>
                            <div className="">
                                <Button color="success" onClick={() => this.onConfirmClick()}>{confirmButton}</Button>
                            </div>
                            <div className="">
                                <Button color="warning" onClick={() => this.onCancelClick()}>{cancelButton}</Button>
                            </div>
                        </>
                        }
                        {!hasChoice &&
                        <div className="">
                            <Button color="success" onClick={() => this.onCancelClick()}>Ok</Button>
                        </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default ConfirmModal;