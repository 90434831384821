// @flow
import type { AuthProps } from 'auth/types';
import { InitialJwt } from 'auth/JwtContext';
import { InitialIdToken } from 'auth/IdTokenContext';
import setAuthHeader from 'utils/axios-header';

class AuthService {
	accessToken: string = null;
	expiration: number = null;
	authorities: Array<string> = [];
	permissions: Array<string> = [];
	setJwt: Object => void;
	setIdToken: Object => void;

	constructor(setJwt: Object => void, setIdToken: Object => void) {
		this.setJwt = setJwt;
		this.setIdToken = setIdToken;
	}

	load({ jwt, idToken }: AuthProps, config = {}): AuthProps {
		// If there is already set an accessToken that means,
		// we have already loaded the idToken and there is no need to load it again when refreshing the token
		// const shouldRefreshId = !this.accessToken;
		const { shouldLoadId = true } = config;

		// Save in memory access_token and refresh_token
		this.accessToken = jwt.accessToken;
		this.expiration = jwt.expiration;
		this.authorities = jwt.authorities;
		this.permissions = jwt.permissions;

		// Set access_token to axios headers
		setAuthHeader(jwt.accessToken);

		// Make suer to call the context setters last because they will trigger re-renders
		// Set jwt and id_token in their context
		this.setJwt(jwt);
		shouldLoadId && !!idToken && this.setIdToken(idToken);

		return { jwt, idToken }
	}

	logout(notifyStorage:boolean = true): void {
		// Reset in memory access_token and refresh_token
		this.accessToken = null;
		this.expiration = null;
		this.refreshToken = null;
		this.refreshExpiration = null;
		this.authorities = [];
		this.permissions = [];
		this.setJwt(InitialJwt);
		this.setIdToken(InitialIdToken);

		// Remove access_toke from axios headers
		setAuthHeader(false);

		// Call signOut in google api
		if ( window.gapi && window.gapi.auth2 ){
			let auth2 = window.gapi.auth2.getAuthInstance();
			auth2 && auth2.signOut().then(() => console.log('User signed out.'));
		}

		// Notify local storage to logout from all tabs
		if ( notifyStorage ) {
			window.localStorage.setItem('logout', Date.now().toString());
		}
	}
}

export default AuthService;