// @flow
import type { InterestProps } from 'types/user-props';
import Button from 'components/general/button';
import { ModalWindow } from 'components/general/modal';
import Interest from 'components/general/account/settings/interest';
import travelIcon from 'img/interests/travel.png';
import "scss/components/general/account/settings/personal-interests-select.scss";

type Props = {
	values: Array<string>,
	interestOptions: Array<InterestProps>,
	isOpen: boolean,
	onClick: Object => void,
	onClose: void => void
};

const PersonalInterestsSelect = ({ values, interestOptions = [], isOpen, onClick, onClose }: Props): React$Node => (
	<ModalWindow isOpen={isOpen} classes={{ modal: 'PersonalInterestsSelect__modal', content: 'PersonalInterestsSelect__content' }}>
		<span className="PersonalInterestsSelect__title">Pick your Interests</span>
		<div className="PersonalInterestsSelect__items">
			{
				interestOptions.map(option => {
					const isActiveClass = values.includes(option.key) ? 'is-active' : '';
					return (
						<Button
							key={option.key}
							color=""
							className={`PersonalInterestsSelect__items__item ${isActiveClass}`}
							onClick={() => onClick(option)}
						>
							<Interest
								key={option.key}
								code={option.code}
								title={option.title}
								img={travelIcon}
							/>
						</Button>
					);
				})
			}
		</div>
		<div className="PersonalInterestsSelect__buttons">
			<Button className="ok" onClick={onClose}>Ok</Button>
		</div>
	</ModalWindow>
);

export default PersonalInterestsSelect;
