import { useCallback } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { Backend, del, post, put } from 'actions/backend';
import confirm from 'components/general/modal/confirm';

export default function usePeopleSocialActions(users, update) {

	//
	// Add user as friend
	//
	const addFriend = useCallback(key => {
		post(Backend.PROFILE_FRIENDS, null, { params:key })
			.then(res => {
				const usersNew = cloneDeep(users)
					.map(user => {
						if ( user._key === key ) {
							return ({
								...user,
								isFriend: {
									active: false,
									isOutbound: true,
									token: res.data
								}
							})
						}
						return user;
					});
				update(usersNew);
			});
	}, [users, update]);

	//
	// Remove user from friends
	//
	const removeFriend = useCallback((key, name) => {
		confirm({message:`Are you sure you want to unfriend ${name}?`})
			.then(() => {
				del(Backend.PROFILE_FRIENDS, key)
					.then(() => {
						const usersNew = cloneDeep(users).map(user => user._key === key ? ({ ...user, isFriend: undefined }) : user);
						update(usersNew);
					});
			});
	}, [users, update]);

	//
	// Accept user's friends request
	//
	const acceptFriend = useCallback(token => {
		put(Backend.PROFILE_FRIENDS_ACCEPT, null, { params:token })
			.then(() => {
				const usersNew = cloneDeep(users).map(user =>
					user.isFriend.token === token ? ({ ...user, isFriend: { active:true, isOutbound:false, token:undefined } }) : user
				);
				update(usersNew);
			});
	}, [users, update]);

	//
	// Ignore user's friends request
	//
	const ignoreFriend = useCallback((token, name) => {
		confirm({message:`Are you sure you want to ignore ${name}?`})
			.then(() => {
				del(Backend.PROFILE_FRIENDS_IGNORE, token)
					.then(() => {
						const usersNew = cloneDeep(users).map(user =>
							user.isFriend.token === token ? ({ ...user, isFriend: undefined }) : user
						);
						update(usersNew);
					});
			});
	}, [users, update]);

	return { addFriend, removeFriend, acceptFriend, ignoreFriend };
}