// @flow
import { Link } from 'react-router-dom';
import experlyLogo from 'img/logo-experly.svg';
import LayoutMainMenu from 'components/layout/main/layout-main-menu';
import 'scss/components/layout/main/layout-main-header.scss';

type Props = {
	profileLink: string,
	profileImage?: string,
};

const LayoutMainHeader = ({ profileLink, profileImage }: Props): React$Node => (
	<header className="LayoutMainHeader">
		<Link to="/" className="LayoutMainHeader__logo">
			<img
				src={experlyLogo}
				alt="experly logo"
				className="img"
			/>
		</Link>
		<LayoutMainMenu
			profileLink={profileLink}
			profileImage={profileImage}
			isDesktop
		/>
	</header>
);

export default LayoutMainHeader;
