// @flow
import { useMemo } from 'react';
import { getButtonClassForVariant, getButtonClassForColor, getButtonClassForStyle } from 'utils/helpers';

import "scss/components/general/buttons.scss";

type ButtonProps = {|
	title?: string,
	type?: string,
	variant?: ?string,
	color?: 'primary' | 'secondary' | 'success' | 'warning' | 'hollow',
	styling?: Array<string> | ?string,
	className?: ?string,
	children?: React$Node,
	onClick?: (e: SyntheticEvent<HTMLButtonElement>) => void
|}

const Button = ({
    type="button", variant = "button", styling, color = "primary", className, children, onClick, ...other
}: ButtonProps): React$Node => {
	const variantClass = useMemo(() => getButtonClassForVariant(variant), [variant]);
	const styleClasses = useMemo(() => getButtonClassForStyle(styling, variantClass), [styling, variantClass]);
	const colorClass = useMemo(() => getButtonClassForColor(color, variantClass), [color, variantClass]);
	const restClasses = className || '';
	return (
		<button
			type={type}
			onClick={onClick}
			className={`${variantClass} ${colorClass} ${styleClasses} ${restClasses}`}
			{...other}
		>
			{children}
		</button>
	);
};

export default Button;