// @flow
import { useMemo } from 'react';
import { FormControl, TextField } from "@mui/material";
import OptionalLabel from 'components/general/input/ely/optional-label';

type ElyTextProps = {
	name?:string,
	label?:string,
	placeholder?:string,
	value?:string,
	error?:string,
	warning?:string,
	multiline?:boolean,
	showMultilineLabel?:boolean,
	rows?: number,
	required?:boolean,
	disabled?:boolean,
	maxCharacters?:number,
	className?: string,
	classes?: {
		control?: Object,
		label?: Object,
		text?: Object,
	},
	variant?: 'standard' | 'outlined' | 'filled',
	InputProps?: Object,
	onChange?:(e: SyntheticEvent<HTMLInputElement>) => void,
	onBlur?:(e: SyntheticEvent<HTMLInputElement>) => void
}

const ElyText = ({
	name,
	label,
	placeholder,
	value,
	error,
	warning,
	multiline,
	showMultilineLabel = true,
	rows,
	required,
	disabled,
	maxCharacters,
	onBlur,
	onChange,
	className,
	classes = {},
	variant = 'standard',
	InputProps,
}: ElyTextProps): React$Node => {

	const helperTextClass = useMemo(() => {
		if ( error ) return 'u-color-error';
		else if ( warning ) return 'u-color-warning';
		return '';
	}, [error, warning]);

	return (
		<FormControl className={className} classes={classes?.control}>
			<TextField
				name={name}
				label={
					<OptionalLabel
						label={label}
						required={required}
						className={classes?.label}
					/>
				}
				placeholder={placeholder}
				multiline={multiline}
				rows={rows}
				disabled={disabled}
				value={value}
				error={!!error}
				onBlur={onBlur}
				onChange={onChange}
				variant={variant}
				InputProps={InputProps}
				classes={classes?.text}
				helperText={error ?? warning ?? ''}
				FormHelperTextProps={{
					classes: {
						root: helperTextClass
					}
				}}
			/>
			{
				multiline && showMultilineLabel &&
				<p className="u-input-footer">
					<span className="u-input-help">press shift + enter for new line</span>
					<span className="u-input-counter">{(value && value.length) || 0}/{maxCharacters || 100}</span>
				</p>
			}
		</FormControl>
	);
};

export default ElyText;
