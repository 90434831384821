import axios from 'axios';

export function setContentTypeHeader(type) {
    axios.defaults.headers.post['Content-Type'] = `${type}`;
    axios.defaults.headers.put['Content-Type'] = `${type}`;
}

export default function setAuthHeader(jwt) {
    if ( jwt ) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${jwt}`;
    }
    else {
        delete axios.defaults.headers.common['Authorization'];
    }
}