// @flow
import "scss/components/public/profile/profile-timeline-date.scss";

type Props = {
	date: string,
	children: React$Node,
	className?: string
};

const ProfileTimelineDate = ({ date, children, className = '' }: Props): React$Node => (
	<li className={`timeline-item-date ${className}`}>
		<h3>{date}</h3>
		{children}
	</li>
);

export default ProfileTimelineDate;
