// @flow

export const AuthTypes = {
	CLOSED: 'CLOSED',
	LOGIN: 'LOGIN',
	FORGOT: 'FORGOT',
	REGISTRATION: 'REGISTRATION',
  REGISTRATION_BUSINESS: 'REGISTRATION_BUSINESS',
	JOIN_BUSINESS: 'JOIN_BUSINESS',
};

export type AuthTypesProps = $Keys<typeof AuthTypes>;
