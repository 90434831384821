// @flow
import { useLocation } from 'react-router-dom';
import { Roles } from 'auth/index';
import { Form, Formik } from 'formik';
import useI18n from 'hooks/i18n/useI18n';
import useAuthActions from 'auth/useAuthActions';
import { Button, LinkButton } from 'components/general/button';
import useSocialLogin from 'components/auth/social/useSocialLogin';
import AuthLayout from 'components/auth/auth-layout';
import { ElyPasswordField, ElyTextField } from 'components/general/input/fields';
import SocialButtonFacebook from 'components/auth/social/social-button-facebook';
import SocialButtonTwitter from 'components/auth/social/social-button-twitter';
import SocialButtonGoogle from 'components/auth/social/social-button-google';
import { AuthTypes, useAuthModal } from 'components/auth/auth-modal-context';
import 'scss/components/auth/login/login-form.scss';

type Props = {
	isModal?: boolean,
}

const LoginForm = ({ isModal = true }: Props): React$Node => {
	const { t } = useI18n();
	const location = useLocation();
    const { doLogin } = useAuthActions();
	const { openAuth, closeAuth } = useAuthModal();
    const { onFbLogin, onTwitterToken, onGoogleLogin } = useSocialLogin(Roles.USER, location);
    return (
        <AuthLayout
	        title={t('login.title')}
	        subtitle={t('login.subtitle')}
	        description={t('login.description')}
	        onClose={isModal ? closeAuth : undefined}
        >
	        <Formik
		        initialValues={{ email:"", password:"" }}
		        validate={validate}
		        onSubmit={values => doLogin(values.email, values.password).then(() => closeAuth())}
	        >
		        {
			        () => (
				        <Form className="LoginForm">
					        <ElyTextField
						        name="email"
						        type="text"
						        label="Email"
						        required={true}
					        />
					        <ElyPasswordField
						        name="password"
						        type="password"
						        label="Password"
						        required={true}
					        />

					        <div className="LoginForm__forgot">
						        <LinkButton onClick={() => openAuth(AuthTypes.FORGOT)}>Forgot password</LinkButton>
					        </div>

					        <div className="LoginForm__submit">
						        <Button type="submit" style={{width:'100%'}}>{t('login.submit')}</Button>
					        </div>

					        <div className="LoginForm__join-with-social">
						        <span> Or you can join with</span>
					        </div>

					        <div className="LoginForm__social">
						        <div className="LoginForm__social__item LoginForm__social__item--facebook">Login with
							        <SocialButtonFacebook
								        scope='email'
								        onConnect={accessToken => onFbLogin(accessToken).then(() => closeAuth())}
							        />
						        </div>
						        <div className="LoginForm__social__item LoginForm__social__item--twitter">Login with
							        <SocialButtonTwitter
								        onConnect={onTwitterToken}
							        />
						        </div>
						        <div className="LoginForm__social__item LoginForm__social__item--google">Login with
							        <SocialButtonGoogle
								        onClick={onGoogleLogin}
							        />
						        </div>
					        </div>

					        <div className="LoginForm__register">
						        <span>Don't have an account? <LinkButton onClick={() => openAuth(AuthTypes.REGISTRATION)}>Sign up!</LinkButton></span>
					        </div>
				        </Form>
			        )
		        }
	        </Formik>
        </AuthLayout>
    );
};

function validate(values) {
    const errors = {};

    if (!values.email) {
        errors.email = "Enter Email!";
    }
    if (!values.password) {
        errors.password = "Enter a Password!";
    }

    return errors;
}

export default LoginForm;
