// @flow
type ModalHeaderProps = {
	children: React$Node,
	className?: string
};

const ModalHeader = ({ children, className }: ModalHeaderProps) => (
	<div className={`ely-modal__header ${className || ''}`}>
		{children}
	</div>
);

export default ModalHeader;