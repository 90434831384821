// @flow
import { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import useGoogleApi from 'hooks/google/use-google-api';

const autocompleteService = { current: null };
const geocoderService = { current: null };

type Props = {
	name: string,
	label?: string | React$Node,
	placeholder?: string,
	value?: string,
	error?: string,
	required?: boolean,
	onBlur:(e: SyntheticEvent<HTMLInputElement>) => void,
	onChange: Object => void,
};

const LocationField = ({ name, label, placeholder, value, error, onBlur, onChange }: Props): React$Node => {
	const [inputValue, setInputValue] = useState('');
	const [options, setOptions] = useState([]);
	const google = useGoogleApi();

	//
	// Initialize Services
	//
	useEffect(() => {
		if ( google ) {
			autocompleteService.current = new google.maps.places.AutocompleteService();
			geocoderService.current = new google.maps.Geocoder();
		}
	}, [google]);

	//
	// Call AutocompleteService when input value changes
	//
	useEffect(() => {
		let active = true;

		autocompleteService.current?.getPlacePredictions({ input: inputValue }, results => {
			if (active) {
				let newOptions = [];

				if (value) newOptions = [value];
				if (results) newOptions = [
					...newOptions,
					...results.map(pred => ({ id: pred.place_id, label: pred.description }))
				];

				setOptions(newOptions);
			}
		});

		return () => {
			active = false;
		};
	}, [value, inputValue]);

	return (
		<Autocomplete
			id={name}
			value={value}
			filterOptions={(x) => x}
			options={options}
			autoComplete
			includeInputInList
			filterSelectedOptions
			onBlur={onBlur}
			// isOptionEqualToValue={(option, value) => {
			// 	console.log(option, value)
			// }}
			onChange={(event, newValue) => {
				setOptions(newValue ? [newValue, ...options] : options);
				if (!newValue) {
					onChange(null);
					return;
				}
				geocoderService.current?.geocode({ placeId: newValue.id }, (results, status) => {
					if (status === 'ZERO_RESULTS') console.log("no results from geocoder");
					if (status === 'OK' && results.length > 0) onChange(results);
				});
			}}
			onInputChange={((event, newValue) => setInputValue(newValue))}
			renderInput={(params) => (
				<TextField
					fullWidth
					label={label}
					placeholder={placeholder}
					name={name}
					error={!!error}
					variant="standard"
					{...params}
				/>
			)}
		/>
	);
};

export default LocationField;
