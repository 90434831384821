// @flow
import type { ApiRoomProps } from 'types/api/node/api-room-props';
import type { ApiDayProps, ApiShipProps } from 'types/api/node/api-cruise-props';
import type { RoomProps, DayProps, ShipProps } from 'types/node/node-commons-props';
import TagGroupType from 'utils/variables/tag-group-vars';
import { transformPhotos } from 'utils/transform/transform-media';

export function transformRoom(room: ApiRoomProps): RoomProps {
	if (!room) return undefined;

	const options = room.tags
		?.filter(tag => tag.tagGroup?.type === TagGroupType.ROOM_OPTIONS)
		.map(tag => ({ title: tag.title, code: tag.code?.replace('tag.', '') })) ?? [];

	const amenities = room.tags
		?.filter(tag => tag.tagGroup?.type === TagGroupType.ROOM_AMENITIES)
		.map(tag => ({ title: tag.title, code: tag.code?.replace('tag.', '') })) ?? [];

	return {
		id: room.id,
		title: room.title,
		photos: transformPhotos(room.photos),
		description: room.descr,
		size: room.size,
		maxGuests: room.guests,
		options,
		amenities,
	}
}

export function transformDay(day: ApiDayProps): DayProps {
	if (!day) return undefined;
	return {
		dayNumber: day.dayNumber,
		description: day.description,
		title: day.title,
		locationPoints: day.locationPoints,
		photos: transformPhotos(day.photos),
	}
}

export function transformShip(ship: ApiShipProps): ?ShipProps {
	if (!ship) return undefined;
	return {
		name: ship.name,
		path: ship.path,
		link: ship.link,
		descr: ship.descr,
		capacity: ship.capacity,
		tonnage: ship.tonnage,
		length: ship.length,
		speed: ship.speed,
		photos: transformPhotos(ship.photos),
		cabins: ship.cabins?.map(cabin => transformRoom(cabin)),
	}
}
