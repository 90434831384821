// @flow
import { useState, useCallback } from 'react';
import { Formik, Form } from 'formik';
import { ElyPasswordField } from 'components/general/input/fields';
import { Button } from 'components/general/button';
import SettingsCard from 'components/general/account/settings/settings-card';

import "scss/components/general/account/settings/password-change.scss";

type ChangePasswordProps = {
    hasPassword?: boolean,
    onChangePassword: Object => Promise<void>
}

const PasswordChange = ({ hasPassword, onChangePassword }: ChangePasswordProps) => {
    const [changed, setChanged] = useState(false);

    const onSubmit = useCallback(values => {
        onChangePassword(values)
            .then(() => setChanged(true))
    }, [onChangePassword, setChanged]);

    return (
        <div className="change-password">
            {
                !changed &&
                <Formik
                    initialValues={{prevPassword:"", newPassword:"", repeatPassword:""}}
                    validate={validate}
                    onSubmit={onSubmit}
                >
                    {
                        () => (
                            <Form>
                                <SettingsCard>
                                    {
                                        !hasPassword &&
                                        <p>You are connected with social media. In order to login with Experly please set
                                            your password</p>
                                    }
                                    {
                                        hasPassword &&
                                        <ElyPasswordField
                                            name="prevPassword"
                                            label="Old Password"
                                        />
                                    }
                                    <ElyPasswordField
                                        name="newPassword"
                                        label="New Password"
                                    />
                                    <ElyPasswordField
                                        name="repeatPassword"
                                        label="Repeat Password"
                                    />
                                </SettingsCard>
                                <div className="change-password__buttons">
                                    <Button type="submit" className="change-password__buttons__submit">Change</Button>
                                </div>
                            </Form>
                        )
                    }
                </Formik>
            }
            {
                changed &&
                <span>Password changed successfully!</span>
            }
        </div>
    );
};

function validate(values) {
    const errors = {};

    if ( !values.prevPassword ) {
        errors.prevPassword = "Enter your previous password";
    }
    if (!values.newPassword) {
        errors.newPassword = "Enter new password!";
    }
    if (!values.repeatPassword) {
        errors.repeatPassword = "Enter new password again!";
    }

    if ( values.newPassword && values.repeatPassword ) {
        if ( values.newPassword !== values.repeatPassword ) {
            errors.repeatPassword = "Passwords do not match";
        }
    }

    return errors;
}

export default PasswordChange;