// @flow
import { useMemo } from 'react';
import TextField from '@mui/material/TextField';

type ElyPasswordProps = {
	name: string,
	label?: string,
	placeholder?: string,
	value: string,
	error?: string,
	warning?: string,
	className?: string,
	variant?: 'standard' | 'outlined' | 'filled',
	onBlur?:(e: SyntheticEvent<HTMLInputElement>) => void,
	onChange?:(e: SyntheticEvent<HTMLInputElement>) => void,
}

const ElyPassword = ({
	name,
	label,
	placeholder,
	value,
	error,
	warning,
	className = '',
	variant = 'standard',
	onBlur,
	onChange,
}: ElyPasswordProps): React$Node => {

	const helperTextClass = useMemo(() => {
		if ( error ) return 'u-color-error';
		else if ( warning ) return 'u-color-warning';
		return '';
	}, [error, warning]);

	return (
		<TextField
			type="password"
			name={name}
			label={label}
			placeholder={placeholder}
			value={value}
			error={!!error}
			onChange={onChange}
			onBlur={onBlur}
			variant={variant}
			helperText={error ?? warning ?? ''}
			className={className}
			FormHelperTextProps={{
				classes: {
					root: helperTextClass
				}
			}}
		/>
	);
};

export default ElyPassword;
