// @flow
import useI18n from 'hooks/i18n/useI18n';
import type { AuditTrailProps } from 'types/audit-trail-props';
import InfiniteScroll from 'components/general/list/infinite-scroll';
import ProfileTimeline from 'components/public/profile/profile-timeline';
import ProfileTimelineDate from 'components/public/profile/profile-timeline-date';
import ProfileTimelineItem from 'components/public/profile/profile-timeline-item';
import ProfileTimelineItemContent from 'components/public/profile/profile-timeline-item-content';

type Props = {
	data: Array<AuditTrailProps>,
	hasMore: boolean,
	onLoadMore: void => void
}

const ProfileTabContributions = ({ data, hasMore, onLoadMore }: Props): React$Node => {
	const { t } = useI18n();
	return (
		<div className="profile-tab-contributions">
			<InfiniteScroll
				throttle={100}
				threshold={300}
				hasMore={hasMore}
				onLoadMore={onLoadMore}
			>
				<ProfileTimeline>
					{
						data?.map(dataItem => (
							<ProfileTimelineDate key={dataItem.group} date={dataItem.group}>
								{
									dataItem.items?.map(item => (
										<ProfileTimelineItem
											key={item.id}
											type={item.event}
											title={t(`profile.activity.contributions.events.${item.event}`)}
											points={item.points}
										>
											{
												item.node &&
												<ProfileTimelineItemContent
													nodeType={item.nodeType}
													node={item.node}
													t={t}
												/>
											}
										</ProfileTimelineItem>
									))
								}
							</ProfileTimelineDate>
						))
					}
				</ProfileTimeline>
			</InfiniteScroll>
		</div>
	);
}

export default ProfileTabContributions;