// @flow
import Icon from 'components/general/icon';
import Emote from 'components/general/emote';
import "scss/components/general/tag.scss";

type TagProps = {
    title: string,
    icon?: ?string,
	emote?: ?string,
	type?: string,
	variant?: 'standard' | 'primary' | 'amenity' | 'text' | 'emote' | 'tag' | 'tag-secondary',
    className?: string,
};

const Tag = ({ title, icon, emote, type, variant = 'standard',  className = '' }: TagProps): React$Node => {
	if ( variant === 'primary' && !type ) {
		console.error("When variant is primary, a type must be provided too.")
	}
	else if ( variant === 'emote' && !emote ) {
		console.error("When variant is emote, an emote property must be provided too.")
	}

	const typeClass = type?.toLowerCase() ?? '';
	const iconLocal = type?.toLowerCase() ?? icon ?? undefined;

	return (
		<div className={`ely-tag ely-tag--${variant} ${typeClass} ${className}`}>
			{ iconLocal && <Icon code={iconLocal} className="ely-tag__avatar" /> }
			{ emote && <Emote code={emote} className="ely-tag__emote" /> }
			<span className="ely-tag__label">{title}</span>
		</div>
	);
}

export default Tag;
