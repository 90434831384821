// @flow
import { Formik, Form } from 'formik';
import Button from 'components/general/button';
import { ElyTextField } from 'components/general/input/fields';
import 'scss/components/auth/register/registration-name.scss';

type Props = {
	onSubmit: Object => Promise<any>,
	t: string => string,
};

const RegistrationName = ({ onSubmit, t }: Props): React$Node => (
	<Formik
		initialValues={{ displayName: '' }}
		validate={validate}
		onSubmit={onSubmit}
	>
		<Form className="RegistrationName">
			<ElyTextField
				required={true}
				name="displayName"
				label="Public name"
			/>
			<Button type="submit" style={{width:'100%'}}>{t('registration.name.submit')}</Button>
		</Form>
	</Formik>
);

function validate(values) {
	const errors = {};
	if (values.displayName?.length > 0 && values.displayName?.length < 2) {
		errors.displayName = 'Public name should be at least 2 characters';
	}
	return errors;
}

export default RegistrationName;
