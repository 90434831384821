// @flow
import { Roles } from 'auth';
import { AuthTypes, type AuthTypesProps } from 'components/auth/auth-utils';
import { ModalWindow } from 'components/general/modal';
import LoginForm from 'components/auth/login/login-form';
import ForgotPassword from 'components/auth/login/forgot-password';
import RegistrationWizard from 'components/auth/register/registration-wizard';
import JoinBusinessWizard from 'components/auth/join/join-business-wizard';
import 'scss/components/auth/auth-modal.scss';

type Props = {
	isOpen: boolean,
	authType: AuthTypesProps,
}

const AuthModal = ({ isOpen, authType }: Props) => (
	<ModalWindow isOpen={isOpen} classes={{ modal: `AuthModal ${authType}`, content: "AuthModal__content" }}>
		{
			AuthTypes.LOGIN === authType &&
			<LoginForm />
		}
		{
			AuthTypes.FORGOT === authType &&
			<ForgotPassword />
		}
		{
			AuthTypes.REGISTRATION === authType &&
			<RegistrationWizard role={Roles.USER} />
		}
		{
			(AuthTypes.REGISTRATION_BUSINESS === authType || AuthTypes.JOIN_BUSINESS === authType) &&
			<JoinBusinessWizard isRegister={AuthTypes.REGISTRATION_BUSINESS === authType} />
		}
	</ModalWindow>
);

export default AuthModal;
