import { useEffect } from 'react';
import ReactGa from 'react-ga4';
import { Globals } from 'utils/variables';
import CookieUtils, { AnalyticsCookieIsTracking } from 'utils/cookie-utils';
import CookieConsent from 'components/general/cookie-consent';
import { notificationService } from 'components/general/notification';

function initiateAnalytics() {
	if (Globals.GAEnabled === true) {
		ReactGa.initialize(Globals.GA);
	}
}

function onCookieSubmit(hasOptOut) {
	CookieUtils.setCookie(AnalyticsCookieIsTracking, !hasOptOut, 3650);
	if ( hasOptOut ) {
		window[Globals.GAOptOutVar] = true;
	}
	else if ( Globals.GAEnabled === true ) {
		initiateAnalytics();
	}
}

export default function useInitializeAnalyticsAndCookieConsent() {

	//
	// Initialize cookies consent
	//
	useEffect(() => {
		const isTracking = CookieUtils.getCookie(AnalyticsCookieIsTracking);
		if ( isTracking === 'true') {
			initiateAnalytics();
		}
		else if ( isTracking === 'false' ) {
			window[Globals.GAOptOutVar] = true;
		}
		else {
			notificationService.show({
				messages: [],
				hideCloseButton: true,
				customHtml: <CookieConsent onCookieSubmit={onCookieSubmit} />,
				anchorOrigin: {
					vertical: 'bottom',
					horizontal: 'center',
				}
			});
		}
	}, []);
}