// @flow
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormHelperText from '@mui/material/FormHelperText';

type Props = {
	id?:string,
	name:string,
	label?:string,
	value:boolean,
	color?:string,
	disabled?:boolean,
	error?:string,
	warning?:string,
	onChange?:(e: SyntheticEvent<HTMLInputElement>) => void,
	className?: string,
};

const ElyCheckbox = ({id, name, label, value, color = "primary", disabled, error, warning, onChange, className }: Props): React$Node => {
	return (
		<FormControl error={!!error} className={className}>
			<FormControlLabel
				label={label}
				control={
					<Checkbox
						id={id}
						name={name}
						color={color}
						disabled={disabled}
						checked={value}
						onChange={onChange}
					/>
				}
			/>
			{error && <FormHelperText className="u-color-problem">{error}</FormHelperText>}
			{warning && <FormHelperText className="u-color-warning">{warning}</FormHelperText>}
		</FormControl>
	);
};

export default ElyCheckbox;