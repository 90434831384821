// @flow
import ReactDOM from 'react-dom';
import "scss/components/general/modal/modal-window.scss";

type Props = {
	isOpen: boolean,
	children: React$Node,
	modalRef?: React$Ref,
	contentRef?: React$Ref,
	classes?: {
		modal?: string,
		content?: string,
	},
};

const ModalWindow = ({ isOpen, children, modalRef, contentRef, classes = {} }: Props): React$Node => {
	const modalClass = classes.modal ?? '';
	const contentClass = classes.content ?? '';
	if ( !isOpen || document.body === null ) return null;
	return ReactDOM.createPortal(
		<div ref={modalRef} className={`ely-modal u-animate-modal is-modal-open ${modalClass}`}>
			<div ref={contentRef} className={`ely-modal__content ${contentClass}`}>{children}</div>
		</div>,
		document.body,
	);
};

export default ModalWindow;
