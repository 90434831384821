// @flow
type Props = {
	children: React$Node,
};

const NoLayout = ({ children }: Props): React$Node => (
	<>
		{children}
	</>
);

export default NoLayout;
