import { getIn } from 'formik';
import { ElyDatepicker } from 'components/general/input/index';

const FormikDatepicker = ({ field, form: { touched, errors, setFieldValue, setFieldTouched }, ...props}) => {
	const error = (getIn(touched, field.name) && getIn(errors, field.name));
	return (
		<ElyDatepicker
			{...field}
			{...props}
			value={field.value === '' || field.value === undefined ? null : field.value}
			error={error}
			onChange={(e) => {
				setFieldValue(field.name, e);
				setFieldTouched(field.name, true)
			}}
			onClose={() => {
				setFieldTouched(field.name, true);
				props.onClose?.();
			}}
		/>
	);
};

export default FormikDatepicker;
