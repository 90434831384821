// @flow
import useI18n from 'hooks/i18n/useI18n';
import ProfileSearch from 'components/public/profile/profile-search';
import PublicUsersList from 'components/public/user/public-users-list';
import "scss/components/public/profile/people/profile-people-search.scss";

type FriendsAddProps = {
	users: Array,
	hasMore: boolean,
	onLoadMore: void => void,
	onSearch: Object => void,
	addFriend: string => void,
	removeFriend: (string, string) => void,
	acceptFriend: string => void,
	ignoreFriend: (string, string) => void
}

const ProfilePeopleSearch = ({ users, hasMore, onLoadMore, onSearch, addFriend, removeFriend, acceptFriend, ignoreFriend }: FriendsAddProps) => {
	const { t } = useI18n();
	return (
		<div className="profile-people-search">
			<ProfileSearch
				placeholder={t('profile.friends.add.search.placeholder')}
				submitLabel={t('profile.friends.add.search.submit')}
				onSubmit={onSearch}
				className="profile-people-search__search"
			/>
			<PublicUsersList
				users={users}
				hasMore={hasMore}
				onLoadMore={onLoadMore}
				addFriend={addFriend}
				removeFriend={removeFriend}
				acceptFriend={acceptFriend}
				ignoreFriend={ignoreFriend}
				className="profile-people-search__results"
			/>
		</div>
	);
}

export default ProfilePeopleSearch;