// @flow
export const TagGroup = {
	ACCOMMODATION: 'accommodation',
	CRUISE: 'cruise',
	EXCURSION: 'excursion',
	FOOD_AND_BEVERAGE: 'food_and_beverage',
	OTHER: 'other',
	SHOW_AND_EVENT: 'show_and_event',
	TRANSFER: 'transfer',

	ACTIVITIES_INTERESTS: 'activities_interests',
	FEATURES: 'features',
	FEELINGS: 'feelings',
	TARGET_GROUPS: 'target_groups',
	CUISINE_TYPE: 'cuisine_type',
	LOCATION: 'location',
};

export const TagGroupType = {
	NONE: 'NONE',
	PRIMARY: 'PRIMARY',
	FEATURES: 'FEATURES',
	AMENITIES: 'AMENITIES',
	ROOM_OPTIONS: 'ROOM_OPTIONS',
	ROOM_AMENITIES: 'ROOM_AMENITIES',
	SHIP_AMENITIES: 'SHIP_AMENITIES',
}

export default TagGroupType;
