import { useRef, useState, useEffect, useCallback } from 'react';
import Globals from 'utils/variables/globals';

const FacebookAPIProps = {
	appId: Globals.FbAppId,
	version: Globals.FbAPIVersion,
	cookie: false,
	xfbml: false,
	language: 'en_US'
};

export default function useLoadFacebook() {
	const _isMounted = useRef(true);
	const [isSdkLoaded, setSdkLoaded] = useState(false);

	//
	// Function to load Facebook Javascript SDK
	//
	const loadSdkAsynchronously = useCallback(() => {

		const { appId, xfbml, cookie, version, language } = FacebookAPIProps;

		// Set callback for facebook in window
		window.fbAsyncInit = () => {
			window.FB.init({
				appId,
				xfbml,
				cookie,
				version: `v${version}`
			});
			_isMounted.current && setSdkLoaded(true);
		};

		// Asynchronously load facebook javascript SDK
		((d, s, id) => {
			let js, fjs = d.getElementsByTagName(s)[0];
			if (d.getElementById(id)) { return; }
			js = d.createElement(s); js.id = id;
			js.src = `https://connect.facebook.net/${language}/sdk.js`;
			fjs.parentNode.insertBefore(js, fjs);
		})(document, 'script', 'facebook-jssdk');
	}, []);

	//
	// Load SDK if has not already been loaded
	//
	useEffect(() => {

		if ( document.getElementById('facebook-jssdk') ) {
			_isMounted.current && setSdkLoaded(true);
		}
		else {
			loadSdkAsynchronously();
		}

		return () => { _isMounted.current = false; }
	}, [loadSdkAsynchronously]);

	return { isSdkLoaded };
}