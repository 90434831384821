// @flow
import { Formik, Form, Field } from 'formik';
import { Button } from 'components/general/button';
import "scss/components/public/profile/profile-search.scss";

type ProfileSearchProps = {
	onSubmit: Object<{search:string}> => void,
	placeholder?: string,
	submitLabel?: string,
	className?: string,
}

const ProfileSearch = ({ onSubmit, placeholder, submitLabel, className }: ProfileSearchProps) => (
	<div className={`profile-search ${className ?? ''}`}>
		<Formik
			initialValues={{search: ''}}
			onSubmit={onSubmit}
		>
			{
				() => (
					<Form className="profile-search__form">
						<Field
							name="search"
							component="input"
							placeholder={placeholder}
							className="profile-search__input"
						/>
						<Button type="submit" className="profile-search__submit">{submitLabel}</Button>
					</Form>
				)
			}
		</Formik>
	</div>
);

export default ProfileSearch;