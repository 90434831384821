// @flow
import Button from 'components/general/button';
import 'scss/components/auth/join/join-business-tutorial.scss';

type Props = {
	activeStep: number,
	title: string,
	description: string,
	nextLabel: string,
	onClick: void => void,
}

const JoinBusinessTutorial = ({ activeStep, title, description, nextLabel, onClick }: Props): React$Node => (
	<div className="JoinBusinessTutorial">
		<ul className="JoinBusinessTutorial__steps">
			<li className={`JoinBusinessTutorial__steps__item ${activeStep === 1 ? 'active' : ''}`}></li>
			<li className={`JoinBusinessTutorial__steps__item ${activeStep === 2 ? 'active' : ''}`}></li>
		</ul>
		<h2 className="JoinBusinessTutorial__title">{title}</h2>
		<p className="JoinBusinessTutorial__description">{description}</p>
		<Button onClick={onClick} className="JoinBusinessTutorial__next">{nextLabel}</Button>
	</div>
);

export default JoinBusinessTutorial;
