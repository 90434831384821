// @flow
import HeaderButtonClose from 'components/layout/header-button-close';
import 'scss/components/auth/auth-layout.scss';

type Props = {
	title?: string,
	subtitle?: string,
	description?: string,
	onClose?: void => void,
	hideTopHeader?: boolean,
	children: React$Node,
	className?: string,
};

const AuthLayout = ({
	title,
	subtitle,
	description,
	onClose,
	hideTopHeader = false,
	children,
	className = '',
}: Props): React$Node => (
	<div className={`AuthLayout ${className}`}>
		<header className="AuthLayout__header">
			{
				!hideTopHeader && (
					<div className="AuthLayout__header__top">
						{ !!onClose && <HeaderButtonClose onClick={onClose} /> }
						{ !!title && <h2 className="title">{title}</h2> }
					</div>
				)
			}
			<div className="AuthLayout__header__bottom">
				{ !!subtitle && <h2 className="title">{subtitle}</h2> }
				{ !!description && <span className="description">{description}</span> }
			</div>
		</header>
		<div className="AuthLayout__main">
			{ children }
		</div>
	</div>
);

export default AuthLayout;
