// @flow

type Props = {
	condition: boolean,
	trueWrapper?: React$Node => React$Node,
	falseWrapper?: React$Node => React$Node,
	children: React$Node
};

const defaultWrapper = children => <>{children}</>;

const ConditionalWrapper = ({ condition, trueWrapper = defaultWrapper, falseWrapper = defaultWrapper, children }: Props): React$Node => (
	<>
		{
			condition
				? trueWrapper(children)
				: falseWrapper(children)
		}
	</>
);

export default ConditionalWrapper;