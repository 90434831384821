import { getIn } from 'formik';
import { ElyReactSelect } from 'components/general/input/index';

const FormikSearchSelect = ({ field, form:{touched, errors, setFieldValue}, ...props}) => {
    const error = (getIn(touched, field.name) && getIn(errors, field.name));
    return (
        <ElyReactSelect
            {...field}
            {...props}
            error={error}
            warning={''}
            onChange={(value) => {
                setFieldValue(field.name, value);
            }}
            onBlur={() => {}}
        />
    );
};

export default FormikSearchSelect;