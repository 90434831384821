// @flow
import Button from 'components/general/button/button';
import loginPartner from 'img/login-partner-photo.png';
import loginSupplier from 'img/login-supplier-photo.png';
import 'scss/components/auth/join/join-business-supplier-partner.scss';

const JoinBusinessSupplierPartner = ({ onClick }) => (
	<div className="JoinBusinessSupplierPartner">
		<div className="JoinBusinessSupplierPartner__supplier">
			<img className="JoinBusinessSupplierPartner__image" alt="" src={loginSupplier}/>
			<h2 className="JoinBusinessSupplierPartner__title">Become a Supplier</h2>
			<p className="JoinBusinessSupplierPartner__description">Whether you're a hotel manager, a sightseeing tour guide or a hospitality professional don't miss out on a great opportunity to list your service and make it available for booking to a growing number of customers across the world.</p>
			<Button onClick={() => onClick('supplier')} className="next">Become a Supplier</Button>
		</div>
		<div className="JoinBusinessSupplierPartner__partner">
			<img className="JoinBusinessSupplierPartner__image" alt="" src={loginPartner}/>
			<h2 className="JoinBusinessSupplierPartner__title">Become a Partner</h2>
			<p className="JoinBusinessSupplierPartner__description">How well do you know the secret gems of experiences that your location has to offer? If you're a local connoisseur, well-connected and with good knowledge of the best spots to enjoy sunsets or to taste local delicacies then you fit the Partner profile with lots of gains to enjoy.</p>
			<Button onClick={() => onClick('partner')} className="next">Become a Partner</Button>
		</div>
	</div>
);

export default JoinBusinessSupplierPartner;
