export default class Status {
	static ACTIVE = 'ACTIVE';
	static BLOCKED = 'BLOCKED';
	static COMPLETE = 'COMPLETE';
	static DRAFT = 'DRAFT';
	static REJECTED = 'REJECTED';
	static PUBLISHED = 'PUBLISHED';
	static UNPUBLISHED = 'UNPUBLISHED';
	static PENDING = 'PENDING';
	static PENDING_REVIEW = 'PENDING_REVIEW';
	static PENDING_CONFIRMATION = 'PENDING_CONFIRMATION';
	static DELETED = 'DELETED';
	static SUBSCRIBED = 'SUBSCRIBED';
	static UNSUBSCRIBED = 'UNSUBSCRIBED';
};
