// @flow
import type { AuditEventProps } from 'types/audit-event-props';

export default class AuditEvent {
	static SIGN_UP                      : AuditEventProps   = "SIGN_UP";
	static SIGN_UP_CONFIRM              : AuditEventProps   = "SIGN_UP_CONFIRM";
	static AUTHENTICATION_SUCCESS       : AuditEventProps   = "AUTHENTICATION_SUCCESS";

	static CREATE_SUPPLIER              : AuditEventProps   = "CREATE_SUPPLIER";
	static CREATE_SERVICE               : AuditEventProps   = "CREATE_SERVICE";
	static EDIT_SERVICE                 : AuditEventProps   = "EDIT_SERVICE";
	static PUBLISH_SERVICE              : AuditEventProps   = "PUBLISH_SERVICE";

	static CREATE_OSM_POI               : AuditEventProps   = "CREATE_OSM_POI";
	static POI_CREATE                   : AuditEventProps   = "POI_CREATE";
	static POI_EDIT                     : AuditEventProps   = "POI_EDIT";
	static POI_CREATE_REVISION          : AuditEventProps   = "POI_CREATE_REVISION";
	static POI_ACCEPT_REVISION          : AuditEventProps   = "POI_ACCEPT_REVISION";
	static POI_REJECT_REVISION          : AuditEventProps   = "POI_REJECT_REVISION";
	static ADD_REVIEW                   : AuditEventProps   = "ADD_REVIEW";

	static UPLOAD_PROFILE_PHOTO         : AuditEventProps   = "UPLOAD_PROFILE_PHOTO";
	static COMPLETE_PROFILE             : AuditEventProps   = "COMPLETE_PROFILE";
	static FEEL_UPDATE                  : AuditEventProps   = "FEEL_UPDATE";
	static ADD_FAVORITE                 : AuditEventProps   = "ADD_FAVORITE";

	static UPDATE_FEELING               : AuditEventProps   = "UPDATE_FEELING";

	static SEARCH_EXPERIENCES           : AuditEventProps   = "SEARCH_EXPERIENCES";
	static BOOK_EXPERIENCE              : AuditEventProps   = "BOOK_EXPERIENCE";
	static SEARCH_PEOPLE                : AuditEventProps   = "SEARCH_PEOPLE";
	static ADD_FRIEND                   : AuditEventProps   = "ADD_FRIEND";
	static ACCEPT_FRIEND                : AuditEventProps   = "ACCEPT_FRIEND";
	static SOCIAL_SHARE                 : AuditEventProps   = "SOCIAL_SHARE";
}