// @flow
import "scss/components/public/profile/profile-timeline.scss";

type ProfileTimelineProps = {
	children:React$Node,
	className?:string
}

const ProfileTimeline = ({ children, className }: ProfileTimelineProps) => {
	return (
		<div className={`timeline-content ${className ?? ''}`}>
			<ul className="timeline">
				{children}
			</ul>
		</div>
	);
}

export default ProfileTimeline;