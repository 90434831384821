export class NotificationService {

	register(notification) {
		this.notification = notification;
	}

	unregister() {
		this.notification = null;
	}

	show = config => {
		this.notification.show(config);
	};

	hide = () => {
		this.notification.hide();
	};
}

const notificationService = new NotificationService();
export { notificationService };