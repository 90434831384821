// @flow
import type { LayoutWidthProps } from 'types/general/commons';
import { useMe } from 'auth';
import { routes } from 'utils/routes';
import { Outlet } from 'react-router-dom';
import LayoutWidth from 'utils/variables/layout-width';
import useCheckScreen from 'hooks/general/useCheckScreen';
import LayoutMainMenu from 'components/layout/main/layout-main-menu';
import LayoutMainHeader from 'components/layout/main/layout-main-header';
import ConditionalWrapper from 'components/general/wrapper/conditional-wrapper';
import 'scss/components/layout/main/layout-main.scss';

type Props = {
	width?: LayoutWidthProps,
	hideMobileHeader?: boolean,
	children?: React$Node,
	className?: string,
};

const LayoutMain = ({ width = LayoutWidth.MAX_WIDTH, hideMobileHeader = false, children, className = '' }: Props): React$Node => {
	const me = useMe();
	const { isSmall, isMedium } = useCheckScreen();
	const profileLink = routes.PROFILE;
	return (
		<div className={`LayoutMain ${className}`}>
			{
				((!isSmall && !isMedium) || !hideMobileHeader) && (
					<LayoutMainHeader
						profileLink={profileLink}
						profileImage={me?.photo?.thumbnail}
					/>
				)
			}
			<main className={`LayoutMain__main LayoutMain__main--${width}`}>
				<ConditionalWrapper
					condition={LayoutWidth.MAX_WIDTH === width}
					trueWrapper={children => <div className="LayoutMain__main__inner">{children}</div>}
				>
					{ children ?? <Outlet /> }
				</ConditionalWrapper>
			</main>
			<LayoutMainMenu
				profileLink={profileLink}
				profileImage={me?.photo?.thumbnail}
				isMobile
			/>
		</div>
	);
};

export default LayoutMain;