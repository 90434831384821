import qs from 'query-string';

export default class FileUtils {

	static SIZE_THUMBNAIL  = 160;
	static SIZE_SMALL      = 320;
	static SIZE_MEDIUM     = 960;
	static SIZE_LARGE      = 1440;

	static getDocUrl = (filename) => filename && `/docs/${filename.split("", 3).join("/")}/${filename}.pdf`;
	static getImageUrl = (filename, size) => {
		if ( !filename ) return undefined;
		if ( size ) return `/images/${filename.split("", 3).join("/")}/${filename}-${size}.jpg`;
		return `/images/${filename.split("", 3).join("/")}/${filename}.jpg`;
	};
	static getVideoThumbnail = url => {
		if ( !url ) return null;
		const tokens = url.split("?");
		if ( !tokens || tokens.length < 1 ) return null;
		const params = qs.parse(tokens[1]);
		return `https://img.youtube.com/vi/${params.v}/mqdefault.jpg`;
	}
}