// @flow
import dayjs from 'dayjs';


export const HTML5_FMT = {
	DATETIME_LOCAL: 'YYYY-MM-DDTHH:mm',
	DATETIME_LOCAL_SECONDS: 'YYYY-MM-DDTHH:mm:ss',
	DATETIME_LOCAL_MS: 'YYYY-MM-DDTHH:mm:ss.SSS',
	DATE: 'YYYY-MM-DD',
	TIME: 'HH:mm',
	TIME_SECONDS: 'HH:mm:ss',
	TIME_MS: 'HH:mm:ss.SSS',
	WEEK: 'GGGG-[W]WW',
	MONTH: 'YYYY-MM'
};

export function format(date: string | Date, format: string): string {
	return dayjs(date).format(format);
}

export function diff(date1: string | Date, date2: string | Date): number {
	return dayjs(date1).diff(dayjs(date2));
}

export function equalDate(date1: Date, date2: Date): boolean {
	if ( !date1 || !date2 ) return false;
	const d1 = new Date(date1);
	const d2 = new Date(date2);
	d1.setHours(12,0,0,0);
	d2.setHours(12,0,0,0);
	return d1.getTime() === d2.getTime();
}

export function equalDateTime(date1: Date, date2: Date): boolean {
	if ( !date1 || !date2 ) return false;
	return date1.getTime() === date2.getTime();
}

export function addDays(date: Date, days: number): Date {
	const dateNew = new Date(date);
	dateNew.setDate(dateNew.getDate() + days);
	return dateNew;
}

export function getDateRangeInclusive(startDate: Date, stopDate: Date): Array<Date> {
	const dateArray = [];
	const startD = startDate;
	const stopD = stopDate;
	startD.setHours(12,0,0,0);
	stopD.setHours(12,0,0,0);
	const stop = dayjs(stopD);
	let next = dayjs(startD);
	while (next <= stop) {
		const current = next.toDate();
		current.setHours(12,0,0,0);
		dateArray.push(current);
		next = dayjs(current).add(1, 'days');
	}
	return dateArray;
}

export function getMonthDates(month: number, year: number): Array<Date> {
	const firstOfMonth = new Date();
	const lastOfMonth = new Date();
	firstOfMonth.setFullYear(year, month, 1);
	lastOfMonth.setFullYear(year, month + 1, 0);
	return getDateRangeInclusive(firstOfMonth, lastOfMonth);
}

// Getters
export function setDate(date: string | Date, params: Object): Date {
	let dateLocal = dayjs(date);
	for ( const key in params ) {
		dateLocal = dateLocal.set(key, params[key])
	}
	return dateLocal.toDate();
}

export function getDay(date: string | Date): number {
	return dayjs(date).day();
}

export function getMonth(date: string | Date): number {
	return dayjs(date).month();
}

export function getYear(date: string | Date): number {
	return dayjs(date).year();
}

export function getPickerInstance(date: string | Date, format?: string): typeof dayjs {
	if (!date) return null;
	return format ? dayjs(date, format) : dayjs(date);
}
