// @flow
import Roles from 'auth/Roles';
import JoinBusinessForm from 'components/auth/join/join-business-form';
import JoinBusinessTermsSupplier from 'components/auth/join/join-business-terms-supplier';
import JoinBusinessTermsPartner from 'components/auth/join/join-business-terms-partner';
import 'scss/components/auth/join/join-business-role.scss';

type Props = {
  role: string,
  isRegister: boolean,
  onAcceptTerms: void => void,
  onRegister: Object => void,
};

const JoinBusinessRole = ({ role, isRegister, onAcceptTerms, onRegister }: Props) => (
  <div className="JoinBusinessRole">
    { (Roles.SUPPLIER === role) && <JoinBusinessTermsSupplier isRegister={isRegister} onClick={onAcceptTerms} /> }
    { (Roles.PARTNER === role) && <JoinBusinessTermsPartner isRegister={isRegister} onClick={onAcceptTerms} /> }
    { isRegister && <JoinBusinessForm role={role} onSubmit={onRegister}/> }
  </div>
);

export default JoinBusinessRole;