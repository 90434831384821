// @flow
import HeaderButtonBack from 'components/layout/header-button-back';
import 'scss/components/layout/modal/layout-modal-header.scss';

type Props = {
	title: string,
	back: string | Object,
	className?: string,
};

const LayoutModalHeader = ({ title, back, className = '' }: Props): React$Node => (
	<header className={`LayoutModalHeader ${className}`}>
		<HeaderButtonBack to={back} className="LayoutModalHeader__back" />
		<h1 className="LayoutModalHeader__title">{title}</h1>
	</header>
);

export default LayoutModalHeader;
