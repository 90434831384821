// @flow
import { OAUTH_URL, API_URL, CMS_URL, IMRE_URL, IMCAP_URL, NLP_URL } from 'utils/variables';

export type BackendProps = {
	url: string
};

export default class Backend {

	//
	// Auth Related
	//
	static REGISTER: BackendProps                                             = {url: `${OAUTH_URL}/register`};
	static REGISTER_CONFIRM: BackendProps                                     = {url: `${OAUTH_URL}/confirm`};
	static LOGIN: BackendProps                                                = {url: `${OAUTH_URL}/token`};
	static LOGOUT: BackendProps                                               = {url: `${OAUTH_URL}/token/revoke`};
	static OAUTH_CHANGE_PASSWORD: BackendProps                                = {url: `${OAUTH_URL}/chpasswd`};
	static OAUTH_FORGOT_PASSWORD: BackendProps                                = {url: `${OAUTH_URL}/forgot`};
	static OAUTH_FORGOT_PASSWORD_CHANGE: BackendProps                         = {url: `${OAUTH_URL}/forgot/change`};
	static OAUTH_ROLES: BackendProps                                          = {url: `${OAUTH_URL}/roles`};
	static USERS: BackendProps                                                = {url: `${API_URL}/users`};

	static OAUTH_FACEBOOK: BackendProps                                       = {url: `${OAUTH_URL}/facebook`};
	static OAUTH_TWITTER: BackendProps                                        = {url: `${OAUTH_URL}/twitter`};
	static OAUTH_TWITTER_TOKEN: BackendProps                                  = {url: `${OAUTH_URL}/twitter/token`};
	static OAUTH_GOOGLE: BackendProps                                         = {url: `${OAUTH_URL}/google`};
	static OAUTH_GOOGLE_URL: BackendProps                                     = {url: `${OAUTH_URL}/google/url`};

	//
	// CMS
	//
	static COUNTRIES: BackendProps                                            = {url: `${API_URL}/db/countries`};
	static ROLES: BackendProps                                                = {url: `${API_URL}/db/roles`};
	static CMS_ROLES: BackendProps                                            = {url: `${CMS_URL}/roles`};
	static CMS_PERMISSION_GROUPS: BackendProps                                = {url: `${CMS_URL}/permissiongroups`};
	static CMS_TAGS: BackendProps                                             = {url: `${CMS_URL}/tags`};
	static CMS_TAGS_GROUPS: BackendProps                                      = {url: `${CMS_URL}/taggroups`};
	static CMS_BUNDLES: BackendProps                                          = {url: `${CMS_URL}/bundles`};
	static BOOKINGS: BackendProps                                             = {url: `${CMS_URL}/bookings`};
	static SERVICES: BackendProps                                             = {url: `${API_URL}/services`};
	static SERVICES_COUNT: BackendProps                                       = {url: `${API_URL}/services/count`};
	static SERVICES_STATUS: BackendProps                                      = {url: `${API_URL}/services/status`};
	static SERVICES_OCCURRENCES: string => BackendProps                       = service => ({ url: `${API_URL}/services/${service}/occurrences` });
	static SERVICES_DELETE_DOCS: (string, string) => BackendProps             = (service, doc) => ({url: `${API_URL}/services/${service}/docs/${doc}`});
	static SERVICES_DELETE_IMAGE: (string, string) => BackendProps            = (service, image) => ({url: `${API_URL}/services/${service}/images/${image}`});
	static SERVICES_FOR_BUNDLE_FORM: BackendProps                             = {url: `${API_URL}/services/for-bundle-form`};
	static CMS_EXPERIENCES: BackendProps                                      = {url: `${CMS_URL}/experiences`};
	static CMS_EXPERIENCES_APPROVE: BackendProps                              = {url: `${CMS_URL}/experiences/approve`};
	static CMS_EXPERIENCES_REJECT: string => BackendProps                     = key => ({url: `${CMS_URL}/experiences/reject/${key}`});
	static CMS_EXPERIENCES_DELETE_IMAGES: (string, string) => BackendProps    = (experience, image) => ({url: `${CMS_URL}/experiences/${experience}/images/${image}`});

	// OSM management
	static OSM_COMMONS: BackendProps                                    = {url: `${API_URL}/osm/commons`};
	static OSM_POIS: BackendProps                                       = {url: `${API_URL}/osm/pois`};
	static OSM_POIS_WITH_NO_LOCATION_DETAILS: BackendProps              = {url: `${API_URL}/osm/pois/with-no-location-details`};
	static OSM_POI_QUERY: BackendProps                                  = {url: `${API_URL}/osm/query`};
	static OSM_IMPORT: BackendProps                                     = {url: `${API_URL}/osm/import`};
	static OSM_POIS_ADDRESS_DETAILS: BackendProps                       = {url: `${API_URL}/osm/address-details`};
	static OSM_POIS_FETCHING: BackendProps                              = {url: `${API_URL}/osm/fetching`};
	static OSM_POI_TAG_METRICS: BackendProps                            = {url: `${API_URL}/osm/osm-tags-metrics`};

	// Dashboard
	static DASHBOARD_COUNTS: BackendProps                               = {url: `${API_URL}/dashboard/counts`};

	//
	// COMMONS
	//
	static COMMONS_LOCALES: BackendProps                                      = {url: `${API_URL}/commons/locales`};
	static COMMONS_PERMISSIONS: BackendProps                                  = {url: `${API_URL}/commons/permissions`};
	static COMMONS_PERMISSION_GROUPS: BackendProps                            = {url: `${API_URL}/commons/permissiongroups`};
	static COMMONS_COUNTRIES: BackendProps                                    = {url: `${API_URL}/commons/countries`};
	static COMMONS_TAGS: BackendProps                                         = {url: `${API_URL}/commons/tags`};
	static COMMONS_TAGS_GROUPS: BackendProps                                  = {url: `${API_URL}/commons/tags/groups`};
	static COMMONS_TAGS_FOLLOWING: BackendProps                               = {url: `${API_URL}/commons/tags/following`};
	static COMMONS_TAG_GROUPS: BackendProps                                   = {url: `${API_URL}/commons/taggroups`};
	static COMMONS_THIRD_PARTIES: BackendProps                                = {url: `${API_URL}/commons/thirdParties`};
	static COMMONS_CANCELLATION_MARGINS: BackendProps                         = {url: `${API_URL}/commons/cancellationMargins`};

	static COMMONS_SUPPLIERS: BackendProps                                    = {url: `${API_URL}/commons/suppliers`};
	static COMMONS_SUPPLIERS_TITLES: BackendProps                             = {url: `${API_URL}/commons/suppliers/titles`};

	//
	// Media API
	//
	static MEDIA: BackendProps                                                = {url: `${API_URL}/media`};
	static MEDIA_IMAGES_SORT: BackendProps                                    = {url: `${API_URL}/media/images/sort`};

	//
	// Public
	//
	static FEED: BackendProps                                                 = {url: `${API_URL}/feed`};
	static FEED_KEYS: BackendProps                                            = {url: `${API_URL}/feed/keys`};
	static FEED_TAG: BackendProps                                             = {url: `${API_URL}/feed/tag`};
	static FEED_TRENDING: BackendProps                                        = {url: `${API_URL}/feed/trending`};
	static NEWSLETTER_SUBSCRIBE: BackendProps                                 = {url: `${API_URL}/newsletter/subscribe`};
	static NEWSLETTER_CONFIRM: BackendProps                                   = {url: `${API_URL}/newsletter/confirm`};
	static NEWSLETTER_UNSUBSCRIBE: BackendProps                               = {url: `${API_URL}/newsletter/unsubscribe`};
	static IP_DETAILS: BackendProps                                           = {url: `${API_URL}/ip-details`};

	//
	// Search
	//
	static SEARCH: BackendProps                                         = {url: `${API_URL}/search`};
	static SEARCH_PREDICTIONS: BackendProps                             = {url: `${API_URL}/search/predictions`};
	static SEARCH_STORIES: BackendProps                                 = {url: `${API_URL}/search/stories`};
	static SEARCH_NODES: BackendProps                                   = {url: `${API_URL}/search/nodes`};
	static SEARCH_SHOP: BackendProps                                    = {url: `${API_URL}/search/shop`};
	static SEARCH_NODES_FEATURED: BackendProps                          = {url: `${API_URL}/search/nodes/featured`};
	static SEARCH_NODES_CLOSE: BackendProps                             = {url: `${API_URL}/search/nodes/close`};
	static SEARCH_TAGS: BackendProps                                    = {url: `${API_URL}/search/tags`};
	static SEARCH_PEOPLE: BackendProps                                  = {url: `${API_URL}/search/people`};
	static SEARCH_PEOPLE_OLD: BackendProps                              = {url: `${API_URL}/search/people/old`};
	static SEARCH_PEOPLE_RECOMMENDED: BackendProps                      = {url: `${API_URL}/search/people/recommended`};
	static SEARCH_AREAS: BackendProps                                   = {url: `${API_URL}/search/areas`};

	//
	// Business
	//
	static EMAIL_EVENTS: BackendProps                                         = {url: `${API_URL}/email/events`};
	static EMAIL_TEMPLATES: BackendProps                                      = {url: `${API_URL}/email/templates`};
	static EMAIL_TEMPLATES_TEST: BackendProps                                 = {url: `${API_URL}/email/templates/test`};
	static BUSINESS_ACCOUNTS: BackendProps                                    = {url: `${API_URL}/accounts`};
	static BUSINESS_ACCOUNTS_STATUS: BackendProps                             = {url: `${API_URL}/accounts/status`};
	static BUSINESS_ACCOUNTS_DELETE_DOCS: string => BackendProps              = docs => ({url: `${API_URL}/accounts/docs/${docs}`});
	static BUSINESS_ACCOUNTS_DELETE_IMAGES: string => BackendProps            = image => ({url: `${API_URL}/accounts/images/${image}`});
	static PARTNERS: BackendProps                                             = {url: `${API_URL}/partners`};
	static PARTNERS_DISCONNECT: BackendProps                                  = {url: `${API_URL}/partners/disconnect`};
	static PARTNERS_CONNECT_REQUEST: BackendProps                             = {url: `${API_URL}/partners/connect/request`};
	static SUPPLIERS: BackendProps                                            = {url: `${API_URL}/suppliers`};
	static SUPPLIERS_COUNT: BackendProps                                      = {url: `${API_URL}/suppliers/count`};

	//
	// Audit
	//
	static HISTORY: string => BackendProps                                    = key => ({url: `${API_URL}/history/${key}`});
	static HISTORY_CONTRIBUTIONS: string => BackendProps                      = key => ({url: `${API_URL}/history/contributions/${key}`});
	static HISTORY_LOYALTY: BackendProps                                      = {url: `${API_URL}/history/loyalty`};

	//
	// Logged in user Related
	//
	static FEEL: BackendProps                                                 = {url: `${API_URL}/feel`};
	static FAVOURITES: BackendProps                                           = {url: `${API_URL}/favourites`};

	//
	// User Profile
	//
	static PROFILE: BackendProps                                              = {url: `${API_URL}/profile`};
	static PROFILE_DISPLAY_NAME: BackendProps                                 = {url: `${API_URL}/profile/display-name`};
	static PROFILE_INTERESTS: BackendProps                                    = {url: `${API_URL}/profile/interests`};
	static PROFILE_FRIENDS_GET: string => BackendProps                        = key => ({url: `${API_URL}/profile/${key}/friends`});
	static PROFILE_FRIENDS: BackendProps                                      = {url: `${API_URL}/profile/friends`};
	static PROFILE_FRIENDS_ACCEPT: BackendProps                               = {url: `${API_URL}/profile/friends/accept`};
	static PROFILE_FRIENDS_IGNORE: BackendProps                               = {url: `${API_URL}/profile/friends/ignore`};
	static PROFILE_FRIENDS_REQUESTS: BackendProps                             = {url: `${API_URL}/profile/friends/requests`};

	// User Profile Settigns
	static PROFILE_SETTINGS: BackendProps                                     = {url: `${API_URL}/profile/settings`};
	static PROFILE_SETTINGS_NATIVE_LOGIN: BackendProps                        = {url: `${API_URL}/profile/settings/native-login`};
	static PROFILE_SETTINGS_DISCONNECT_SOCIAL: string => BackendProps         = provider => ({url: `${API_URL}/profile/settings/disconnect-social/${provider}`});
	static PROFILE_SETTINGS_INTERESTS: BackendProps                           = {url: `${API_URL}/profile/settings/interests`};

	//
	// Business Account Profile
	//
	static SOCIAL_SUPPLIERS: BackendProps                               = {url: `${API_URL}/social/suppliers`};
	static SOCIAL_SUPPLIERS_FRIENDS: BackendProps                       = {url: `${API_URL}/social/suppliers/friends`};
	static SOCIAL_SUPPLIERS_ACCEPT: BackendProps                        = {url: `${API_URL}/social/suppliers/accept`};

	static NODES: BackendProps                                          = {url: `${API_URL}/nodes`};
	static POIS: BackendProps                                           = {url: `${API_URL}/pois`};
	static POIS_COUNT: BackendProps                                     = {url: `${API_URL}/pois/count`};
	static POIS_PENDING_REVIEW: BackendProps                            = {url: `${API_URL}/pois/pending-review`};
	static POIS_CHANGE_STATUS: BackendProps                             = {url: `${API_URL}/pois/status`};
	static POIS_REVISIONS: BackendProps                                 = {url: `${API_URL}/pois/revisions`};
	static EXPERIENCES: BackendProps                                    = {url: `${API_URL}/experiences`};

	static REVIEWS_OVERVIEW: BackendProps                               = {url: `${API_URL}/reviews/overview`};
	static REVIEWS: BackendProps                                        = {url: `${API_URL}/reviews`};
	static REVIEW_UPDATE_STATUS: string => BackendProps                 = key => ({url: `${API_URL}/reviews/status/${key}`});

	//
	// Availability and Booking
	//
	static SNE_TICKET_TYPES: BackendProps                               = {url: `${API_URL}/availability/commons/sne/ticket-types`};
	static CRUISE_PERIOD: BackendProps                                  = {url: `${API_URL}/availability/commons/cruise/periods`};
	static AVAILABILITY: BackendProps                                   = {url: `${API_URL}/availability`};
	static BUY_OVERVIEW: BackendProps                                   = {url: `${API_URL}/buy/overview`};
	static BUY_BOOK: BackendProps                                       = {url: `${API_URL}/buy/book`};
	static BUY_CONFIRMATION: BackendProps                               = {url: `${API_URL}/buy/confirmation`};

	static PLAY_QUESTIONS: BackendProps                                 = {url: `${API_URL}/play/questions`};
	static PLAY_QUESTIONS_V2: BackendProps                              = {url: `${API_URL}/play/questions/v2`};
	static PLAY_QUESTIONS_PERSONALIZED: BackendProps                    = {url: `${API_URL}/play/questions/personalized`};
	// static PLAY_QUESTIONS                                       = {url: `${API_URL}/play/tree`};
	static PLAY: BackendProps                                           = {url: `${API_URL}/play`};
	static PLAY_V2: BackendProps                                        = {url: `${API_URL}/play/v2`};

	//
	// Audit Trail
	//
	static TRACK_VISIT_NODE: BackendProps                               = { url: `${API_URL}/track/visit/node` };

	//
	// Notarization
	//
	static NOTARIZATION_RATING: BackendProps                            = {url: `${API_URL}/notarization/rating/latest`};

	//
	// External Services
	//
	// Image Recognition
	static ANALYZE: BackendProps                                        = {url: `${IMRE_URL}/imre_tags`};
	// Image Captioning
	static IMCAP_CAPTIONS: BackendProps                                 = {url: `${IMCAP_URL}/places365`};
	// NLP
	static NLP_POIS_TAGS: BackendProps                                  = {url: `${NLP_URL}/pois_tags`};
}
