// @flow
import ElyTheme from 'theme';
import { BrowserRouter } from 'react-router-dom';
import { I18nProvider } from 'context/i18n-context';
import { Provider as ReduxProvider } from 'react-redux';
import JwtContext from 'auth/JwtContext';
import IdTokenContext from 'auth/IdTokenContext';
import { ThemeProvider } from '@mui/material/styles';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import { AuthModalContext } from 'components/auth/auth-modal-context';

type Props = {
	reduxStore: Object,
	i18n: Object,
	jwt: Object,
	idToken: Object,
	authModal: Object,
	children: React$Node,
};

const Providers = ({ reduxStore, i18n, jwt, idToken, authModal, children }: Props): React$Node => (
	<ReduxProvider store={reduxStore}>
		<BrowserRouter>
			<I18nProvider value={i18n}>
				<StyledEngineProvider injectFirst>
					<ThemeProvider theme={ElyTheme}>
						<JwtContext.Provider value={jwt}>
							<IdTokenContext.Provider value={idToken}>
								<AuthModalContext.Provider value={authModal}>
									{children}
								</AuthModalContext.Provider>
							</IdTokenContext.Provider>
						</JwtContext.Provider>
					</ThemeProvider>
				</StyledEngineProvider>
			</I18nProvider>
		</BrowserRouter>
	</ReduxProvider>
);

export default Providers;