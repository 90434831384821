// @flow
import { useMemo, useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router';
import { useMe, useAuth } from 'auth';
import { Backend, get, post } from 'actions/backend';
import { TagGroup } from 'utils/variables/tag-group-vars';
import { HttpResponse } from 'utils/variables/http-response';
import { SSR_RESPONSE, SsrUtils } from 'utils/helpers/ssr-utils';
import { transformProfile, getCurrentTab } from 'components/public/profile/profile-helper';
import UserProfile from 'components/public/profile/user-profile';
import GenericNotFound from 'components/public/general/generic-not-found';
import useSearchFriends from 'components/public/profile/useSearchFriends';
import useProfileFriends from 'components/public/profile/useProfileFriends';
import useProfileHistory from 'components/public/profile/useProfileHistory';

export const LIMIT = 10;
export type InitialPayloadProps = { data: Array<any>, offset: number, hasMore: ?boolean, total: ?number };
export const InitialPayload: InitialPayloadProps = { data: [], offset: 0, hasMore: undefined, total: undefined };

const ProfileWrapper = (): React$Node => {
	const { key } = useMe() ?? {};
	const params = useParams();
	const { isAuthenticated } = useAuth();
	const [profile, setProfile] = useState(undefined);
	const [isFriend, setFriend] = useState(undefined);
	const [feel, setFeel] = useState(undefined);
	const [feelings, setFeelings] = useState(undefined);
	const [achievements, ] = useState(undefined);
	const [tab, setTab] = useState(getCurrentTab(params?.tab));
	const isMe = useMemo(() => key === params.key, [key, params.key]);

	const { history, onLoadHistory, contributions, onLoadContributions } = useProfileHistory(params.key);
	const { friends, onLoadFriends, addFriend, removeFriend, acceptFriend, ignoreFriend } = useProfileFriends(params.key, isMe, setFriend);
	const {	search, onSearchFriends, onSearchFriendsMore } = useSearchFriends(params.key);
	const friendsProps = {
		...(search.total !== undefined ? search : friends),
		onLoadMore: search.total !== undefined ? onSearchFriendsMore : onLoadFriends,
		onSearchFriends,
	};

	const onStatusSubmit = useCallback((values): Object => {
		post(Backend.FEEL, values)
			.then(res => setFeel(res.data));
	}, []);

	//
	// Set current tab index
	//
	useEffect(() => {
		setTab(getCurrentTab(params.tab));
	}, [params.tab]);

	//
	// Load profile data
	//
	useEffect(() => {
		if ( SsrUtils.hasServerData() ) {
			setProfile(
				SsrUtils.getServerData() === SSR_RESPONSE.NOT_FOUND
					? HttpResponse.NOT_FOUND
					: transformProfile(SsrUtils.getServerData())
			);
			SsrUtils.setServerData(undefined);
		}
		else {
			get(Backend.PROFILE, params.key, { showErrors: false })
				.then(res => {
					const { isFriend, feel, ...other } = res.data;
					setFriend(isFriend);
					setFeel(feel);
					setProfile(transformProfile(other));
				})
				.catch(() => setProfile(HttpResponse.NOT_FOUND));
		}
	}, [params.key]);

	//
	// Load commons
	// Get the tags with tag group TagGroup.FEELINGS
	//
	useEffect(() => {
		if ( isMe && !feelings ) {
			get(Backend.COMMONS_TAG_GROUPS, {code: TagGroup.FEELINGS})
				.then(res => setFeelings(res.data.flatMap(ptg => ptg.tags)));
		}
	}, [isMe, feelings]);

	//
	// Load current tab data
	//
	useEffect(() => {
		if ( isAuthenticated && (isMe || isFriend?.active) ) {
			if ( tab === 0 && history.total === undefined ) {
				onLoadHistory();
			}
			if ( tab === 1 && contributions.total === undefined ) {
				onLoadContributions();
			}
			if ( tab === 2 && friends.total === undefined ) {
				onLoadFriends();
			}
		}
	}, [isAuthenticated, isMe, isFriend, tab, history, contributions, onLoadHistory, friends, onLoadFriends, onLoadContributions]);

	if ( !profile ) return null;
	if ( profile === HttpResponse.NOT_FOUND ) return (
		<GenericNotFound />
	);
	return (
		<UserProfile
			isLoggedIn={isAuthenticated}
			profile={profile}
			isMe={isMe}
			isFriend={isFriend}
			feel={feel}
			feelings={feelings}
			achievements={achievements}
			addFriend={addFriend}
			removeFriend={removeFriend}
			acceptFriend={acceptFriend}
			ignoreFriend={ignoreFriend}
			history={history}
			onLoadHistory={onLoadHistory}
			contributions={contributions}
			onLoadContributions={onLoadContributions}
			onStatusSubmit={onStatusSubmit}
			friends={friendsProps}
			tabProps={{
				current: tab,
				setTab: setTab,
				all: { to: profile.path },
				contributions: { to: `${profile.path}/contributions` },
				friends: { to: `${profile.path}/friends` }
			}}
		/>
	);
};

export default ProfileWrapper;
