// @flow
import { NavLink } from 'react-router-dom';

import "scss/components/general/ely-nav-link.scss";

type Props = {|
	to: string | Object,
	color?: string,
	variant?: string,
	className?: string,
	children?: React$Node
|};

const CmsNavLink = ({ to, color = "primary", className, children, ...other}: Props): React$Node => {
	const colorClass = `nav-link--${color}`;
	const restClasses = className || '';
	return (
		<NavLink
			to={to}
			className={`nav-link ${colorClass} ${restClasses}`}
			{...other}
		>
			{children}
		</NavLink>
	);
};

export default CmsNavLink;
