import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import { isAsideOpen } from './app-reducer';
import { isLoading, hasErrors, errors, errorCount } from './ajax-reducer';
import { isGoogleMapLoading, isGoogleMapLoaded } from 'reducers/google/map';

import MeReducer from './me-reducer';

const rootReducer = combineReducers({

	// Auth
    me: MeReducer,

    // General
    isAsideOpen,
    isLoading,
    hasErrors,
    errors,
    errorCount,
    isGoogleMapLoading, isGoogleMapLoaded,

    // Redux Form
    form: formReducer
});

export default rootReducer;