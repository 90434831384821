// @flow
import { useRef, useState, useEffect, useCallback } from 'react';
import useLoadFacebook from 'hooks/social/useLoadFacebook';
import { Button, LinkButton } from 'components/general/button';

// eslint-disable-next-line
const getIsMobile = () => {
    let isMobile = false;

    try {
        isMobile = !!(
            (window.navigator && window.navigator.standalone) ||
            navigator.userAgent.match('CriOS') ||
            navigator.userAgent.match(/mobile/i)
        );
    } catch (ex) {
        // continue regardless of error
    }

    return isMobile;
};

type Props = {
    scope: string,
    variant?: string,
    onConnect?: void => void,
    onLink?: void => void,
    className?: string,
    children?: React$Node
}

const SocialButtonFacebook = ({ scope, variant = "link", onConnect, onLink, className = "", children }: Props): React$Node => {
    const _isMounted = useRef(true);
    const [isProcessing, setProcessing] = useState(false);
    const { isSdkLoaded } = useLoadFacebook();

    // TODO: isProcessing check should be done where onConnect/onLink are being defined
    const setProcessingIfMounted = useCallback(processing => {
        _isMounted.current && setProcessing(processing);
    }, []);

    const onClick = useCallback(() => {
        if ( !isSdkLoaded || isProcessing ) {
            return;
        }
        setProcessingIfMounted(true);

        // TODO: In mobile we should redirect instead of using the popup
        // if ( getIsMobile() ) {
        //     // Use query-string plugin instead of custom getParamsFromObject
        //     window.location.href = `//www.facebook.com/dialog/oauth${getParamsFromObject(params)}`;
        // }
        // else {
        window.FB.login(res => {
            if ( res?.authResponse?.accessToken ) {
                onConnect?.(res?.authResponse?.accessToken)
                    ?.finally(() => setProcessingIfMounted(false));
                onLink?.(res?.authResponse?.accessToken)
                    ?.finally(() => setProcessingIfMounted(false));
            }
            else {
                setProcessingIfMounted(false);
            }
        }, { scope });
        // }
    }, [scope, isSdkLoaded, isProcessing, onConnect, onLink, setProcessingIfMounted]);

    useEffect(() => {
        return () => { _isMounted.current = false }
    }, []);

    if ( !isSdkLoaded ) return null;
    return (
        <>
            {
                variant === "link" &&
                <LinkButton className={className} onClick={onClick}>
                    {children || 'Facebook'}
                </LinkButton>
            }
            {
                variant === "button" &&
                <Button className={className} onClick={onClick}>
                    {children || 'Facebook'}
                </Button>
            }
        </>
    );
}

export default SocialButtonFacebook;
