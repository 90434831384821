import { getIn } from 'formik';
import { ElyText } from 'components/general/input/index';

const FormikText = ({field, form: {touched, errors}, ...props}) => {
	const error = (getIn(touched, field.name) && getIn(errors, field.name));
	return (
		<ElyText
			{...field}
			{...props}
			error={error}
		/>
	);
};

export default FormikText;