const SUPPLIER_SORTERS = [
	{label:'Name', value:'name'}
];

const SERVICE_SORTERS = [
	{label:'Title', value:'title'},
	{label:'Service Type', value:'serviceType'},
	{label:'Supplier', value:'supplier.title'},
	{label:'Last Updated', value:'updatedAt'}
];

const ACTIVITY_SORTERS = [
	{label:'Title', value:'title'},
	{label:'Last Updated', value:'updatedAt'}
];

export { SUPPLIER_SORTERS, SERVICE_SORTERS, ACTIVITY_SORTERS }