// @flow
import { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';

import "scss/components/general/modal/modal-template.scss";

type ModalTemplateProps = {
    children: React$Node
}

const modalTemplate = document.getElementById('ely-modal-template');
const ModalTemplate = ({ children }: ModalTemplateProps) => {
    const [modal, setModal] = useState();

    useEffect(() => {
        const modalTmp = document.createElement('div');
        if ( modalTemplate ) {
            modalTmp.classList.add('modal-template');
            modalTemplate.appendChild(modalTmp);
            modalTemplate.classList.add('is-modal-template-open');
            setModal(modalTmp);
        }
        return () => {
            if ( modalTemplate ) {
                modalTemplate.removeChild(modalTmp);
                modalTemplate.classList.remove('is-modal-template-open');
            }
        }
    }, []);

    if ( !modal ) return null;
    return ReactDOM.createPortal(
        children,
        modal
    );
};

export default ModalTemplate;