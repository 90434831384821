import { getIn } from 'formik';
import { ElyPassword } from 'components/general/input/index';

const FormikPassword = ({field, form: {touched, errors}, ...props}) => {
	const error = (props.multiline && getIn(errors, field.name)) || (getIn(touched, field.name) && getIn(errors, field.name));
	return (
		<ElyPassword
			{...field}
			{...props}
			error={error}
		/>
	);
};

export default FormikPassword;