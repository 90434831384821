// @flow
import { FileUtils } from 'utils/helpers';
import { NodeTypeAlias } from 'utils/variables';
import MediaType from 'utils/variables/media-type';
import type { DocProps } from 'types/general/doc-props';
import type { NodeTypeAliasProps } from 'types/general/commons';
import type { ApiDocProps } from 'types/api/general/api-doc-props';
import type { ApiPhotoProps, ApiVideoProps } from 'types/api/general/api-media-props';
import type { PhotoProps, VideoProps } from 'types/general/photo-props';

import placeholderImg from 'img/placeholder.png';
import userPlaceholder from 'img/user-placeholder.png';
import ActivityThumbPlaceholder from 'img/poi/activity-40.svg';
import LocationThumbPlaceholder from 'img/poi/location-40.svg';
import ActivityPlaceholder from 'img/poi/activity-400-293.svg';
import LocationPlaceholder from 'img/poi/location-400-293.svg';

export function transformDoc(doc: ApiDocProps): DocProps {
	if (!doc) return undefined;
	return {
		key: doc.key,
		url: FileUtils.getDocUrl(doc.filename),
		name: doc.originalFilename,
		size: Math.round(doc.size / 1024),
	};
}
export function transformDocs(docs: Array<ApiDocProps>): Array<DocProps> {
	return (docs || []).map(doc => transformDoc(doc));
}

export function transformPhoto(photo: ApiPhotoProps, defaultAlt: string = 'photo'): PhotoProps {
	if (!photo) return undefined;
	const photoTransformed = {
		type: 'img',
		alt: photo.alt ?? defaultAlt,
	};

	if (photo.key) photoTransformed.key = photo.key;
	if (photo.filename) {
		photoTransformed.filename = photo.filename;
		photoTransformed.src = FileUtils.getImageUrl(photo.filename); // Original photo size
		photoTransformed.thumbnail = FileUtils.getImageUrl(photo.filename, FileUtils.SIZE_THUMBNAIL);
		photoTransformed.small = FileUtils.getImageUrl(photo.filename, FileUtils.SIZE_SMALL);
		photoTransformed.medium = FileUtils.getImageUrl(photo.filename, FileUtils.SIZE_MEDIUM);
	}
	if (photo.caption) photoTransformed.caption = photo.caption;
	if (photo.mediaType) {
		photoTransformed.mediaType = photo.mediaType;
		photoTransformed.featured = MediaType.PRIMARY === photo.mediaType;
	}

	return photoTransformed;
}
export function transformPhotos(photos: Array<ApiPhotoProps>, defaultAlt: string = 'photo'): Array<PhotoProps> {
	return (photos || []).map(photo => transformPhoto(photo, defaultAlt));
}

export function transformVideo(video: ApiVideoProps): VideoProps {
	if (!video) return undefined;
	return {
		key: video.key,
		type: 'video',
		src: video.url,
		thumbnail: FileUtils.getVideoThumbnail(video.url),
		alt: video.alt,
	}
}
export function transformVideos(videos: Array<ApiVideoProps>): Array<VideoProps> {
	return (videos || []).map(transformVideo);
}

export function getPoiImagePlaceholder(nodeTypeAlias: NodeTypeAliasProps): PhotoProps {
	if (NodeTypeAlias.POI === nodeTypeAlias) {
		return {
			key: 'placeholder',
			type: 'img',
			src: ActivityPlaceholder,
			thumbnail: ActivityThumbPlaceholder,
			small: ActivityPlaceholder,
			medium: ActivityPlaceholder,
			alt: 'Activity generic',
			caption: '',
			featured: true,
			mediaType: MediaType.PRIMARY,
		}
	}
	if (NodeTypeAlias.AREA === nodeTypeAlias) {
		return {
			key: 'placeholder',
			type: 'img',
			src: LocationPlaceholder,
			thumbnail: LocationThumbPlaceholder,
			small: LocationPlaceholder,
			medium: LocationPlaceholder,
			alt: 'Location generic',
			caption: '',
			featured: true,
			mediaType: MediaType.PRIMARY,
		}
	}
	return {
		key: 'placeholder',
		type: 'img',
		src: placeholderImg,
		thumbnail: placeholderImg,
		small: placeholderImg,
		medium: placeholderImg,
		alt: 'Placeholder',
		caption: '',
		featured: true,
		mediaType: MediaType.PRIMARY,
	}
}

export function getUserImagePlaceholder(alt = 'photo'): PhotoProps {
	return {
		key: 'placeholder',
		type: 'img',
		thumbnail: userPlaceholder,
		small: userPlaceholder,
		medium: userPlaceholder,
		src: userPlaceholder,
		alt: alt,
		caption: '',
		mediaType: MediaType.IMAGE,
	}
}
