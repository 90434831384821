// @flow
import { useRef, useState, useEffect, useCallback } from 'react';
import { type SocialLinksProps } from 'types/user-props';
import { THIRD_PARTIES, getThirdPartyRegex } from 'utils/variables/third-party';
import { FieldArray } from 'formik';
import { ElyListTextField } from 'components/general/input/fields';
import ElySettingsTitle from 'components/general/account/settings/ely-settings-title';

import "scss/components/general/account/settings/social-links.scss";

type SocialLinkProps = {
	code: string,
	label: string,
	value: ?string,
	setFieldValue: (string, string) => void
}
type SocialLinksCompProps = {
	values: SocialLinksProps,
	setFieldValue: (string, string) => void
}

const SocialLink = ({ code, name, label, value, validate, setFieldValue }: SocialLinkProps) => {
	const inputRef = useRef(null);
	const [editing, setEditing] = useState(false);
	const onLinkClick = useCallback(() => {
		setEditing(true);
	}, []);

	useEffect(() => {
		if ( editing )
			inputRef.current.focus();
	}, [editing]);

	return (
		<div className="profile-settings__social__item">
			<span className={`profile-settings__social__item__logo ${code.toLowerCase()}`}>label</span>
			{
				!value && !editing &&
				<button type="button" className="u-link icon-chain" onClick={onLinkClick}>Link</button>
			}
			{
				(!!value || editing) &&
				<ElyListTextField
					required={true}
					inputRef={inputRef}
					name={`${name}.path`}
					label={label}
					onBlur={() => setEditing(false)}
					remove={() => setFieldValue(`${name}.path`, '')}
					validate={validate}
				/>
			}
		</div>
	);
};

const SocialLinks = ({ values, setFieldValue }: SocialLinksCompProps) => (
	<div className="profile-settings__social">
		<ElySettingsTitle title="Social Links" />

		<FieldArray
			name="socials"
			render={() => (
				<>
					{
						THIRD_PARTIES.map((thirdParty, index) => {
							const thirdPartyValue = values
								?.filter(value => value.thirdParty === thirdParty.code)
								?.[0];
							return (
								<SocialLink
									key={thirdParty.code}
									code={thirdParty.code}
									name={`socialLinks.${index}`}
									label={`${thirdParty.label} link`}
									value={thirdPartyValue?.path}
									setFieldValue={setFieldValue}
									validate={validateThirdPartyLink(thirdParty.code)}
								/>
							)
						})
					}
				</>
			)}
		/>
	</div>
);

const validateThirdPartyLink = social => {
	const regex = getThirdPartyRegex(social);
	return value => !regex.test(value)
		? "Invalid third party link"
		: undefined;
}

export default SocialLinks;