// @flow
import Icon from 'components/general/icon';
import ElyLink from 'components/general/link';
import Button from 'components/general/button/button';
import 'scss/components/auth/join/join-business-terms.scss';

type Props = {
  isRegister: boolean,
  onClick: void => void,
};

const JoinBusinessTermsSupplier = ({ isRegister, onClick }: Props): React$Node => (
	<div className="JoinBusinessTerms">
		<h2 className="title">Become a Supplier</h2>
		<p>An Experly Partner can offer their own service, much like a Supplier, but can also register and manage other Suppliers who may not be able to do it on their own.</p>
		<span className="benefits-title">Benefits include:</span>
		<ul className="benefits">
			<li className="benefits__item"><Icon code="settings" />global audience visibility</li>
			<li className="benefits__item"><Icon code="settings" />flexible service management</li>
			<li className="benefits__item"><Icon code="settings" />B2B networking</li>
			<li className="benefits__item"><Icon code="settings" />attractive in-platform service promotion</li>
			<li className="benefits__item"><Icon code="settings" />loyalty club participation</li>
			<li className="benefits__item"><Icon code="settings" />service analytics and statistics</li>
		</ul>
		<p>By selecting Agree and continue, I agree to Experly's <ElyLink to="/legal/user/terms" target="_blank">Terms of Service</ElyLink>, <ElyLink target="_blank">Payments Terms of Service</ElyLink>, and <ElyLink target="_blank">Nondiscrimination Policy</ElyLink> and acknowledge the <ElyLink to="/privacy" target="_blank">Privacy Policy</ElyLink>.</p>
    { !isRegister && <Button onClick={onClick} className="submit">Agree and continue</Button> }
	</div>
);

export default JoinBusinessTermsSupplier;
