import { createTheme } from '@mui/material/styles';
import sassVariables from 'scss/global/base/variables.module.scss';

const ElyTheme = createTheme({
	typography: {
		fontFamily: 'Poppins',
		fontSize: 15,
		fontWeightLight: 300,
		fontWeightRegular: 400,
		fontWeightMedium: 500,
		display4: {},
		display3: {},
		display2: {},
		display1: {},
		headline: {
			fontSize: '1.1875rem'
		},
		title: {},
		subheading: {},
		body2: {
			fontStyle: 'normal',
			fontHeight: 'normal',
			lineHeight: 'normal',
			fontSize: '0.75rem'
		},
		body1: {
			fontSize: '0.8125rem'
		},
		caption: {
			fontSize: '0.6875rem'
		},
		button: {},
		h1: {},
		h2: {
			color: '#333333',
			fontFamily: 'Poppins',
			fontWeight: 600,
			fontSize: '40px',
			lineHeight: 'normal'
		},
		h3: {},
		h4: {
			margin: "10px 0",
			fontSize: "1em",
			fontWeight: 600
		},
		h5: {},
		h6: {},
		subtitle1: {
			fontSize: '0.6875rem'
		},
		subtitle2: {},
		body1Next: {},
		body2Next: {},
		buttonNext: {},
		captionNext: {},
		overline: {},
		useNextVariants: true
	},
	palette: {
		primary: {
			main: '#4DA1FF'
		},
		secondary: {
			main: '#4DA1FF'
		},
		warning: {
			main: '#f50057',
			light: 'rgb(255, 226, 236)',
		}
	},
	spaces: {
		spaceXxxLarge: sassVariables.spaceXxxLarge,
		spaceXxlarge: sassVariables.spaceXxlarge,
		spaceXlarge: sassVariables.spaceXlarge,
		spaceLarge: sassVariables.spaceLarge,
		spaceMedium: sassVariables.spaceMedium,
		spaceSmall: sassVariables.spaceSmall,
		spaceSmaller: sassVariables.spaceSmaller,
		spaceSmallest: sassVariables.spaceSmallest,
		spaceExternal: sassVariables.spaceExternal,
	},
	components: {
		MuiSnackbar: {
			styleOverrides: {
				anchorOriginTopCenter: {
					top: '90px'
				},
				anchorOriginBottomCenter: {
					bottom: '5px'
				}
			}
		},
		MuiFormControl: {
			styleOverrides: {
				root: {
					width: '100%'
				}
			}
		},
		MuiFormGroup: {
			styleOverrides: {
				root: {
					display: 'flex',
					flexDirection: 'row',
					width: '200px',
					justifyContent: 'space-between'
				}
			}
		},
		MuiFormLabel: {
			styleOverrides: {
				root: {
					fontSize: '0.9rem',
					color: '#333',
					fontFamily: "'Poppins', sans-serif",
					'&.Mui-focused': {
						color: '#4DA1FF'
					}
				}
			}
		},
		MuiInput: {
			styleOverrides: {
				root: {
					fontSize: '1rem',
					fontFamily: "'Poppins', sans-serif"
				},
				underline: {
					'&:before': {
						borderBottomColor: '#d8d8d8'
					},
					'&:after': {
						borderBottomColor: '#4DA1FF'
					}
				}
			}
		},
		MuiTabs: {
			styleOverrides: {
				root: {
					marginBottom: "1px",
					color: sassVariables.bluemedium,
				},
				indicator: {
					height: '3px',
					backgroundColor: sassVariables.oceandark
				}
			}
		},
		MuiTab: {
			styleOverrides: {
				textColorInherit: {opacity: 1},
				root: {
					textTransform: 'initial',
					opacity: 1,
					fontWeight: 800,
					color: sassVariables.bluemedium,
					"&.Mui-selected": {
						"color": "#333"
					}
				},
				selected: {}
			}
		},
		MuiPickersToolbarText: {
			toolbarTxt: {
				styleOverrides: {
					color: "#fff"
				}
			},
			toolbarBtnSelected: {
				styleOverrides: {
					color: "#fff"
				}
			}
		},
	}
});

export default ElyTheme;
