// @flow
import "scss/components/general/emote.scss";

type Props = {
	code: string,
	title?: string,
	size?: 'standard' | 'small',
	showTitle?: boolean,
	className?: string
};

const Emote = ({ code, title = '', size = 'standard', showTitle = false, className = '' }: Props): React$Node => {
	const codeClass = `ely-emote--${code}`;
	const sizeClass = `ely-emote--${size}`;
	const titleClass = showTitle ? 'show-title' : '';
	return (
		<span className={`ely-emote ${codeClass} ${sizeClass} ${titleClass} ${className}`}>{title}</span>
	);
}

export default Emote;
