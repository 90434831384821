// @flow
import { useSelector } from 'react-redux';
import AuthModal from 'components/auth/auth-modal';
import Loading from 'components/general/loading/loading';
import ExperlyRouter from 'components/routers/experly-router';
import HeaderMetadata from 'components/public/header-metadata';
import { Notification } from 'components/general/notification';
import { useAuthModal } from 'components/auth/auth-modal-context';
import useInitializeAnalyticsAndCookieConsent from 'hooks/useInitializeAnalyticsAndCookieConsent';

// EXP-859: Remove EDL and Notarization from web app
// const EdlPoc01Wrapper = lazy(() => import('components/edl/poc01/edl-poc-wrapper'));
// const EdlPoc02Wrapper = lazy(() => import('components/edl/poc02/App'));

const Experly = (): React$Node => {
	useInitializeAnalyticsAndCookieConsent();
	const { isAuthOpen, authType } = useAuthModal();
	const isAsideOpen = useSelector(state => state.isAsideOpen);
	return (
		<>
			<HeaderMetadata />
			<div className={isAsideOpen ? 'is-aside-open' : ''}>
				<ExperlyRouter />
				<AuthModal
					isOpen={isAuthOpen}
					authType={authType}
				/>
				<Notification/>
				<Loading />
			</div>
		</>
	);
};

export default Experly;
