// @flow
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import METADATA from 'utils/variables/metadata';

const HeaderMetadata = (): React$Node => {
	const location = useLocation();
	const data = METADATA[location.pathname];

	if ( !data ) { // if no ssr data for current path default title to /
		return (
			<Helmet>
				<title>{METADATA["/"].title}</title>
			</Helmet>
		);
	}
	return (
		<Helmet>
			<title>{data.title}</title>
			<meta property="og:title" content={data.title}/>
			<meta property="og:description" name="description" content={data.description}/>
			<meta property="og:image" content={data.image}/>
			<meta property="og:image:width" content={data.imageWidth} />
			<meta property="og:image:height" content={data.imageHeight} />
			<meta property="og:url" content={data.url}/>
			{/*<meta name="twitter:card" content="TBD" />*/}
		</Helmet>
	);
};

export default HeaderMetadata;