// @flow
import Button from 'components/general/button/button';

type LinkButtonProps = {|
	type?: string,
	color?: 'primary' | 'secondary' | 'success' | 'warning' | 'hollow',
	className?: string,
	children?: React$Node,
	onClick?: (e: SyntheticEvent<HTMLButtonElement>) => void
|};

const LinkButton = ({ children, ...other }: LinkButtonProps): React$Node => (
	<Button variant="link" {...other}>
		{children}
	</Button>
);

export default LinkButton;
