export class LoadingService {

	register(loading) {
		this.loading = loading;
	}

	unregister() {
		this.loading = null;
	}

	show() {
		if ( !this.loading ) return;
		this.loading.show = true;
	}

	hide() {
		if ( !this.loading ) return;
		this.loading.show = false;
	}

	forceShow() {
		if ( !this.loading ) return;
		this.loading.force = true
	}

	forceHide() {
		if ( !this.loading ) return;
		this.loading.force = false;
	}

	showFor(promises) {
		this.forceShow();
		Promise.all(promises)
			.finally(() => this.forceHide());
	}
}

const loadingService = new LoadingService();
export { loadingService };