// @flow
import type { PhotoProps } from 'types/general/photo-props';
import { Backend, post, del } from 'actions/backend';
import confirm from 'components/general/modal/confirm';
import { notificationService } from 'components/general/notification';

const MAX_FILE_SIZE = 2.5 * 4194304; /*2.5 * 4MB = 10MB*/

type ReturnProps = {
	onUpload: Array<Object> => Array<Promise<any>>,
	onSort: Array<PhotoProps> => Promise<any>,
	onDelete: string => Promise<any>,
};

function showErrorMsg(msg) {
	notificationService.show({
		level: "warning",
		title: "Error",
		messages: [{ level: "warning", code: msg }],
	});
}

export function onUpload(filesData: Array<Object>): Array<Promise<any>> {
	return filesData.map(({ file, owner, mediaType, order, override = false }) => {

		if (file.size > MAX_FILE_SIZE) {
			showErrorMsg('error.file.exceed.size.limit');
			return Promise.reject('File size limit is 10MB per image');
		}

		const formData = new FormData();
		formData.append('file', file);
		formData.append('mediaType', mediaType);
		formData.append('override', override.toString());
		if (order) formData.append('order', order);
		if (owner) formData.append('owner', owner);
		return post(Backend.MEDIA, formData, {
			headers: {
				"Content-Type": "multipart/form-data",
			},
		});
	});
}

export default function useImageApi(): ReturnProps {

	//
	// On Image Sort
	//
	function onSort(images: Array<PhotoProps>): Promise<any> {
		return post(Backend.MEDIA_IMAGES_SORT, images);
	}

	//
	// On Image Delete
	//
	function onDelete(key, { disableConfirmation = false }) {
		if (disableConfirmation) {
			return del(Backend.MEDIA, key);
		}
		return confirm({ message: 'Are you sure you want to delete this photo?' })
			.then(() => del(Backend.MEDIA, key));
	}

	return {
		onUpload,
		onSort,
		onDelete,
	};
}
