// @flow
import type { JwtProps } from 'auth/types';
import { createContext } from 'react';
import { Roles } from 'auth/index';

export const InitialJwt: JwtProps = {
	initialized: false,
	isAuthenticated: false,
	accessToken: null,
	expiration: null,
	authorities: [],
	permissions: [],
	dominantRole: Roles.USER,
	rolePath: "/"
}

const JwtContext: React$Context<JwtProps> = createContext(InitialJwt);
export default JwtContext;
