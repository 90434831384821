// @flow
import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import type { PhotoProps } from 'types/general/photo-props';
import { Button } from 'components/general/button';
import { ElyTextField } from 'components/general/input/fields';
import ElySettingsTitle from 'components/general/account/settings/ely-settings-title';

import userPlaceholder from 'img/user-placeholder.png';
import "scss/components/general/account/settings/basic-information.scss";

type Props = {
	photos: ?Array<PhotoProps>,
	onImageDrop: (Array<Object>, Array<Object>, Object) => Array<Promise<any>>,
	onImageDelete: string => Promise<any>
};

const BasicInformation = ({ photos, onImageDrop, onImageDelete }: Props): React$Node => {
	const { getRootProps, getInputProps, open } = useDropzone({ onDrop:onImageDrop, onDrag:false, noClick:true, multiple:false });
	const onImageDeleteLocal = useCallback(e => {
		photos?.[0] && onImageDelete(photos?.[0]?.key);
		e.stopPropagation();
	}, [photos, onImageDelete]);
	return (
		<div className="profile-settings__basic">
			<ElySettingsTitle title="Basic Information"/>
			<div className="profile-settings__basic__container">
				<div className="profile-settings__basic__photo" {...getRootProps()}>
					<input {...getInputProps()} />
					{
						Number(photos?.length) > 0 &&
						<>
							<img
								src={photos?.[0]?.thumbnail}
								alt={photos?.[0]?.alt}
								className="profile-settings__basic__photo__img"
							/>
							<div className="profile-settings__basic__photo__actions">
								<Button className="profile-settings__basic__photo__new" onClick={open}>Upload new photo</Button>
								<Button color="secondary-border" className="profile-settings__basic__photo__delete" onClick={onImageDeleteLocal}>Delete</Button>
							</div>
						</>
					}
					{
						(!photos || Number(photos?.length) === 0) &&
						<>
							<img
								src={userPlaceholder}
								alt="profile"
								className="profile-settings__basic__photo__img"
							/>
							<Button className="profile-settings__basic__photo__new" onClick={open}>Upload new photo</Button>
						</>
					}
				</div>
				<div className="profile-settings__basic__rest">
					<ElyTextField
						name="firstName"
						label="Name"
						required={true}
					/>
					<ElyTextField
						name="lastName"
						label="Surname"
						required={true}
					/>
					<ElyTextField
						name="displayName"
						label="Display Name"
						required={true}
					/>
					<ElyTextField
						className="profile-settings__basic__rest__bio"
						name="bio"
						label="Bio"
						multiline={true}
						showMultilineLabel={false}
						required={true}
					/>
					<ElyTextField
						name="email"
						label="Email"
						required={true}
					/>
					<ElyTextField
						name="phone"
						label="Phone"
						required={true}
					/>
				</div>
			</div>
		</div>
	);
};

export default BasicInformation;