import { FileUtils } from 'utils/helpers';
import { transformPublicUsers } from 'utils/transform/transform-user';
import { transformPhoto, getPoiImagePlaceholder } from 'utils/transform/transform-media';
import userPlaceholder from 'img/user-placeholder.png';

export function getCurrentTab(path) {
	if ( path === 'contributions' ) return 1;
	if ( path === 'friends' ) return 2;
	return 0;
}

export function transformProfile(profile) {
	return {
		...profile,
		contributionsOverview: undefined,
		profilePhoto: FileUtils.getImageUrl(profile.photos?.[0]?.filename, FileUtils.SIZE_MEDIUM) ?? userPlaceholder,
		photos: profile.photos?.map(photo => FileUtils.getImageUrl(photo.filename, FileUtils.SIZE_SMALL)),
		interests: profile.interests?.map(int => ({
			key: int.key,
			code: int.title.toLowerCase().replace(/ /gi, "-"),
			label: int.title
		})),
		friends: transformPublicUsers(profile.friends)
	};
}

function transformHistoryItemPhotos(node) {
	if (!node || !node.photos?.length) return [getPoiImagePlaceholder(node?.nodeTypeAlias)];
	return node.photos.map(photo => transformPhoto(photo, node.title));
}

export function transformHistory(items) {
	return items.map(item => ({
		...item,
		node: {
			...item?.node,
			photos: transformHistoryItemPhotos(item?.node),
			node: {
				...item?.node?.node,
				photos: transformHistoryItemPhotos(item?.node?.node),
			}
		}
	}));
}