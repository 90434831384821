import { I18nConsumer } from 'context/i18n-context';

const withI18n = ComponentToWrap => props => (
	<I18nConsumer>
		{
			({locale, t, setLocale}) => <ComponentToWrap {...props} locale={locale} t={t} setLocale={setLocale} />
		}
	</I18nConsumer>
);

export { withI18n };
export default withI18n;