// @flow
import React from 'react';
import Icon from 'components/general/icon';
import { format, getPickerInstance } from 'utils/date';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro';
import { LocalizationProvider } from '@mui/x-date-pickers';
import 'scss/components/general/input/ely/ely-daterangepicker-shop.scss';

const FORMAT = "DD MMM";
const InputProps = {
	disableUnderline: true,
};

type ElyDateRangePickerProps = {
	name:string,
	value?:Object,
	disabled?:boolean,
	shouldDisableDate?:boolean,
	disablePast?:boolean,
	open?: boolean,
	onBlur?: SyntheticEvent<HTMLInputElement> => void,
	onChange?: SyntheticEvent<HTMLInputElement> => void,
	onOpen?: SyntheticEvent<HTMLInputElement> => void,
	onClose?: SyntheticEvent<HTMLInputElement> => void,
	className?: string,
}

const InputField = React.forwardRef((props, ref) => {
	const { name, value, ownerState, className = '' } = props;
	return (
		<div ref={ref} className={`ElyDateRangePickerShop ${className}`}>
			<button onClick={ownerState.onOpen} className="ElyDateRangePickerShop__icon">
				<Icon code="date" />
			</button>
			<button className="ElyDateRangePickerShop__field" onClick={ownerState.onOpen}>
				<span className="ElyDateRangePickerShop__value">
					{(value[0] && value[1]) ? `${format(value[0], FORMAT)} - ${format(value[1], FORMAT)}` : 'Start - End'}
				</span>
				<input
					name={name}
					value={(value[0] && value[1]) ? `${format(value[0], FORMAT)} - ${format(value[1], FORMAT)}` : 'Start - End'}
					onChange={() => {}}
					className="input"
				/>
			</button>
			<button
				type="button"
				onClick={e => ownerState.onChange([null, null])}
				className="ElyDateRangePickerShop__clear"
			>
				<Icon code="close" />
			</button>
		</div>
	)
})

const ElyDateRangePickerShop = ({
	name,
	value,
	disabled,
	shouldDisableDate,
	disablePast = false,
	open,
	onBlur,
	onChange,
	onOpen,
    onClose,
	className = '',
}: ElyDateRangePickerProps): React$Node => (
	<LocalizationProvider dateAdapter={AdapterDayjs}>
		<DateRangePicker
			value={[getPickerInstance(value?.[0]), getPickerInstance(value?.[1])]}
			open={open}
			onBlur={onBlur}
			onChange={onChange}
			onOpen={onOpen}
			onClose={onClose}
			disabled={disabled}
			disablePast={disablePast}
			shouldDisableDate={shouldDisableDate}
			className={className}
			slots={{
				field: InputField,
			}}
			slotProps={{
				field: props => ({
					name,
					InputProps,
					variant: "standard",
				})
			}}
		/>
	</LocalizationProvider>
);

export default ElyDateRangePickerShop;