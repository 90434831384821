// @flow
import { useState, useCallback, useContext, createContext } from 'react';
import { routes } from 'utils/routes';
import { useLocation, useNavigate } from 'react-router-dom';
import { type AuthTypesProps, AuthTypes } from 'components/auth/auth-utils';

export { AuthTypes };

type AuthModalContextProps = {
	authType: AuthTypesProps,
	setAuthType: AuthTypesProps => void,
};

type AuthModalProps = {
	isAuthOpen: boolean,
	authType: AuthTypesProps,
	openAuth: ?(AuthTypesProps) => void,
	closeAuth: void => void,
};

const InitialLayoutMainContext = {
	authType: AuthTypes.CLOSED,
	setAuthType: () => {},
};

export const AuthModalContext: React$Context<AuthModalContextProps> = createContext(InitialLayoutMainContext);

export function useAuthModalInitialization(initialAuthType: AuthTypesProps = AuthTypes.CLOSED): AuthModalContextProps {
	const [authType, setAuthType] = useState<AuthTypesProps>(initialAuthType);
	return {
		authType,
		setAuthType,
	}
}

export function useAuthModal(): AuthModalProps {
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const { authType, setAuthType } = useContext<AuthModalContextProps>(AuthModalContext);

	const openAuth = useCallback((type: AuthTypesProps = AuthTypes.LOGIN) => {
		if (pathname.includes(routes.AUTH)) {
			switch (type) {
				case AuthTypes.LOGIN: navigate(routes.LOGIN); return;
				case AuthTypes.FORGOT: navigate(routes.FORGOT); return;
				case AuthTypes.REGISTRATION: navigate(routes.REGISTER); return;
				default: return;
			}
		}
    document.body.classList.add('modal-open');
		setAuthType(type);
	}, [pathname, navigate, setAuthType]);

	const closeAuth = useCallback(() => {
    document.body.classList.remove('modal-open');
    setAuthType(AuthTypes.CLOSED);
  }, [setAuthType]);

	return {
		isAuthOpen: AuthTypes.CLOSED !== authType,
		authType,
		openAuth,
		closeAuth,
	}
}
