// @flow
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import OptionalLabel from 'components/general/input/ely/optional-label';
import LocationField from 'components/form/field/location-field.js';

type Props = {
	name: string,
	label?: string,
	placeholder?: string,
	value?: string,
	error?: string,
	required?: boolean,
	onBlur:(e: SyntheticEvent<HTMLInputElement>) => void,
	onChange: Object => void,
	renderTarget?: (e: SyntheticEvent<>) => void
}

const ElyLocationAutocomplete = ({
	name,
	label,
	placeholder,
	value,
	error,
	required,
	onBlur,
	onChange,
	renderTarget
}: Props): React$Node => (
	<FormControl error={!!error}>
		<LocationField
			name={name}
			label={<OptionalLabel label={label} required={required} />}
			placeholder={placeholder}
			value={value}
			error={error}
			onBlur={onBlur}
			onChange={onChange}
			renderTarget={renderTarget}
		/>
		{error && <FormHelperText className="u-color-problem" sx={{ margin: 0 }}>{error}</FormHelperText>}
	</FormControl>
);

export default ElyLocationAutocomplete;
