// @flow
import React from 'react';
import { Link } from 'react-router-dom';
import 'scss/components/general/ely-link.scss';

type Props = {
	to: string,
	state?: Object,
	color?: string,
	variant?: string,
	className?: string,
	children?: React$Node,
};

const ElyLink = React.forwardRef<Props, HTMLLinkElement>((props: Props, ref): React$Node => {
	const { color = "primary", variant = "link", className } = props;
	const colorClass = `ely-link--${variant}--${color}`;
	const variantClass = `ely-link--${variant}`;
	const restClasses = className || '';
	return (
		<Link
			ref={ref}
			{...props}
			className={`ely-link ${variantClass} ${colorClass} ${restClasses}`}
		/>
	);
});

export default ElyLink;
