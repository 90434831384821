// @flow
import "scss/components/general/account/settings/settings-card.scss";

type SettingsCardProps = {
	className?: string,
	children: React$Node
};

const SettingsCard = ({ className, children }: SettingsCardProps) => (
	<div className={`ely-settings-card ${className ?? ''}`}>
		{children}
	</div>
);

export default SettingsCard;