// @flow
import { useState, useCallback } from 'react';
import Icon from 'components/general/icon'
import Interest from 'components/general/account/settings/interest';
import ElySettingsTitle from 'components/general/account/settings/ely-settings-title';
import PersonalInterestsSelect from 'components/general/account/settings/personal-interests-select';
import "scss/components/general/account/settings/personal-interests.scss";

type Props = {
    interests: Array<Object>,
    interestOptions: Array<Object>,
    setFieldValue: (string, Object) => void
};

const PersonalInterests = ({ interests = [], interestOptions = [], setFieldValue }: Props): React$Node => {
    const [isOpen, setOpen] = useState(false);

    const onInterestClick = useCallback((option) => {
        const isActive = Number(interests.filter(interest => interest.key === option.key).length) > 0;
        const interestsClone = isActive
            ? interests?.filter(int => int.key !== option.key)
            : [...interests, option];
        setFieldValue("interests", interestsClone);
    }, [interests, setFieldValue]);

    const onInterestsSelectClose = useCallback(() => setOpen(false), []);

    return (
        <div className="profile-settings__interests">
            <ElySettingsTitle title="Your interests" />

            <div className="profile-settings__interests__container">
                {
                    interests.map(interest => (
                        <Interest
                            key={interest.key}
                            code={interest.code}
                            title={interest.title}
                            className="profile-settings__interests__item"
                        />
                    ))
                }

                <button
                    type="button"
                    className="profile-settings__interests__item profile-settings__interests__edit"
                    onClick={() => setOpen(true)}
                >
                    <Icon
                        code="edit"
                    />
                    Edit
                </button>

                <PersonalInterestsSelect
                    values={interests.map(interest => interest.key)}
                    interestOptions={interestOptions}
                    isOpen={isOpen}
                    onClick={onInterestClick}
                    onClose={onInterestsSelectClose}
                />
            </div>
        </div>
    );
};

export default PersonalInterests;
