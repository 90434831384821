// @flow
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { login, logout } from 'auth/auth-actions';

type ReturnProps = {
	doLogin: (string, string) => Promise<any>,
	doLogout: void => Promise<any>,
};

export default function useAuthActions(): ReturnProps {
	const navigate = useNavigate();

	//
	// LOGIN
	//
	const doLogin = useCallback((username, password) => {
		// No need to redirect here since auth-router does it itself
		return login(username, password);
	}, []);

	//
	// LOGOUT
	//
	const doLogout = useCallback(() => {
		// First redirect and then do logout actions
		// Order is important cause logout actions will update auth context
		// and this will trigger redirect to login page, but we want to redirect to home
		// TODO: check if there is a better way to do the redirection to home page
		navigate("/");
		return logout();
	}, [navigate]);

	return {
		doLogin,
		doLogout,
	}
}