// @flow
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { TextField } from '@mui/material';
import { normalizeDuration } from 'utils/form/input-utils';
import OptionalLabel from 'components/general/input/ely/optional-label';

type Props = {
	name: string,
	label?: string,
	value: string,
	required?: boolean,
	disabled?: boolean,
	inputRef?: Object,
	error?: string,
	warning?: string,
	onChange?: SyntheticEvent<HTMLInputElement> => void,
	onBlur: string => void
}

const ElyDuration = ({ name, label, value, required, disabled, inputRef, error, warning, onChange, onBlur }: Props): React$Node => (
	<FormControl error={!!error}>
		<TextField
			label={<OptionalLabel label={label} required={required} />}
			inputRef={inputRef}
			id={name}
			name={name}
			placeholder="1w 2d 4h 34m"
			value={value}
			variant="standard"
			disabled={disabled}
			error={error}
			onChange={onChange}
			onBlur={e => onBlur(normalizeDuration(e.target.value, ''))}
		/>
		{error && <FormHelperText className="u-color-problem">{error}</FormHelperText>}
		{warning && <FormHelperText className="u-color-warning">{warning}</FormHelperText>}
	</FormControl>
);

export default ElyDuration;