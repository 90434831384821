import { getIn } from 'formik';
import { ElyLocationAutocomplete } from 'components/general/input/index';

const FormikLocationAutocomplete = ({field, form, onChange, ...props}) => {
	const error = (getIn(form.touched, field.name) && getIn(form.errors, field.name));
	return (
		<ElyLocationAutocomplete
			{...field}
			{...props}
			error={error}
			onChange={onChange}
			renderTarget={() => {}}
		/>
	);
};

export default FormikLocationAutocomplete;
