// @flow
import { Suspense, lazy } from 'react';
import Roles from 'auth/Roles';
import useI18n from 'hooks/i18n/useI18n';
import { Globals } from 'utils/variables';
import { Routes, Route } from 'react-router-dom';
import { AuthUtils, Permission, useAuth } from 'auth/index';
import LayoutWidth from 'utils/variables/layout-width';
import RequireAuth from 'components/routers/require-auth';
import LayoutMain from 'components/layout/main/layout-main';
import LayoutModal from 'components/layout/modal/layout-modal';
import ProfileWrapper from 'components/public/profile/profile-wrapper';
import ProfilePeopleRouter from 'components/public/profile/people/profile-people-router';
import ProfileEditWrapper from 'components/general/account/settings/profile-edit-wrapper';
import PasswordChangeWrapper from 'components/general/account/settings/password-change-wrapper';
import LinkedAccountsWrapper from 'components/general/account/settings/linked-accounts-wrapper';
import RedirectAuthenticated from 'components/routers/redirect-authenticated';
import GenericNotFound from 'components/public/general/generic-not-found';

const AuthRouter = lazy(() => import('components/auth/auth-router'));
const CmsRouter = lazy(() => import('components/routers/cms-router'));
const ForBusinessPage = lazy(() => import('components/business/for-business-page'));
const LoginPage = lazy(() => import('components/auth/login/login-page'));
const ForgotPasswordPage = lazy(() => import('components/auth/login/forgot-password-page'));
const ChangePasswordPage = lazy(() => import('components/auth/login/change-password-page'));
const RegistrationPage = lazy(() => import('components/auth/register/registration-page'));
const FeedWrapper = lazy(() => import('components/public/feed/feed-wrapper'));
const FeedForTagWrapper = lazy(() => import('components/public/feed/feed-for-tag-wrapper'));
const SearchPage = lazy(() => import('components/public/search/search-page'));
const ShopPage = lazy(() => import('components/public/shop/shop-page'));
const ProfileMenuPage = lazy(() => import('components/public/profile/profile-menu-page'));
const NewsletterConfirmation = lazy(() => import('components/public/newsletter/newsletter-confirmation'));
const NewsletterUnsubscribeWrapper = lazy(() => import('components/public/newsletter/newsletter-unsubscribe-wrapper'));
const PartnerTerms = lazy(() => import('components/public/static/legal/partner-terms'));
const SupplierTerms = lazy(() => import('components/public/static/legal/supplier-terms'));
const UserTerms = lazy(() => import('components/public/static/legal/user-terms'));
const About = lazy(() => import('components/public/static/about'));
const Privacy = lazy(() => import('components/public/static/privacy'));
const LoyaltyHistory = lazy(() => import('components/general/account/loyalty-history'));
const FavouritesWrapper = lazy(() => import('components/public/favourites/favourites-wrapper'));
const PublicSettings = lazy(() => import('components/public/settings/public-settings'));
const BookPageWrapper = lazy(() => import('components/public/buy/book/book-page-wrapper'));
const BookConfirmationPageWrapper = lazy(() => import('components/public/buy/confirmation/book-confirmation-page-wrapper'));
const SupplierViewWrapper = lazy(() => import('components/public/supplier/supplier-view-wrapper'));
const NodePageWrapper = lazy(() => import('components/public/node/page/node-page-wrapper'));
const ShipView = lazy(() => import('components/public/node/page/ship/ship-view'));
const BundlePageWrapper = lazy(() => import('components/public/node/page/bundle/bundle-page-wrapper'));
const ContributeChooser = lazy(() => import('components/public/contribute/contribute-chooser'));
const ContributeFormWrapper = lazy(() => import('components/public/contribute/contribute-form-wrapper'));
const ContributePoisListWrapper = lazy(() => import('components/public/contribute/contribute-pois-list-wrapper'));
const AddExperience = lazy(() => import('components/public/experience/add/add-experience'));
const ExperienceEditWrapper = lazy(() => import('components/public/experience/edit/experience-edit-wrapper'));
const ExperiencePreviewWrapper = lazy(() => import('components/public/experience/preview/preview-experience-wrapper'));
// const PlayWrapperV2 = lazy(() => import('components/user/play-v2/questions-wrapper'));

const ExperlyRouter = (): React$Node => {
	const { t } = useI18n();
	const { permissions } = useAuth();
	return (
		<Suspense fallback={null}>
			<Routes>
				{/***************/}
				{/* AUTH ROUTES */}
				{/***************/}
				<Route path="auth/*" element={<AuthRouter />} />
				<Route path="auth/*" element={<LayoutMain width={LayoutWidth.FULL_WIDTH} hideMobileHeader />}>
					<Route element={<RedirectAuthenticated />}>
						<Route path="login" element={<LoginPage />} />
						<Route path="forgot-password" element={<ForgotPasswordPage />} />
						<Route path="change-password" element={<ChangePasswordPage />} />
						<Route path="register/user" element={<RegistrationPage role={Roles.USER} />} />
					</Route>
				</Route>

				{/****************/}
				{/* FOR BUSINESS */}
				{/****************/}
				<Route path="/for-business" element={<ForBusinessPage />} />

				{/**************/}
				{/* CMS ROUTES */}
				{/**************/}
				<Route path="/cms/*" element={<CmsRouter />} />

				{/***************/}
				{/* REST ROUTES */}
				{/***************/}
				<Route element={<LayoutMain width={LayoutWidth.FULL_WIDTH} />}>
					<Route index element={<FeedWrapper />} />
					<Route path="feed">
						<Route index element={<FeedWrapper />} />
						<Route path="tag/:key" element={<FeedForTagWrapper />} />
					</Route>
					<Route path="/shop" element={<ShopPage />} />
				</Route>

				<Route path="profile">
					<Route path="people/*" element={
						<LayoutMain hideMobileHeader>
							<RequireAuth roles={[Roles.ADMIN, Roles.PARTNER, Roles.SUPPLIER, Roles.USER]}>
								<ProfilePeopleRouter />
							</RequireAuth>
						</LayoutMain>
					} />
					<Route element={<LayoutMain width={LayoutWidth.FULL_WIDTH} hideMobileHeader />}>
						<Route index element={<ProfileMenuPage />} />
						<Route path=":key" element={<ProfileWrapper />} />
						<Route path=":key/:title" element={<ProfileWrapper />} />
						<Route path=":key/:title/:tab" element={<ProfileWrapper />} />
					</Route>
				</Route>

				<Route path="settings/*" element={<RequireAuth roles={[Roles.ADMIN, Roles.PARTNER, Roles.SUPPLIER, Roles.USER]} />}>
					<Route element={<LayoutModal width={LayoutWidth.MAX_WIDTH} title={t('page.settings.title')} metaTitle={t('page.settings.title')} />}>
						<Route index element={<PublicSettings />} />
						<Route path="profile/edit" element={<ProfileEditWrapper />} />
						<Route path="password/change" element={<PasswordChangeWrapper />} />
						<Route path="socials/connect" element={<LinkedAccountsWrapper />} />
					</Route>
				</Route>

				<Route path="legal" element={<LayoutModal width={LayoutWidth.MAX_WIDTH} title="Terms & Conditions" metaTitle="Terms & Conditions" />}>
					<Route path="partner/terms" element={<PartnerTerms />} />
					<Route path="supplier/terms" element={<SupplierTerms />} />
					<Route path="user/terms" element={<UserTerms />} />
				</Route>
				<Route path="about" element={<LayoutModal width={LayoutWidth.MAX_WIDTH} title={t('page.about.title')} metaTitle={t('page.about.title')}><About /></LayoutModal>} />
				<Route path="privacy" element={<LayoutModal width={LayoutWidth.MAX_WIDTH} title={t('page.privacy.title')} metaTitle={t('page.privacy.title')}><Privacy /></LayoutModal>} />
				<Route path="loyalty" element={<LayoutModal width={LayoutWidth.MAX_WIDTH} title={t('page.loyalty.title')} metaTitle={t('page.loyalty.title')}><LoyaltyHistory /></LayoutModal>} />
				<Route path="favourites" element={<LayoutModal width={LayoutWidth.MAX_WIDTH} title={t('page.favourites.title')} metaTitle={t('page.favourites.title')}><FavouritesWrapper /></LayoutModal>} />

				<Route element={<LayoutModal />}>
					<Route path="nodes/:key/*" element={<NodePageWrapper />} />
					<Route path="bundles/:key/*" element={<BundlePageWrapper />} />
				</Route>
				<Route path="nodes/:key/ship/*" element={<LayoutModal width={LayoutWidth.FULL_WIDTH}><ShipView isPublic /></LayoutModal>} />

				<Route path="contribute" element={<LayoutModal />}>
					<Route index element={<ContributeChooser showValidate={AuthUtils.hasPermission(permissions, Permission.POI_MANAGE)} />} />
					<Route path="create" element={<ContributeFormWrapper isCreate />} />
					<Route path="pois/:key" element={<ContributeFormWrapper isEdit />} />
					{
						AuthUtils.hasPermission(permissions, Permission.POI_MANAGE) &&
						<Route path="pois" element={<ContributePoisListWrapper />} />
					}
				</Route>

				<Route path="stories" element={<LayoutModal />}>
					<Route path="add" element={<AddExperience />} />
					<Route path="edit" element={<ExperienceEditWrapper />} />
					<Route path="preview" element={<ExperiencePreviewWrapper />} />
				</Route>

				<Route element={<LayoutMain />}>
					<Route path="/search" element={<SearchPage />} />
					<Route path="suppliers/:key/*" element={<SupplierViewWrapper />} />
					<Route path="newsletter">
						<Route path="confirm/:token" element={<NewsletterConfirmation />} />
						<Route path="unsubscribe/:token" element={<NewsletterUnsubscribeWrapper />} />
					</Route>

					{
						Globals.BookingEnabled &&
						<Route path="book">
							<Route index element={
								<RequireAuth roles={[Roles.ADMIN, Roles.PARTNER, Roles.SUPPLIER, Roles.USER]}>
									<BookPageWrapper />
								</RequireAuth>
							} />

							<Route path="confirmation" element={
								<RequireAuth roles={[Roles.ADMIN, Roles.PARTNER, Roles.SUPPLIER, Roles.USER]}>
									<BookConfirmationPageWrapper />
								</RequireAuth>
							} />
						</Route>
					}

					<Route path="404" element={<GenericNotFound />} />
					<Route path="*" element={<GenericNotFound />} />
				</Route>

				{/* TODO: Hide until further discussion */}
				{/*{ AuthUtils.isLoggedIn() && <ElyRoute path="/play" component={PlayWrapperV2} layout={LayoutDefault} /> }*/}
			</Routes>
		</Suspense>
	);
};

export default ExperlyRouter;
