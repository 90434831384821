// @flow
import type { JwtProps, IdTokenProps, IdTokenAccountProps, MeProps } from 'auth/types';
import { useMemo, useContext, useCallback } from 'react';
import { AuthUtils } from 'auth/index';
import JwtContext from 'auth/JwtContext';
import IdTokenContext from 'auth/IdTokenContext';
import { transformPhoto } from 'utils/transform';
import { getUserImagePlaceholder } from 'utils/transform/transform-media';

export function useAuth(): JwtProps {
	return useContext(JwtContext);
}

export function useIdToken(): IdTokenProps {
	return useContext(IdTokenContext);
}

export function useMe(): ?MeProps {
	const userContext = useContext(IdTokenContext)?.user;

	const updatePhoto = useCallback((filename: string): void => {
		const photo = filename
			? transformPhoto({ filename }, "profile")
			: getUserImagePlaceholder('profile');
		AuthUtils.service.setIdToken(idToken => ({
			...idToken,
			user: { ...idToken.user, photo }
		}));
	}, []);

	const updatePoints = useCallback((points: number): void => {
		AuthUtils.service.setIdToken(idToken => ({
			...idToken,
			user: { ...idToken.user, points }
		}));
	}, []);

	return useMemo(() => {
		if (!userContext) return null;
		return {
			...userContext,
			updatePhoto,
			updatePoints,
		};
	}, [userContext, updatePhoto, updatePoints]);
}

export function useAccount(): ?IdTokenAccountProps {
	return useContext(IdTokenContext)?.account;
}
