// @flow
import type { ApiNodeProps } from 'types/api/node/api-node-props';
import type {
	NodeProps,
	BookableProps,
	OneOfNodesProps,
	NodeTeaserProps,
	StoryTeaserProps,
	AccommodationProps,
	BundleProps,
	CruiseProps,
	ExcursionProps,
	FoodProps,
	OtherProps,
	ShowAndEventProps,
	StoryProps,
	TransferProps,
	VersionableProps
} from 'types/node/node-props';
import { NodeTypeAlias } from 'utils/variables/node-type-vars';
import { transformUser } from 'utils/transform/transform-user';
import { transformTags, transformTagCategories } from 'utils/transform/transform-tag';
import { transformDay, transformRoom, transformShip } from 'utils/transform/transform-node-commons';
import { transformDocs, transformPhotos, transformVideos, getPoiImagePlaceholder, transformDoc, transformPhoto } from 'utils/transform/transform-media';

// ==== //
// NODE //
// ==== //
export function transformNode(node: ApiNodeProps): NodeProps {
	if (!node) return null;

	const { tagPrimary, tagsFeatured, tagsAmenities } = transformTagCategories(node.tags, node.accessibility);
	const convertedPhotos = !!node.photos?.length
		? transformPhotos(node.photos, node.title)
		: [getPoiImagePlaceholder(node.nodeTypeAlias)];
	const convertedVideos = transformVideos(node.videos);

	const result: NodeProps = {
		nodeType: node.nodeType,
		nodeTypeAlias: node.nodeTypeAlias,
		id: node.id,
		key: node.key,
	};

	if (node.status) result.status = node.status;
	if (node.insertedAt) result.insertedAt = node.insertedAt;
	if (node.title) result.title = node.title;
	if (node.description) result.description = node.description;
	if (node.shortDescr) result.shortDescr = node.shortDescr;
	if (node.location) result.location = node.location;
	if (node.tags) result.tags = transformTags(node.tags);
	if (tagPrimary) result.tagPrimary = tagPrimary;
	if (tagsFeatured) result.tagsFeatured = tagsFeatured;
	if (tagsAmenities) result.tagsAmenities = tagsAmenities;
	if (node.docs) result.docs = transformDocs(node.docs);
	if (!!convertedPhotos?.length || !!convertedVideos?.length) result.photos = [...convertedPhotos, ...convertedVideos];
	if (node.accessibility) result.accessibility = node.accessibility;

	return result;
}
export function transformNodes(nodes: Array<ApiNodeProps> = []): Array<OneOfNodesProps> {
	return nodes.map(node => transformNode(node));
}

// ============== //
// BOOKABLE Types //
// ============== //
export function transformBookable(node: ApiNodeProps): BookableProps {
	const result: BookableProps = transformNode(node);

	let pricing = undefined;
	if ( NodeTypeAlias.OTHER === node.nodeTypeAlias ) {
		pricing = {
			cost: undefined,
			priceUnit: node.pricing?.[0]?.priceUnit,
			comments: node.pricing?.[0]?.comments,
		};
		if (node.pricing?.[0]?.eurocents) {
			pricing.cost = node.pricing[0].eurocents / 100;
		}
	}

	result.supplier = node.supplier;
	result.pricing = pricing;
	result.minCost = node.minCost ? node.minCost / 100 : undefined;
	result.advantages = node.advantages;
	result.thirdParties = node.thirdParties;
	result.cancellationMargin = node.cancellationMargin;
	result.cancellationPolicy = node.cancellationPolicy;

	return result;
}

export function transformAccommodation(node: ApiNodeProps): AccommodationProps {
	const result: AccommodationProps = transformBookable(node);
	result.checkIn = node.checkIn;
	result.checkOut = node.checkOut;
	result.rooms = node.rooms?.map(room => transformRoom(room));
	return result;
}

export function transformCruise(node: ApiNodeProps): CruiseProps {
	const result: CruiseProps = transformBookable(node);
	result.knowBeforeYouGo = node.knowBeforeYouGo;
	result.periods = node.periods;
	result.days = node.days?.map(day => transformDay(day));
	result.captainName = node.captainName;
	result.captainBio = node.captainBio;
	result.ship = transformShip(node.ship);
	return result;
}

export function transformExcursion(node: ApiNodeProps): ExcursionProps {
	const result: ExcursionProps = transformBookable(node);
	result.duration= node.duration;
	result.knowBeforeYouGo = node.knowBeforeYouGo;
	return result;
}

export function transformFood(node: ApiNodeProps): FoodProps {
	const result: FoodProps = transformBookable(node);
	result.tables= node.tables;
	result.reservationPhones = node.reservationPhones;
	result.reservationEmail = node.reservationEmail;
	result.openHour = node.openHour;
	result.closeHour = node.closeHour;
	result.menuUrl = node.menuUrl;
	result.menu = transformDoc(node.menu);
	return result;
}

export function transformShowAndEvent(node: ApiNodeProps): ShowAndEventProps {
	const result: ShowAndEventProps = transformBookable(node);
	result.performers= node.performers;
	result.duration = node.duration;
	result.venue = node.venue;
	result.knowBeforeYouGo = node.knowBeforeYouGo;
	result.feeNotes = node.feeNotes;
	return result;
}

export function transformTransfer(node: ApiNodeProps): TransferProps {
	const result: TransferProps = transformBookable(node);
	result.driver = {
		phone: node.driver?.phone,
		email: node.driver?.email,
	};
	result.startingPoint = node.startingPoint;
	result.destinationPoint = node.destinationPoint;
	return result;
}

export function transformOther(node: ApiNodeProps): OtherProps {
	const result: OtherProps = transformBookable(node);
	result.duration = node.duration;
	result.knowBeforeYouGo = node.knowBeforeYouGo;
	return result;
}

export function transformBundle(node: ApiNodeProps): BundleProps {
	let nodes = [];
	if (node.node) nodes = [transformNodeTeaser(node.node)];
	if (node.nodes) nodes = transformNodeTeasers(node.nodes);
	return {
		...transformBookable(node),
		nodes,
	};
}


// ========= //
// POI Types //
// ========= //
export function transformVersionable(node: ApiNodeProps): VersionableProps {
	const result: VersionableProps = transformNode(node);

	result.hasRevision = node.hasRevision;
	result.revision = node.revision;
	result.newRevisions = node.newRevisions;
	result.oldRevisions = node.oldRevisions;
	// OSM related
	result.osmNodeId = node.osmNodeId;
	result.osmType = node.osmType;
	result.osmTags = node.osmTags;

	return result;
}

// =========== //
// STORY Types //
// =========== //
export function transformStory(story: ApiNodeProps): StoryProps {
	const result: StoryProps = transformNode(story);

	result.nodes = transformNodeTeasers(story.nodes);
	result.tags = transformTags(story.tags);
	result.targetGroups = transformTags(story.targetGroups);
	result.feelings = transformTags(story.feelings);
	result.owner = transformUser(story.owner);

	return result;
}
export function transformStories(stories: Array<ApiNodeProps> = []): Array<StoryProps> {
	return stories.map(story => transformStory(story));
}

// ========== //
// All In One //
// ========== //
export function transformOneOfNode(node: ApiNodeProps): OneOfNodesProps {
	if (!node) return null;

	if (NodeTypeAlias.ACCOMMODATION === node.nodeTypeAlias) return transformAccommodation(node);
	if (NodeTypeAlias.CRUISE === node.nodeTypeAlias) return transformCruise(node);
	if (NodeTypeAlias.EXCURSION === node.nodeTypeAlias) return transformExcursion(node);
	if (NodeTypeAlias.FOOD_AND_BEVERAGE === node.nodeTypeAlias) return transformFood(node);
	if (NodeTypeAlias.SHOW_AND_EVENT === node.nodeTypeAlias) return transformShowAndEvent(node);
	if (NodeTypeAlias.TRANSFER === node.nodeTypeAlias) return transformTransfer(node);
	if (NodeTypeAlias.OTHER === node.nodeTypeAlias) return transformOther(node);

	if (NodeTypeAlias.BUNDLE === node.nodeTypeAlias) return transformBundle(node);

	if (
		NodeTypeAlias.POI === node.nodeTypeAlias ||
		NodeTypeAlias.AREA === node.nodeTypeAlias
	) return transformVersionable(node);

	if (NodeTypeAlias.EXPERIENCE === node.nodeTypeAlias) return transformStory(node);
}

export function transformOneOfNodes(nodes: Array<ApiNodeProps> = []): Array<OneOfNodesProps> {
	return nodes
		?.map(node => transformOneOfNode(node));
}

// ======= //
// TEASERS //
// ======= //
export function transformNodeTeaser(node: ApiNodeProps): ?NodeTeaserProps {
	if (!node) return undefined;
	return ({
		path: node.path,
		link: node.link,
		nodeType: node.nodeType,
		nodeTypeAlias: node.nodeTypeAlias,
		key: node.key,
		title: node.title,
		image: !!node.photos?.length ? transformPhoto(node.photos[0]) : getPoiImagePlaceholder(node.nodeTypeAlias),
		location: node.location,
		tag: {
			type: node.nodeTypeAlias?.toLowerCase(),
			title: node.nodeTypeAlias
		},
		// price: node.pricing && Math.min(...node.pricing.map(p => (p.eurocents ?? 0) / 100)),
		pricing: {
			cost: node.pricing?.eurocents ? node.pricing.eurocents / 100 : undefined,
			eurocents: node.pricing?.eurocents,
			ticketType: node.pricing?.ticketType,
			priceType: node.pricing?.priceType,
			priceUnit: node.pricing?.priceUnit,
		},
		...node.supplier ? { supplier: node.supplier } : {},
		newRevisions: transformNodeTeasers(node.newRevisions),
	});
}
export function transformNodeTeasers(nodes: Array<ApiNodeProps>): Array<NodeTeaserProps> {
	return nodes
		?.map(node => transformNodeTeaser(node))
		?.filter(node => !!node);
}

export function transformStoryTeaser(node: ApiNodeProps): StoryProps {
	if (!node) return null;

	let diffFromCreation = undefined;
	if (node.daysFromCreation && node.daysFromCreation === 0) {
		diffFromCreation = "Today";
	}
	else if (node.daysFromCreation && node.daysFromCreation <= 30) {
		diffFromCreation = `${node.daysFromCreation} days ago`;
	}
	else if (node.daysFromCreation && node.daysFromCreation <= 365) {
		diffFromCreation = `${node.daysFromCreation % 30} months ago`;
	}
	else if (node.daysFromCreation && node.daysFromCreation > 365) {
		diffFromCreation = `${node.daysFromCreation % 365} years ago`;
	}

	return {
		path: node.path,
		link: node.link,
		nodeType: node.nodeType,
		nodeTypeAlias: node.nodeTypeAlias,
		key: node.key,
		title: node.title,
		img: !!node.photos?.length ? transformPhoto(node.photos[0]) : getPoiImagePlaceholder(node.nodeTypeAlias),
		tags: transformTags(node.tags),
		owner: transformUser(node.owner),
		nodes: transformNodeTeasers(node.nodes),
		diffFromCreation,
	};
}
export function transformStoryTeasers(story: Array<ApiNodeProps> = []): Array<StoryTeaserProps> {
	return story.map(experience => transformStoryTeaser(experience));
}
