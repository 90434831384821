// @flow
import { useCallback } from 'react';
import { facebookSingIn } from 'auth';
import { Backend, get, post } from 'actions/backend';

type ReturnProps = {
	onFbLogin: string => Promise<any>,
	onTwitterToken: void => Promise<any>,
	onGoogleLogin: void => Promise<any>,
}

export default function useSocialLogin(role: string, location: Object, context: string = "connect"): ReturnProps {

	//
	// Facebook Login
	//
	const onFbLogin = useCallback(fbAccessToken => {
		// No need to redirect here since auth-router does it itself
		return facebookSingIn(role, fbAccessToken);
	}, [role]);

	//
	// Twitter Login
	//
	const onTwitterToken = useCallback(() => {
		return post(Backend.OAUTH_TWITTER_TOKEN, null, {params: { context }})
			.then(res => {
				localStorage['authRole'] = role;
				localStorage['twitterToken'] = res.data.token;
				localStorage['twitterTokenSecret'] = res.data.tokenSecret;
				const authFrom = location?.state?.from;
				if ( authFrom ) {
					localStorage['authFrom'] = JSON.stringify(authFrom);
				}
				window.location.href = res.data.authUrl;
				return res;
			});
	}, [role, context, location]);

	//
	// Google Login
	//
	const onGoogleLogin = useCallback(() => {
		return get(Backend.OAUTH_GOOGLE_URL, { context  })
			.then(res => {
				localStorage['authRole'] = role;
				const authFrom = location?.state?.from;
				if ( authFrom ) {
					localStorage['authFrom'] = JSON.stringify(authFrom);
				}
				window.location.href = res.data
			})
	}, [role, context, location]);

	return { onFbLogin, onTwitterToken, onGoogleLogin };
}