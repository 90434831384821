// @flow
import { useMemo } from 'react';
import AuthUtils from 'auth/AuthUtils';
import { useAuth } from 'auth/hooks';
import { Outlet, Navigate, useLocation } from 'react-router-dom';
import NoLayout from 'components/layout/no-layout';
import LayoutMain from 'components/layout/main/layout-main';
import GenericNotAuthorized from 'components/public/general/generic-not-authorized';

type Props = {
	isCms?: boolean,
	roles?: Array<string>,
	children?: React$Node,
};

const RequireAuth = ({ isCms = false, roles, children }: Props): React$Node => {
	const location = useLocation();
	const { initialized, isAuthenticated, authorities } = useAuth();
	const isPublic = useMemo(() => !roles, [roles]);
	const areRolesValid = useMemo(() => AuthUtils.checkRoles(authorities, roles), [authorities, roles]);

	const NotAuthorizedLayout = isCms ? NoLayout : LayoutMain;

	// Wait for auth initialization before rendering or redirecting
	if ( !isPublic && !initialized ) return null;

	// If public or authenticated with necessary permissions
	if ( isPublic || (isAuthenticated && areRolesValid) ) return (
		<>
			{children ?? <Outlet />}
		</>
	);

	// If authenticated but not valid roles show NotAuthorized component
	if (isAuthenticated && !areRolesValid) return (
    <NotAuthorizedLayout>
      <GenericNotAuthorized />
    </NotAuthorizedLayout>
	);

	// Redirect to login page
	return (
		<Navigate
			to="/auth/login"
			state={{ from: location }}
		/>
	);
};

export default RequireAuth;
