// @flow
import useI18n from 'hooks/i18n/useI18n';
import { Button } from 'components/general/button';
import PublicUserTeaser from 'components/public/user/public-user-teaser';
import "scss/components/public/user/public-users-list.scss";

type PublicUsersListProps = {
	users: Array,
	hasMore: ?boolean,
	onLoadMore: void => void,
	addFriend: string => void,
	removeFriend: (string, string) => void,
	acceptFriend: string => void,
	ignoreFriend: (string, string) => void,
	className?: string
}

const PublicUsersList = ({ users, hasMore, onLoadMore, addFriend, removeFriend, acceptFriend, ignoreFriend, className }: PublicUsersListProps) => {
	const { t } = useI18n();
	return (
		<div className={`public-users-list ${className}`}>
			<div className="public-users-list__content">
				{
					users?.map(user => (
						<PublicUserTeaser
							isAuthenticated={true}
							key={user._key}
							isMe={true}
							{...user}
							addFriend={() => addFriend(user._key)}
							removeFriend={() => removeFriend(user._key, user.name)}
							acceptFriend={() => acceptFriend(user.isFriend.token)}
							ignoreFriend={() => ignoreFriend(user.isFriend.token, user.name)}
						/>
					))
				}
			</div>
			{
				hasMore &&
				<div className="public-users-list__actions">
					<Button onClick={onLoadMore}>{t('profile.tab.friends.more')}</Button>
				</div>
			}
		</div>
	);
}

export default PublicUsersList;