export const SSR_RESPONSE = {
	EMPTY: "",
	PLACEHOLDER: "___DATA___",
	NOT_FOUND: "NOT_FOUND"
}

export class SsrUtils {
	static hasServerData() {
		return (
			window.SERVER_DATA &&
			window.SERVER_DATA !== SSR_RESPONSE.EMPTY &&
			window.SERVER_DATA !== SSR_RESPONSE.PLACEHOLDER
		);
	}

	static getServerData() {
		return window.SERVER_DATA;
	}

	static setServerData(data) {
		window.SERVER_DATA = data;
	}
}