import { getIn } from 'formik';
import ElySelect from 'components/general/input/ely/ely-select';

const FormikSelect = ({ field, form:{setFieldValue, touched, errors}, ...props}) => {
	const error = (getIn(touched, field.name) && getIn(errors, field.name));
	return (
		<ElySelect
			{...field}
			{...props}
			error={error}
			onChange={(e) => {
				if ( typeof props.onChange === 'function'){
					props.onChange(e.target.value);
				}
				const value = props.objectAsValue ? props.options.filter(o => o[props.keyProp] === e.target.value)[0] : e.target.value;
				setFieldValue(field.name, value)
			}}
		/>
	)
};

export default FormikSelect;