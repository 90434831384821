// @flow
import { getClassForAuditEvent } from 'utils/helpers';
import AuditEvent from 'utils/variables/audit-event';
import { ElyLink } from 'components/general/link';
import "scss/components/public/profile/profile-timeline-item.scss";

type ProfileTimelineItemProps = {
	type: string,
	title: string,
	node?: ?Object,
	points?: ?Number,
	children: React$Node,
	className?: string
}

const ProfileTimelineItem = ({ type = "title-type", title, node, points, children, className }: ProfileTimelineItemProps) => (
	<div className={`timeline-item ${className ?? ''}`}>
		<div className={`timeline-item-title icon-${getClassForAuditEvent(type)}`}>
			{title}
			{
				type === AuditEvent.ACCEPT_FRIEND &&
				<ElyLink to={node.path} className="timeline-item-title__user">{node.name}</ElyLink>
			}
		</div>
		{ !!points && points > 0 && <div className="timeline-item-points">{`+${points}`}</div> }
		{children}
	</div>
);

export default ProfileTimelineItem;