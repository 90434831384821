import {
    ACTION_ADD_ERROR, ACTION_HAS_ERRORS, ACTION_IS_LOADING, ACTION_REMOVE_ERROR,
    ACTION_SET_ERRORS
} from 'actions/types';

export function isLoading(state = false, action) {
    switch (action.type) {
        case ACTION_IS_LOADING:
            return action.payload;
        default:
            return state;
    }
}

export function hasErrors(state = false, action) {
    switch (action.type) {
        case ACTION_HAS_ERRORS:
            return action.payload;
        default:
            return state
    }
}

export function errors(state = [], action) {
    switch (action.type) {
        case ACTION_SET_ERRORS:
            return action.payload;
        case ACTION_ADD_ERROR:
            return [...state, action.payload];
        default:
            return state;
    }
}

export function errorCount(state = 0, action) {
    switch (action.type) {
        case ACTION_ADD_ERROR:
            return state + 1;
        case ACTION_REMOVE_ERROR:
            return state - 1;
        default:
            return state;
    }
}