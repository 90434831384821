// @flow
import type { UserProps } from 'types/user-props';
import type { ApiUserProps } from 'types/api/api-user-props';
import { FileUtils } from 'utils/helpers';
import userPlaceholder from 'img/user-placeholder.png';
import { transformPhotos } from 'utils/transform/transform-media';
import { transformLocation } from 'utils/transform/transform-location';

export function transformUser(user: Object): UserProps {
	if (!user) return null;
	const result: UserProps = {};
	if (user.path) result.path = user.path;
	if (user.link) result.link = user.link;
	if (user.id) result.id = user.id;
	if (user.key) result.key = user.key;
	if (user.name) result.name = user.name;
	if (user.email) result.email = user.email;
	if (user.firstName) result.firstName = user.firstName;
	if (user.lastName) result.lastName = user.lastName;
	if (user.displayName) result.displayName = user.displayName;
	if (user.feel) result.feel = user.feel;
	if (user.bio) result.bio = user.bio;
	if (user.phone) result.phone = user.phone;
	if (user.location) result.location = transformLocation(user.location);
	if (user.photos) result.photos = transformPhotos(user.photos);
	if (user.interests) result.interests = user.interests;
	if (user.socialLinks) result.socialLinks = user.socialLinks;
	if (user.isMe) result.isMe = user.isMe;
	if (user.isFriend) result.isFriend = user.isFriend;
	return result;
}

export function transformPublicUser(user: ApiUserProps): UserProps {
	if (!user) return null;
	const result: UserProps = {};
	if (user.key) result.key = user.key;
	if (user.name) result.name = user.name;
	if (user.path) result.path = user.path;
	if (user.link) result.link = user.link;
	if (user.feel) result.feel = user.feel;
	if (user.isFriend) result.isFriend = user.isFriend;
	if (user.isMe) result.isMe = !!user.isMe;
	result.image = FileUtils.getImageUrl(user.photos?.[0]?.filename, FileUtils.SIZE_SMALL) ?? userPlaceholder;
	return result;
}

export function transformPublicUsers(users) {
	if ( !users ) return [];
	return users.map(user => transformPublicUser(user));
}