// @flow
import "scss/components/cms/general/nav/cms-nav.scss";

type CmsNavProps = {
	className?: string,
	children: React$Node
}

const CmsNav = ({ className, children }: CmsNavProps) => (
	<aside className={`ely-aside ${className ?? ''}`}>
		<div className="ely-aside__wrapper">
			<nav className="ely-nav">
				<ul className="ely-nav__list">
					{children}
				</ul>
			</nav>
		</div>
	</aside>
);

export default CmsNav;