// @flow
import HeaderButton from 'components/layout/header-button';
import 'scss/components/layout/header-button-back.scss';

type Props = {
	to: string | Object,
	className?: string,
};

const HeaderButtonBack = ({ to, className }: Props): React$Node => (
	<HeaderButton
		to={to}
		icon="previous"
		ariaLabel="Back"
		className={`HeaderButtonBack ${className}`}
	/>
);

export default HeaderButtonBack;
