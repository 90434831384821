// @flow
import withI18n from 'hoc/with-i18n';
import TeasersList from 'components/public/general/teasers-list';

import "scss/components/public/profile/profile-achievements.scss";

type ProfileAchievementProps = {|
	code: string,
	label: string
|}

type ProfileAchievementsProps = {
	achievements: Array<ProfileAchievementProps>,
	count: number,
	t: string => string
}

const ProfileAchievement = ({ code, label }: ProfileAchievementProps) => (
	<div className="profile-achievements__item">
		<span className={`profile-achievements__item__icon ${code.replace('.', '-')}`}/>
		<span className="profile-achievements__item__name">{label}</span>
	</div>
);

const ProfileAchievements = ({ achievements, count, t }: ProfileAchievementsProps) => (
	<TeasersList
		title={t('profile.achievements.title')}
		count={count}
		moreUrl={{ pathname: "/achievements" }}
		moreLabel={t('profile.viewall.label')}
	>
		{
			achievements.map((achievement, index) => <ProfileAchievement key={index} {...achievement} />)
		}
	</TeasersList>
);

ProfileAchievements.defaultProps = {
	achievements: []
};

export { ProfileAchievement };
export default withI18n(ProfileAchievements);