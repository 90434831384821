// @flow
import { SocialProvider } from 'utils/variables';
import { Button } from 'components/general/button';
import ElySettingsTitle from 'components/general/account/settings/ely-settings-title';
import SettingsCard from 'components/general/account/settings/settings-card';
import SocialButtonFacebook from 'components/auth/social/social-button-facebook';
import SocialButtonTwitter from 'components/auth/social/social-button-twitter';
import SocialButtonGoogle from 'components/auth/social/social-button-google';

import "scss/components/general/account/settings/linked-accounts.scss";

type LinkedAccountsProps = {
	user: Object,
	onFbConnect: Object => Promise<Object>,
	onTwitterConnect: void => Promise<Object>,
	onGoogleConnect: string => void,
	disconnectSocial: string => void
}

const LinkedAccounts = ({ user, onFbConnect, onTwitterConnect, onGoogleConnect, disconnectSocial }: LinkedAccountsProps) => (
	<SettingsCard className="profile-settings__linked-accounts">
		<ElySettingsTitle title="Linked accounts" />
		<div className="profile-settings__linked-accounts__item">
			<div className="profile-settings__linked-accounts__item__inner">
				<span className="profile-settings__linked-accounts__item__logo FACEBOOK">Facebook</span>
				{user.facebookConnected && <span>Connected with Facebook</span>}
			</div>
			{
				!user.facebookConnected &&
				<SocialButtonFacebook
					variant="button"
					className="profile-settings__linked-accounts__item__button"
					onLink={onFbConnect}
					scope={'user_link'}
				>Connect</SocialButtonFacebook>
			}
			{
				user.facebookConnected &&
				<Button
					color="secondary-border"
					className="profile-settings__linked-accounts__item__button"
					onClick={() => disconnectSocial(SocialProvider.FACEBOOK)}
				>Disconnect</Button>
			}
		</div>
		<div className="profile-settings__linked-accounts__item">
			<div className="profile-settings__linked-accounts__item__inner">
				<span className="profile-settings__linked-accounts__item__logo TWITTER">Twitter</span>
				{user.twitterConnected && <span>Connected with Twitter</span>}
			</div>
			{
				!user.twitterConnected &&
				<SocialButtonTwitter
					variant="button"
					onConnect={onTwitterConnect}
					className="profile-settings__linked-accounts__item__button"
				>Connect</SocialButtonTwitter>
			}
			{
				user.twitterConnected &&
				<Button
					color="secondary-border"
					className="profile-settings__linked-accounts__item__button"
					onClick={() => disconnectSocial(SocialProvider.TWITTER)}
				>Disconnect</Button>
			}
		</div>
		<div className="profile-settings__linked-accounts__item">
			<div className="profile-settings__linked-accounts__item__inner">
				<span className="profile-settings__linked-accounts__item__logo GOOGLE">Google</span>
				{user.googleConnected && <span>Connected with Google</span>}
			</div>
			{
				!user.googleConnected &&
				<SocialButtonGoogle
					variant="button"
					onClick={onGoogleConnect}
					className="profile-settings__linked-accounts__item__button"
				>Connect</SocialButtonGoogle>
			}
			{
				user.googleConnected &&
				<Button
					color="secondary-border"
					className="profile-settings__linked-accounts__item__button"
					onClick={() => disconnectSocial(SocialProvider.GOOGLE)}
				>Disconnect</Button>
			}
		</div>
	</SettingsCard>
);

export default LinkedAccounts;