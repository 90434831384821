// @flow
import Status from 'utils/variables/status';
import AuditNodeType from 'utils/variables/audit-node-type';
import ProfileStatus from 'components/public/profile/profile-status';
import NodeTeaser, { NodeTeaserVariant } from 'components/public/node/node-teaser';
import "scss/components/public/profile/profile-timeline-item-content.scss";

type Props = {
	nodeType: string,
	node: Object,
	t: string => string
};

const ProfileTimelineItemContent = ({ nodeType, node, t }: Props): React$Node => (
	<>
		{
			nodeType === AuditNodeType.FEEL &&
			<ProfileStatus
				status={node.status}
				tag={node.tag}
				t={t}
			/>
		}
		{
			nodeType === AuditNodeType.NODE &&
			<NodeTeaser
				nodeTypeAlias={node.nodeTypeAlias}
				image={node.photos?.[0]?.thumbnail}
				title={node.title}
				location={node.location}
				to={Status.PUBLISHED === node.status && `/nodes/${node.key}`}
				target="_blank"
				variant={NodeTeaserVariant.SECONDARY}
				className="timeline-item-content"
			/>
		}
		{
			nodeType === AuditNodeType.REVIEW &&
			<div className="timeline-item-content timeline-item-content__shadow">
				{
					node?.experience &&
					<NodeTeaser
						nodeTypeAlias={node.node.nodeTypeAlias}
						image={node.node.photos?.[0]?.thumbnail}
						title={node.node.title}
						location={node.node.location}
						to={Status.PUBLISHED === node.node.status && `/nodes/${node.node.key}`}
						target="_blank"
						variant={NodeTeaserVariant.SECONDARY}
						className="timeline-item-content__review__node"
					/>
				}
				<p className="timeline-item-content__review__comment">{node?.comment}</p>
			</div>
		}
	</>
);

export default ProfileTimelineItemContent;
