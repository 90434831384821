// @flow
import { useMemo } from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

type ElyPriceProps = {
	name: string,
	label?: string,
	placeholder?: string,
	value: number,
	error?: string,
	warning?: string,
	InputProps?: Object,
	className?: string,
	variant?: 'standard' | 'outlined' | 'filled',
	onChange?:(e: SyntheticEvent<HTMLInputElement>) => void,
	onBlur?:(e: SyntheticEvent<HTMLInputElement>) => void
};

const ElyPrice = ({
	name,
	label,
	placeholder,
	value,
	error,
	warning,
	InputProps = {},
	className,
	variant = 'standard',
	onChange,
	onBlur,
}: ElyPriceProps): React$Node => {

	const helperTextClass = useMemo(() => {
		if ( error ) return 'u-color-error';
		else if ( warning ) return 'u-color-warning';
		return '';
	}, [error, warning]);

	return (
		<TextField
			type="number"
			id={name}
			name={name}
			label={label}
			placeholder={placeholder}
			value={value}
			error={!!error}
			onChange={onChange}
			onBlur={onBlur}
			InputProps={{
				// inputComponent: NumberFormatCustom,
				startAdornment: <InputAdornment position="start">&euro;</InputAdornment>,
				...InputProps,
			}}
			variant={variant}
			className={className}
			helperText={error ?? warning ?? ''}
			FormHelperTextProps={{
				classes: {
					root: helperTextClass
				}
			}}
		/>
	);
};

export default ElyPrice;
