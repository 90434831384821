import { useMemo, useState, useEffect } from 'react';
import { ScreenSize } from 'utils/variables/screen';
import sassVariables from 'scss/global/base/variables.module.scss';

const useCheckScreen = () => {
	const [width, setWidth] = useState(window.innerWidth);

	const handleWindowSizeChange = () => {
		setWidth(window.innerWidth);
	}

	const screen = useMemo(() => {
		if (width >= sassVariables.breakXlarge) return ScreenSize.XXLARGE;
		if (width >= sassVariables.breakLarge) return ScreenSize.XLARGE;
		if (width >= sassVariables.breakMedium) return ScreenSize.LARGE;
		if (width >= sassVariables.breakSmall) return ScreenSize.MEDIUM;
		return ScreenSize.SMALL;
	}, [width]);

	useEffect(() => {
		window.addEventListener('resize', handleWindowSizeChange);
		return () => {
			window.removeEventListener('resize', handleWindowSizeChange);
		}
	}, []);

	return {
		screen,
		isSmall: ScreenSize.SMALL === screen,
		isMedium: ScreenSize.MEDIUM === screen,
		isLarge: ScreenSize.LARGE === screen,
		isXlarge: ScreenSize.XLARGE === screen,
		isXXlarge: ScreenSize.XXLARGE === screen,
	};
}

export default useCheckScreen;
