// @flow
import type { AccessibilityProps } from 'types/general/commons';
import type { ApiTagGroupProps, ApiTagProps } from 'types/api/general/api-tag-props';
import type { PrimaryTagProps, TagGroupProps, TagProps } from 'types/general/tag-props';
import TagGroupType from 'utils/variables/tag-group-vars';

export function transformTag(tag: ApiTagProps, tagGroup?: TagGroupProps): TagProps {
	const tagGroupLocal = tag.tagGroup ?? tagGroup ?? undefined;
	const transformed: TagProps = {};
	if (tag.id) transformed.id = tag.id;
	if (tag.key) transformed.key = tag.key;
	if (tag.title) transformed.title = tag.title;
	if (tag.isFollowing) transformed.isFollowing = tag.isFollowing;
	if (tag.followers) transformed.followers = tag.followers;
	if (tag.code || tag.title) transformed.code = tag.code ?? tag.title.toLowerCase().replace(/ /gi, "_");
	if (tagGroupLocal) transformed.tagGroup = transformTagGroup(tagGroupLocal);
	return transformed;
}
export function transformTags(tags: ?Array<ApiTagProps>, tagGroup?: TagGroupProps): Array<TagProps> {
	return (tags ?? []).map(tag => transformTag(tag, tagGroup));
}

export function transformTagGroup(tagGroup: ApiTagGroupProps): TagGroupProps {
	const transformed: TagGroupProps = {}
	if (tagGroup.key) transformed.key = tagGroup.key;
	if (tagGroup.title) transformed.title = tagGroup.title;
	if (tagGroup.code) transformed.code = tagGroup.code;
	if (tagGroup.descr) transformed.descr = tagGroup.descr;
	if (tagGroup.order) transformed.order = tagGroup.order;
	if (tagGroup.type) transformed.type = tagGroup.type;
	if (tagGroup.isSingle) transformed.isSingle = tagGroup.isSingle;
	if (tagGroup.isBoolean) transformed.isBoolean = tagGroup.isBoolean;
	if (tagGroup.nodeTypes) transformed.nodeTypes = tagGroup.nodeTypes;
	// When transforming the tags we need to keep track to which tag group they belong,
	// so we need to pass the tag group to transformTags
	if (tagGroup.tags) transformed.tags = transformTags(tagGroup.tags, { key: tagGroup.key, code: tagGroup.code });
	return transformed;
}
export function transformTagGroups(tagGroups: Array<ApiTagGroupProps>): Array<TagGroupProps> {
	return (tagGroups ?? []).map(tagGroup => transformTagGroup(tagGroup));
}

type TransformTagsProps = {
	tagPrimary: PrimaryTagProps,
	tagsFeatured?: ?Array<TagProps>,
	tagsAmenities?: ?Array<TagProps>,
};
export function transformTagCategories(tags: Array<ApiTagProps> = [], accessibility: AccessibilityProps): TransformTagsProps {

	const tagPrimary = tags
		.filter(tag => tag.tagGroup?.type === TagGroupType.PRIMARY)
		.map(tag => ({ type: tag.tagGroup.code, title: tag.title }));

	const tagsFeatured = tags
		.filter(tag => tag.tagGroup?.type === TagGroupType.FEATURES)
		.map(tag => ({ title: tag.title, code: tag.code?.replace('tag.', '') }));

	const tagsAmenities = tags
		.filter(tag => !tag.tagGroup?.type === TagGroupType.AMENITIES)
		.filter(tag => tag.tagGroup?.code !== 'tag.group.ship')
		.map(tag => ({ title: tag.title, code: tag.code?.replace('tag.', '') }));

	if ( accessibility?.isAccessible ) {
		tagsFeatured.push({ code: 'accessible', title: 'Accessible' });
		tagsAmenities.push({ code: 'accessible', title: 'Accessible' });
	}

	return {
		tagPrimary: tagPrimary?.[0],
		tagsFeatured,
		tagsAmenities,
	};
}
