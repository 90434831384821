// @flow
import { Link } from 'react-router-dom';

import "scss/components/public/general/teasers-list.scss";

type TeasersListProps = {
	title: string,
	count?: number,
	moreUrl: string | Object,
	moreLabel?: ?string,
	children: React$Node
};

const TeasersList = ({ title, count, moreUrl, moreLabel, children }: TeasersListProps) => (
	<div className="teasers-list">
		<h2 className="teasers-list__title">{title} <span className="teasers-list__count">{count}</span></h2>
		<div className="teasers-list__container">
			{children}
		</div>
		{
			moreLabel &&
			<Link className="view-all icon-next" to={moreUrl}>{moreLabel}</Link>
		}
	</div>
);

export default TeasersList;