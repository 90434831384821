// @flow
import { Link } from 'react-router-dom';
import Icon from 'components/general/icon';

type Props = {
	icon: string,
	title: string,
	href: string | Object,
};

const ProfileSettingsItem = ({ icon, title, href }: Props): React$Node => (
	<li className="ProfileSettingsItem">
		<Link to={href} className="ProfileSettingsItem__link">
			<Icon code={icon} />
			<span className="ProfileSettingsItem__title">{title}</span>
		</Link>
	</li>
);

export default ProfileSettingsItem;
