import { SET_ME, RESET_ME } from 'actions/types';

const MeReducer = (state = {}, action) => {
	switch(action.type) {
		case SET_ME:
			return {
				postId: action.payload.postId,
				id: action.payload.id,
				key: action.payload.key,
				name: action.payload.name,
				email: action.payload.email,
				photos: action.payload.photos,
				path: action.payload.path,
				link: action.payload.link
			};
		case RESET_ME:
			return {};
		default: return state;
	}
}

export default MeReducer;