// @flow
import type { LocationProps } from 'types/general/location-props';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import withI18n from 'hoc/with-i18n';
import Emote from 'components/general/emote';
import { Button } from 'components/general/button';
import { ButtonLink } from 'components/general/link';
import Interest from 'components/general/account/settings/interest';
import ProfileStatus from 'components/public/profile/profile-status';
import ProfileFeeling from 'components/public/profile/profile-feeling';
import 'scss/components/public/profile/profile-user.scss';

type ProfileUserProps = {
	isLoggedIn: boolean,
	isMe: boolean,
	isFriend: Object,
	image: string,
	name: string,
	location: LocationProps,
	points: number,
	feel: Object,
	contributions: {
		activities?: ?number,
		experiences?: ?number,
		reviews?: ?number,
		points?: ?number
	},
	bio: ?string,
	socials: Array<Object>,
	interests: Array<Object>,
	feelings: ?Array<Object>,
	onStatusSubmit: Object => void,
	addFriend: (string, string) => void,
	removeFriend: (string, string) => void,
	acceptFriend: (string, string, string) => void,
	ignoreFriend: (string, string, string) => void,
	t: string => string
}

const ProfileUser = ({
	isLoggedIn,
	isMe,
	isFriend,
	image,
	name,
	location,
	points,
	feel,
	contributions,
	bio,
	socials,
	interests,
	feelings,
	onStatusSubmit,
	addFriend,
	removeFriend,
	acceptFriend,
	ignoreFriend,
	t,
}: ProfileUserProps) => {
	const [editingStatus, setEditingStatus] = useState<boolean>(false);
	return (
		<div className="profile-user">
			<div className="profile-user__info">
				<div className="profile-user__image">
					<img src={image} alt={t('profile.user.image.alt')} />
					<Emote
						code={feel?.tag?.title?.toLowerCase()}
						title={feel?.tag?.title}
						className="profile-user__emote"
					/>
				</div>
				<div className="profile-user__basic">
					<h1 className="profile-user__basic__name">{name}</h1>
					{
						location?.city && location?.country &&
						<span className="profile-user__basic__location">{`${location.city}, ${location.country}`}</span>
					}
					<span className="profile-user__basic__points">{points ?? 0}</span>
					{
						isLoggedIn && isMe &&
						<Link to="/settings" className="profile-user__basic__edit icon-edit">{t('profile.user.edit.link')}</Link>
					}
					{
						feel &&
						<ProfileStatus
							status={feel?.status}
							tag={feel?.tag}
							location={feel?.location}
							onEdit={() => setEditingStatus(state => !state)}
						/>
					}
					{
						isLoggedIn && !isMe && !isFriend &&
						<Button styling={["small", "round"]} onClick={addFriend}>{t('profile.user.add.friend')}</Button>
					}
					{
						isLoggedIn && !isMe && isFriend &&
						<>
							{
								isFriend.active &&
								<Button
									color="hollow"
									styling={["small", "round"]}
									onClick={removeFriend}
								>
									{t('profile.user.remove.friend')}
								</Button>
							}
							{
								!isFriend.active && isFriend.isOutbound &&
								<Button
									color="hollow"
									styling={["small", "round"]}
									onClick={removeFriend}
								>
									{t('profile.user.cancel.friend')}
								</Button>
							}
							{
								!isFriend.active && !isFriend.isOutbound &&
								<>
									<Button
										color="hollow"
										styling={["small", "round"]}
										className="accept"
										onClick={acceptFriend}
									>
										{t('profile.user.accept.friend')}
									</Button>
									<Button
										color="hollow"
										styling={["small", "round"]}
										onClick={ignoreFriend}
									>
										{t('profile.user.ignore.friend')}
									</Button>
								</>
							}
						</>
					}
					{
						!isLoggedIn &&
						<ButtonLink to="/auth/login" className="login-to-connect">{t('profile.user.login.to.connect')}</ButtonLink>
					}
				</div>
			</div>
			{
				isMe && editingStatus &&
				<ProfileFeeling
					feelings={feelings}
					onSubmit={values => {
						setEditingStatus(false);
						onStatusSubmit(values);
					}}
					className="profile-user__status-update--mobile"
				/>
			}
			{
				contributions &&
				<div className="profile-user__contributions">
					<div className="profile-user__contributions__activities">
						<span className="profile-user__contributions__count">{contributions.activities}</span>
						<span>{t('profile.user.contributions.activities')}</span>
					</div>
					<div className="profile-user__contributions__experiences">
						<span className="profile-user__contributions__count">{contributions.experiences}</span>
						<span>{t('profile.user.contributions.experiences')}</span>
					</div>
					<div className="profile-user__contributions__reviews">
						<span className="profile-user__contributions__count">{contributions.reviews}</span>
						<span>{t('profile.user.contributions.reviews')}</span>
					</div>
					<div className="profile-user__contributions__points">
						<span className="profile-user__contributions__count">{contributions.points}</span>
						<span>{t('profile.user.contributions.points')}</span>
					</div>
				</div>
			}
			{
				bio &&
				<div className="profile-user__bio">
					<h3 className="profile-user__bio__title">{t('profile.user.bio.title')}</h3>
					<p className="profile-user__bio_text">{bio}</p>
				</div>
			}
			{
				socials?.length > 0 &&
				<div className="profile-user__social">
					{
						socials.map(social => (
							<a
								key={social.thirdParty}
								href={social.path}
								target="_blank"
								rel="noopener noreferrer"
								className={`icon-${social.thirdParty.toLowerCase()}`}
							>
								{social.thirdParty}
							</a>
						))
					}
				</div>
			}
			{
				interests?.length > 0 &&
				<div className="profile-user__interests">
					<h3 className="profile-user__interests__title">{t('profile.user.interests.title')}</h3>
					<div className="profile-user__interests__list">
						{
							interests.map(interest => (
								<Interest
									key={interest.key}
									code={interest.code}
									title={interest.label}
									className="profile-user__interests__item"
								/>
							))
						}
					</div>
				</div>
			}
		</div>
	);
}

ProfileUser.defaultProps = {
	socials: [],
	interests: []
};

export default withI18n(ProfileUser);