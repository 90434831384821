export const SCOPE_TOKEN_IDTOKEN = 'token idtoken';
export const GRANT_TYPE_PASSWORD = 'password';
export const GRANT_TYPE_REFRESH_TOKEN = 'refresh_token';

// AUTH URLS
export const OAUTH_URL = `/oauth`;
export const LOGIN_URL = `${OAUTH_URL}/token`;

export const API_URL = `/api`;
export const CMS_URL = '/api/cms';
export const IMRE_URL = `${API_URL}/imre`;
export const IMCAP_URL = `${API_URL}/imcap`;
export const NLP_URL = `${API_URL}/nlp`;
