import { createContext } from 'react';

export const locales = {
	en: 'en',
	el: 'el'
};

const I18nContext = createContext({
	locale: locales.en, // default value
	t: () => {},
	setLocale: () => {}
});

export const I18nProvider = I18nContext.Provider;
export const I18nConsumer = I18nContext.Consumer;
export default I18nContext;