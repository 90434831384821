export function getMuiSelectOptions(min, max) {
    const options = [];
    for ( let i=min; i<=max; i++ ) {
        options.push({value: i})
    }
    return options;
}

export function getHours(anyTime) {
    const hours = anyTime ? [{code: 'any-time', descr: 'Any Time'}] : [];
    for ( let i=0; i<=23; i++ ) {
        const time = i < 10 ? `0${i}:00` : `${i}:00`;
        hours.push({code: time, descr: time});
    }
    return hours;
}

export function normalizePhone(value, prevValue) {
    if (!value) return value;
    const onlyNums = value.replace(/[^\d]/g, '');
    if ( onlyNums.length > 10 ) {
        return prevValue;
    }
    return onlyNums;
}

export function normalizeDuration(value, prevValue) {
	if ( !value || value === '' ) return prevValue;
	const seconds = parseDuration(value);
	return toDurationString(seconds);
}

function parseDuration(duration) {
    const wrx = new RegExp(/([0-9][0-9]?)[ ]?w/);
    const drx = new RegExp(/([0-9])[ ]?d/);
    const hrx = new RegExp(/([0-9][0-9]?)[ ]?h/);
    const mrx = new RegExp(/([0-9][0-9]?)[ ]?m/);


    const weeks = wrx.test(duration) ? wrx.exec(duration)[1] : 0;
    const days = drx.test(duration) ? drx.exec(duration)[1] : 0;
    const hours = hrx.test(duration) ? hrx.exec(duration)[1] : 0;
    const minutes = mrx.test(duration) ? mrx.exec(duration)[1] : 0;

    return toSeconds(weeks, days, hours, minutes);
}

function toDurationString(duration) {
	if (duration <= 0) return '';
	const w = Math.floor(duration/604800);
	const d = Math.floor((duration/86400)%7);
	const h = Math.floor((duration/3600)%24);
	const m = Math.floor((duration/60)%60);
	let result = '';
	if (w > 0) result = result + w + 'w ';
	if (d > 0) result = result + d + 'd ';
	if (h > 0) result = result + h + 'h ';
	if (m > 0) result = result + m + 'm ';
	return result.substring(0, result.length - 1);
}

function toSeconds(ww,dd,hh,mm) {
    let w = parseInt(ww);
	let d = parseInt(dd);
	let h = parseInt(hh);
	let m = parseInt(mm);
	if (isNaN(w)) w = 0;
	if (isNaN(d)) d = 0;
	if (isNaN(h)) h = 0;
	if (isNaN(m)) m = 0;

	return (
	    w * 7 * 24 * 60 * 60 +
        d * 24 * 60 * 60 +
        h * 60 * 60 +
		m * 60
    );
}