import { NodeType } from 'utils/variables';

export default class UrlUtils {
    static sanitizeText = str => str.trim().replace(/[^0-9a-zA-Z$\-_.+!*'(),]/g, " ").replace(/[\s_]+/g, "-").toLowerCase();
    static getNodeUrl = (type, key) => {
        if ( NodeType.SERVICE === type ) return `/nodes/${key}`;
        if ( NodeType.POI === type ) return `/nodes/${key}`;
        if ( NodeType.OSM_POI === type ) return `/nodes/${key}`;
        if ( NodeType.BUNDLE === type ) return `/bundles/${key}`;
    };
    static getNodePreviewUrl = (type, key) => {
        if ( NodeType.SERVICE === type ) return `/cms/services/${key}/preview`;
        if ( NodeType.BUNDLE === type ) return `/cms/bundles/${key}/preview`;
    };
}