// @flow
import "scss/components/general/account/settings/ely-settings-title.scss";

type SettingsTitleProps = {
	title: string
}

const ElySettingsTitle = ({ title }: SettingsTitleProps) => (
	<h3 className="ely-settings-title">{title}</h3>
);

export default ElySettingsTitle;