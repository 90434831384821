import { useCallback } from 'react';
import { Backend, post } from 'actions/backend';

function loadImage(src) {
	return new Promise((resolve, reject) => {
		const img = new Image();
		img.addEventListener("load", () => resolve(img));
		img.addEventListener("error", err => reject(err));
		img.src = src;
	});
}

function getPixels(img) {
	const canvas = document.createElement("CANVAS");
	canvas.width = "224";
	canvas.height = "224";

	const ctx = canvas.getContext("2d");
	const cw = canvas.width;
	const ch = canvas.height;
	const maxW = 224;
	const maxH = 224;

	const iw = img.width;
	const ih = img.height;
	const scale = Math.min((maxW / iw), (maxH / ih));
	const iwScaled = iw * scale;
	const ihScaled = ih * scale;

	const px = (maxW-iwScaled)/2;
	const py = (maxH-ihScaled)/2;
	const width = iwScaled;
	const height = ihScaled;

	ctx.clearRect(0, 0, cw, ch);
	ctx.fillStyle = "#000000";
	ctx.drawImage(img,px,py,width,height);

	const imgData = ctx.getImageData(0, 0, maxW, maxH).data;
	const pixels = [];
	for(let h=0;h<maxH;h++){
		const row = [];
		for(let w=0;w<maxW;w++){
			const pixel = ((h * maxW) + w) * 4;
			row.push( [ imgData[pixel], imgData[pixel+1], imgData[pixel+2] ] );
		}
		pixels.push(row)
	}

	return pixels;
}

export default function useImageCaptions() {
	return useCallback((image, config = {}) => {
		const { showLoading = true, showErrors = true } = config;
		return loadImage(URL.createObjectURL(image))
			.then(img => getPixels(img))
			.then(pixels => post(
					Backend.IMCAP_CAPTIONS,
					{ instances: pixels },
					{ showLoading, showErrors }
				))
			.then(res => res.data);
	}, []);
}