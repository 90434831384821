// @flow
import withI18n from 'hoc/with-i18n';
import { Link } from 'react-router-dom';
import TeasersList from 'components/public/general/teasers-list';

import "scss/components/public/profile/profile-friends.scss";

type ProfileFriendProps = {|
	image: string,
	name: string,
	path: string
|}

type ProfileFriendsProps = {
	friends: Array<ProfileFriendProps>,
	count: number,
	allPath: string,
	t: string => string
}

const ProfileFriend = ({ image, name, path }: ProfileFriendProps) => (
	<Link className="profile-friends__item" to={encodeURI(path ?? '/404')}>
		<img src={image} alt={name} className="profile-friends__item__image" />
		<span className="profile-friends__item__name">{name}</span>
	</Link>
);

const ProfileFriends = ({ friends, count, allPath, t }: ProfileFriendsProps) => (
	<TeasersList
		title={t('profile.friends.title')}
		count={count}
		moreUrl={{ pathname: allPath }}
		moreLabel={t('profile.viewall.label')}
	>
		<div className="profile-friends">
			{
				friends.map(friend => <ProfileFriend key={friend._key} {...friend} />)
			}
		</div>
	</TeasersList>
);

ProfileFriends.defaultProps = {
	friends: []
};

export { ProfileFriend };
export default withI18n(ProfileFriends);