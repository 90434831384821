// @flow
import { Link } from 'react-router-dom';
import Icon from 'components/general/icon';
import 'scss/components/layout/header-button.scss';

type Props = {
	icon: string,
	to?: string | Object,
	onClick?: SyntheticEvent<HTMLButtonElement> => void,
	ariaLabel?: string,
	size?: 'standard' | 'small',
	style?: Object,
	className?: string,
};

function getContainer(to) {
	if (to) return Link;
	return ({ children, ...props }) => (
		<button type="button" {...props}>{children}</button>
	);
}

const HeaderButton = ({ icon, to, onClick, ariaLabel, size = 'standard', style, className }: Props): React$Node => {
	const Container = getContainer(to);
	const sizeClass = `HeaderButton--${size}`;
	return (
		<Container
			to={to}
			onClick={onClick}
			aria-label={ariaLabel}
			style={style}
			className={`HeaderButton ${sizeClass} ${className}`}
		>
			<Icon code={icon} className="icon" />
		</Container>
	);
};

export default HeaderButton;
