// @flow
import {useCallback, useState} from 'react';
import { routes } from 'utils/routes';
import { Link, NavLink, matchPath, useLocation } from 'react-router-dom';
import Icon from 'components/general/icon';
import userPlaceholder from 'img/user-placeholder.png';
import useClickOutside from 'hooks/general/useClickOutside';
import LayoutMainProfileMenu from 'components/layout/main/layout-main-profile-menu';
import 'scss/components/layout/main/layout-main-menu.scss';

type Props = {
	profileLink: string,
	profileImage?: string,
	isDesktop?: boolean,
	isMobile?: boolean,
};

const LayoutMainMenu = ({ profileLink, profileImage, isDesktop, isMobile }: Props): React$Node => {
	const desktopClass = isDesktop ? 'LayoutMainMenu--desktop' : '';
	const mobileClass = isMobile ? 'LayoutMainMenu--mobile' : '';

	const { pathname } = useLocation();
	const profileActiveClass = matchPath( {
		path: routes.PROFILE,
		caseSensitive: false,
		end: false,
	}, pathname) ? 'active' : '';

	const [isProfileOpen, setProfileOpen] = useState(false);

	const { elementRef, addListener, removeListener } = useClickOutside(() => setProfileOpen(false));
	const onProfileClick = useCallback(() => {
		setProfileOpen(state => !state);
		if ( !isProfileOpen ) addListener();
		if ( isProfileOpen ) removeListener();
	}, [isProfileOpen, addListener, removeListener]);

	return (
		<div className={`LayoutMainMenu ${desktopClass} ${mobileClass}`}>
			<NavLink to={routes.HOME} className="menu-link menu-link--logo" end>
				<Icon code="logo" className="menu-icon logo" />
			</NavLink>
			<NavLink to={routes.SEARCH} className="menu-link menu-link--search">
				<Icon code="search" className="menu-icon search" />
			</NavLink>
			<div className="add-outer">
				<Link to={routes.STORIES_ADD} state={{ from: pathname }} className="add-link">
					<Icon code="plus" className="add" />
				</Link>
			</div>
			<NavLink to={routes.SHOP} className="menu-link menu-link--shop">
				<Icon code="shop" className="menu-icon shop" />
			</NavLink>
			{
				isDesktop && <div ref={elementRef} className="LayoutMainMenu__profile">
					<button type="button" onClick={onProfileClick} className={`menu-link menu-link--profile ${profileActiveClass}`}>
						<img
							alt="profile"
							src={profileImage ?? userPlaceholder}
							className="user"
						/>
					</button>
					<LayoutMainProfileMenu
						isOpen={isProfileOpen}
						onClose={() => setProfileOpen(false)}
						className="profile-menu"
					/>
				</div>
			}
			{
				isMobile && (
					<NavLink to={profileLink} className="menu-link menu-link--profile">
						<img
							alt="profile"
							src={profileImage ?? userPlaceholder}
							className="user"
						/>
					</NavLink>
				)
			}
		</div>
	);
}

export default LayoutMainMenu;
