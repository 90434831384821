// @flow
import type { NodeTypeAliasProps } from 'types/general/commons';

export const NodeType = {
	SERVICE: 'SERVICE',
	POI: 'POI',
	AREA: 'AREA',
	EXPERIENCE: 'EXPERIENCE',
	BUNDLE: 'BUNDLE',
	TAG: 'TAG',
	USER: 'USER',
};

export const NodeTypeAlias = {

	// Service Types
	SERVICE: 'SERVICE',
	ACCOMMODATION: 'ACCOMMODATION',
	FOOD_AND_BEVERAGE: 'FOOD_AND_BEVERAGE',
	SHOW_AND_EVENT: 'SHOW_AND_EVENT',
	EXCURSION: 'EXCURSION',
	TRANSFER: 'TRANSFER',
	CRUISE: 'CRUISE',
	OTHER: 'OTHER',

	// Poi Types
	POI: 'POI',
	AREA: 'AREA',

	// Experience type
	EXPERIENCE: 'EXPERIENCE',

	BUNDLE: 'BUNDLE',

	TAG: 'TAG',
	USER: 'USER',
};

export const ServiceTypeArray = [
	NodeTypeAlias.ACCOMMODATION,
	NodeTypeAlias.FOOD_AND_BEVERAGE,
	NodeTypeAlias.SHOW_AND_EVENT,
	NodeTypeAlias.EXCURSION,
	NodeTypeAlias.TRANSFER,
	NodeTypeAlias.CRUISE,
	NodeTypeAlias.OTHER,
];

export const NodeTypeAliasArray = [
	NodeTypeAlias.ACCOMMODATION,
	NodeTypeAlias.FOOD_AND_BEVERAGE,
	NodeTypeAlias.SHOW_AND_EVENT,
	NodeTypeAlias.EXCURSION,
	NodeTypeAlias.TRANSFER,
	NodeTypeAlias.CRUISE,
	NodeTypeAlias.OTHER,
	NodeTypeAlias.POI,
	NodeTypeAlias.AREA,
	NodeTypeAlias.EXPERIENCE,
	NodeTypeAlias.BUNDLE,
];

type NodeTypeOptionProps = {
	label: NodeTypeAliasProps,
	value: NodeTypeAliasProps,
};
export const NodeTypeAliasOptions: Array<NodeTypeOptionProps> = NodeTypeAliasArray.map(type => ({ label: type, value: type }));

export default NodeType;
