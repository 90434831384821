// @flow
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import OptionalLabel from 'components/general/input/ely/optional-label';

type ElySelectProps = {
	name:string,
	label?:string,
	options:Array<Object>,
	value?:any,
	error?:string,
	warning?:string,
	keyProp?:string,
	descrProp?:string,
	required?:boolean,
	objectAsValue?:boolean,
	disabled?:boolean,
	inputProps?:Object,
	variant?: 'standard' | 'outlined' | 'filled',
	open?: boolean,
	onBlur?:(e: SyntheticEvent<HTMLSelectElement>) => void,
	onChange?:(e: SyntheticEvent<HTMLSelectElement>) => void,
	onOpen?:(e: SyntheticEvent<HTMLSelectElement>) => void,
	onClose?:(e: SyntheticEvent<HTMLSelectElement>) => void,
	style?:Object,
	disableUnderline?: boolean,
	className?: string,
	classes?: {
		control?: Object,
		select?: Object,
		option?: Object,
	},
}

const ElySelect = ({
	name,
	label,
	options,
	value,
	error,
	warning,
	required,
	open,
	onBlur,
	onChange,
	onOpen,
	onClose,
	objectAsValue,
	disabled,
	inputProps,
	variant,
	keyProp,
	descrProp,
	style,
	disableUnderline,
	className,
	classes = {},
}: ElySelectProps): React$Node => (
	<FormControl error={!!error} disabled={disabled} variant={variant} className={className} classes={classes?.control}>
		{ !!label && <InputLabel htmlFor={name}><OptionalLabel label={label} required={required} /></InputLabel> }
		<Select
			inputProps={{id:name, name, ...inputProps}}
			value={objectAsValue ? (value && value[keyProp] ? value[keyProp]: '' ) : (value || '')}
			open={open}
			onBlur={onBlur}
			onChange={onChange}
			onOpen={onOpen}
			onClose={onClose}
			style={style}
			disableUnderline={disableUnderline}
			classes={classes?.select}
		>
			{
				options.map(o => <MenuItem key={o[keyProp]} value={o[keyProp]} classes={classes?.option}>{o[descrProp]}</MenuItem>)
			}
		</Select>
		{error && <FormHelperText className="u-color-problem">{error}</FormHelperText>}
		{warning && <FormHelperText className="u-color-warning">{warning}</FormHelperText>}
	</FormControl>
);

const emptyObject: Object = {};
ElySelect.defaultProps = {
	objectAsValue: false,
	disabled: false,
	variant: 'standard',
	inputProps: emptyObject,
	keyProp: 'value',
	descrProp: 'label',
	className: '',
}

export default ElySelect;