// @flow
import useI18n from 'hooks/i18n/useI18n';
import Icon from 'components/general/icon';
import { Button } from 'components/general/button';
import { ButtonLink } from 'components/general/link';
import ProfileSearch from 'components/public/profile/profile-search';
import PublicUserTeaser, { type PublicUserTeaserProps } from 'components/public/user/public-user-teaser';
import "scss/components/public/profile/profile-tab-friends.scss";

type ProfileTabFriendsProps = {
	total: number,
	friends: Array<PublicUserTeaserProps>,
	hasMore: boolean,
	onLoadMore: void => void,
	onSearchFriends: Object => void,
	addFriend: (string, string) => void,
	removeFriend: (string, string, string) => void,
	acceptFriend: (string, string, string) => void,
	ignoreFriend: (string, string, string, string) => void
}

const ProfileTabFriends = ({
       total, friends, hasMore, onLoadMore, onSearchFriends,
       addFriend, removeFriend, acceptFriend, ignoreFriend
}: ProfileTabFriendsProps) => {
	const { t } = useI18n();
	return (
		<div className="profile-tab-friends">
			<ProfileSearch
				onSubmit={onSearchFriends}
				placeholder={t('profile.tab.friends.search.placeholder')}
				submitLabel={t('profile.tab.friends.search.submit')}
				className="profile-tab-friends__search"
			/>
			<div className="profile-tab-friends__header">
				<h3 className="friends-title">
					{t('profile.tab.friends.connected')}
					<span className="friends-count">{`(${total})`}</span>
				</h3>
				<ButtonLink color="hollow" className="friends-add" to="/profile/people/search">
					<Icon code="add" className="friends-add__icon" />
					{t('profile.tab.friends.add')}
				</ButtonLink>
			</div>
			{
				friends.length === 0 &&
				<div className="profile-tab-friends__empty">
					<span>{t('profile.tab.friends.empty')}</span>
				</div>
			}
			{
				friends?.length > 0 &&
				<div className="profile-tab-friends__list">
					<div>
						{
							friends.map(friend => (
								<PublicUserTeaser
									isAuthenticated={true}
									key={friend._key}
									{...friend}
									addFriend={() => addFriend('friends', friend._key)}
									removeFriend={() => removeFriend('friends', friend._key, friend.name)}
									acceptFriend={() => acceptFriend('friends', friend._key, friend.isFriend.token)}
									ignoreFriend={() => ignoreFriend('friends', friend._key, friend.isFriend.token, friend.name)}
								/>
							))
						}
					</div>
					{
						hasMore &&
						<div className="profile-tab-friends__list__actions">
							<Button onClick={onLoadMore}>{t('profile.tab.friends.more')}</Button>
						</div>
					}
				</div>
			}
		</div>
	);
}

export default ProfileTabFriends;