// @flow
import { useMemo } from 'react';
import TextField from '@mui/material/TextField';
import OptionalLabel from 'components/general/input/ely/optional-label';

type ElyNumberProps = {
	name:string,
	label?:string,
	placeholder?:string,
	error?:string,
	warning?:string,
	required?:boolean,
	InputProps?: Object,
	className?: string,
	variant?: 'standard' | 'outlined' | 'filled',
	onBlur?:(e: SyntheticEvent<HTMLInputElement>) => void,
	onChange?:(e: SyntheticEvent<HTMLInputElement>) => void,
}

const ElyNumber = ({
	name,
	label,
	placeholder,
	error,
	warning,
	required,
	InputProps = {},
	className = '',
	variant = 'standard',
	onChange,
	onBlur,
	...other
}: ElyNumberProps): React$Node => {

	const helperTextClass = useMemo(() => {
		if ( error ) return 'u-color-error';
		else if ( warning ) return 'u-color-warning';
		return '';
	}, [error, warning]);

	return (
		<TextField
			{...other}
			type="number"
			id={name}
			name={name}
			label={<OptionalLabel label={label} required={required} />}
			placeholder={placeholder}
			error={!!error}
			onChange={onChange}
			onBlur={onBlur}
			InputProps={InputProps}
			variant={variant}
			className={className}
			helperText={error ?? warning ?? ''}
			FormHelperTextProps={{
				classes: {
					root: helperTextClass
				}
			}}
		/>
	);
};

export default ElyNumber;