import { useRef, useCallback } from 'react';
import { Backend, post } from 'actions/backend';

export default function useImageAnalysis() {
	const canvasRef = useRef(null);

	const getPixels = useCallback(img => {
		const canvas = canvasRef.current;
		const ctx = canvas.getContext("2d");
		const cw = canvas.width;
		const ch = canvas.height;
		const maxW = 224;
		const maxH = 224;

		const iw = img.width;
		const ih = img.height;
		const scale = Math.min((maxW / iw), (maxH / ih));
		const iwScaled = iw * scale;
		const ihScaled = ih * scale;

		const px = (maxW-iwScaled)/2;
		const py = (maxH-ihScaled)/2;
		const width = iwScaled;
		const height = ihScaled;

		ctx.clearRect(0, 0, cw, ch);
		ctx.fillStyle = "#000000";
		ctx.drawImage(img,px,py,width,height);

		const imgData = ctx.getImageData(0, 0, maxW, maxH).data;
		const pixels = [];
		for(let h=0;h<maxH;h++){
			const row = [];
			for(let w=0;w<maxW;w++){
				const pixel = ((h * maxW) + w) * 4;
				row.push( [ imgData[pixel]/255, imgData[pixel+1]/255, imgData[pixel+2]/255 ] );
			}
			pixels.push(row)
		}

		return pixels;
	}, [canvasRef]);

	const loadImage = useCallback(src => {
		return new Promise((resolve, reject) => {
			const img = new Image();
			img.addEventListener("load", () => resolve(img));
			img.addEventListener("error", err => reject(err));
			img.src = src;
		});
	}, []);

	const analyze = useCallback((image, config = {}) => {
		const { showLoading = true, showErrors = true } = config;
		return loadImage(URL.createObjectURL(image))
			.then(img => getPixels(img))
			.then(pixels => post(
					Backend.ANALYZE,
					{ instances: [pixels] },
					{ params: { top_k: 3 }, showLoading, showErrors }
				))
			.then(res => res.data);
	}, [loadImage, getPixels]);

	return {
		canvasRef,
		analyze
	}
}