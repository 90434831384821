import { useState, useReducer, useEffect, useCallback } from 'react';
import groupBy from 'lodash/groupBy';
import { format } from 'utils/date';
import { Backend, get } from 'actions/backend';
import { transformHistory } from 'components/public/profile/profile-helper';
import { LIMIT, InitialPayload } from 'components/public/profile/profile-wrapper';

const getReducer = transformer => (state, action) => {
	switch (action.type) {
		case 'load':
			// New items from backend may have items that should be appended to last date group of old items
			// In order to not re-calculate the whole data set we append new items to items of last of the old groups
			// and recalculate only those
			const oldGroups = state.data.slice(0,-1); // Keep old groups except for last group
			const oldLastItems = state.data.slice(-1)[0]?.items ?? []; // Get the items from old last group
			const newItems = transformer(action.payload.content); // Transform new items
			const newGroups = groupBy( // append new items to last group from old and group them
				[...oldLastItems, ...newItems],
				item => format(item.insertedAt, 'YYYY-MM-DD')
			);

			// Convert object to array to ensure ordering
			const newContent = Object.keys(newGroups)
				.sort((d1, d2) => new Date(d2) - new Date(d1))
				.map(group => ({group, items: newGroups[group]}));

			return {
				data: [...oldGroups, ...newContent],
				offset: state.offset + LIMIT,
				hasMore: !action.payload.last,
				total: action.payload.totalElements
			};
		case 'reset':
			return InitialPayload;
		default:
			throw new Error();
	}
}

export default function useProfileHistory(key) {
	const [historyLoading, setHistoryLoading] = useState(false);
	const [contributionsLoading, setContributionsLoading] = useState(false);
	const [history, dispatchHistory] = useReducer(getReducer(transformHistory), InitialPayload);
	const [contributions, dispatchContributions] = useReducer(getReducer(transformHistory), InitialPayload);

	//
	// Load history
	//
	const onLoadHistory = useCallback(() => {
		if ( historyLoading ) return;
		setHistoryLoading(true);
		get(Backend.HISTORY(key), { offset:history.offset, limit:LIMIT })
			.then(res => {
				dispatchHistory({ type:'load', payload:res.data });
				setHistoryLoading(false);
			});
	}, [historyLoading, history.offset, key]);

	//
	// Load contributions
	//
	const onLoadContributions = useCallback(() => {
		if ( contributionsLoading ) return;
		setContributionsLoading(true);
		get(Backend.HISTORY_CONTRIBUTIONS(key), { offset: contributions.offset, limit:LIMIT })
			.then(res => {
				dispatchContributions({ type:'load', payload:res.data });
				setContributionsLoading(false);
			});
	}, [contributionsLoading, contributions.offset, key]);

	//
	// Reset history and contributions on key change
	//
	useEffect(() => {
		dispatchHistory({ type: 'reset' });
		dispatchContributions({ type: 'reset' });
	}, [key]);

	return {
		history,
		onLoadHistory,
		contributions,
		onLoadContributions
	}
}