import { ACTION_IS_GOOGLE_MAP_LOADING, ACTION_IS_GOOGLE_MAP_LOADED } from 'actions/types';

export const isGoogleMapLoading = (state = false, action) => {
    switch(action.type) {
        case ACTION_IS_GOOGLE_MAP_LOADING:
            return action.payload;
        default: return state;
    }
};

export const isGoogleMapLoaded = (state = false, action) => {
    switch(action.type) {
        case ACTION_IS_GOOGLE_MAP_LOADED:
            return action.payload;
        default: return state;
    }
};