// @flow
import ElyLink from 'components/general/link/ely-link';

type Props = {
	to: string,
	state?: Object,
	color?: string,
	className?: string,
	children?: React$Node
}

const ButtonLink = ({ to, state, color, className, children }: Props): React$Node => (
	<ElyLink to={to} state={state} color={color} variant="button" className={(className && className) || ''}>
		{children}
	</ElyLink>
);

export default ButtonLink;