// @flow
import Icon from 'components/general/icon';
import Button from './button';

type DeleteButtonProps = {|
	type?: string,
	variant?: ?string,
	color?: ?string,
	styling?: Array<string> | ?string,
	className?: string,
	children?: React$Node,
	onClick?: (e: SyntheticEvent<HTMLButtonElement>) => void,
|}

const DeleteButton = ({ onClick, ...other }: DeleteButtonProps): React$Node => (
	<Button title="Delete" variant={null} color={null} onClick={onClick} {...other}>
		<Icon code="delete" style={{color:"inherit"}} />
	</Button>
);
export default DeleteButton;