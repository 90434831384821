// @flow

export type GeolocationProps = {
	latitude: number,
	longitude: number
}
export type LocationProps = {
	country: string,
	city: string,
	village: string,
	street: string,
	postCode: number,
	what3words?: string,
	howToGetThere?: string,
	geolocation: GeolocationProps
}

export const GeolocationEmpty: GeolocationProps = {
	latitude: Number(undefined),
	longitude: Number(undefined)
};

export const LocationEmpty: LocationProps = {
	country: "",
	city: "",
	street: "",
	postCode: Number(undefined),
	what3words: "",
	howToGetThere: "",
	geolocation: GeolocationEmpty
};