// @flow
import "scss/components/general/input/ely/optional-label.scss";

type Props = {
	label?: string,
	required?: boolean,
};

const OptionalLabel = ({ label, required }: Props): React$Node => (
	required
		? <span>{label}</span>
		: <span>{label} <i className="label__optional">(optional)</i></span>
);

export default OptionalLabel;
