// @flow
import withI18n from "hoc/with-i18n";
import TeasersList from "components/public/general/teasers-list";

import "scss/components/public/profile/profile-photos.scss";

type ProfilePhotosProps = {
	photos: Array<string>,
	count: number,
	t: string => string
}

const ProfilePhotos = ({ photos, count, t }: ProfilePhotosProps) => (
	<TeasersList
		title={t('profile.photos.title')}
		count={count}
		moreUrl={{ pathname: "/photos" }}
		moreLabel={t('profile.viewall.label')}
	>
		{
			photos.map((photo, index) => (
				<img key={index} src={photo} className="profile-photos__item" alt={t('profile.photos.item.alt')} />
			))
		}
	</TeasersList>
);

ProfilePhotos.defaultProps = {
	photos: []
};

export default withI18n(ProfilePhotos);