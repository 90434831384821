import { NodeTypeAlias, Status } from 'utils/variables';

const ADMIN_USER_FILTERS = {
	role: [
		{ label: 'Admin', value: 'admin' },
		{ label: 'Partner', value: 'partner' },
		{ label: 'Supplier', value: 'supplier' },
		{ label: 'End User', value: 'user' },
	]
};

const SUPPLIER_FILTERS = {
	nodeTypeAlias:[
		{label:'All Service Types', value:'%'},
		{label:NodeTypeAlias.ACCOMMODATION, value:NodeTypeAlias.ACCOMMODATION},
		{label:NodeTypeAlias.FOOD_AND_BEVERAGE, value:NodeTypeAlias.FOOD_AND_BEVERAGE},
		{label:NodeTypeAlias.SHOW_AND_EVENT, value:NodeTypeAlias.SHOW_AND_EVENT},
		{label:NodeTypeAlias.EXCURSION, value:NodeTypeAlias.EXCURSION},
		{label:NodeTypeAlias.TRANSFER, value:NodeTypeAlias.TRANSFER},
		{label:NodeTypeAlias.CRUISE, value:NodeTypeAlias.CRUISE}
	]
};

const SERVICE_FILTERS = {
	nodeTypeAlias:[
		{label:'All Service Types', value:'%'},
		{label:NodeTypeAlias.ACCOMMODATION, value:NodeTypeAlias.ACCOMMODATION},
		{label:NodeTypeAlias.FOOD_AND_BEVERAGE, value:NodeTypeAlias.FOOD_AND_BEVERAGE},
		{label:NodeTypeAlias.SHOW_AND_EVENT, value:NodeTypeAlias.SHOW_AND_EVENT},
		{label:NodeTypeAlias.EXCURSION, value:NodeTypeAlias.EXCURSION},
		{label:NodeTypeAlias.TRANSFER, value:NodeTypeAlias.TRANSFER},
		{label:NodeTypeAlias.CRUISE, value:NodeTypeAlias.CRUISE}
	],
	status:[
		{label:'All Statuses', value:'%'},
		{label:'Published', value:Status.PUBLISHED},
		{label:'Draft', value:Status.DRAFT}
	]
};

const BUNDLES_FILTERS = {
	status:[
		{label:'All Statuses', value:'%'},
		{label:'Published', value:Status.PUBLISHED},
		{label:'Draft', value:Status.DRAFT}
	]
};

export { ADMIN_USER_FILTERS, SUPPLIER_FILTERS, SERVICE_FILTERS, BUNDLES_FILTERS };
