import { createRoot } from 'react-dom/client';
import ConfirmModal from './confirm-modal';

const modal = document.getElementById('ely-modal');

const confirm = (options = {}) => {
	const root = createRoot(modal.appendChild(document.createElement('div')));
    const dispose = () => root.unmount();
    return new Promise((resolve, reject) => {
        try {
			root.render(
				<ConfirmModal
					reject={reject}
					resolve={resolve}
					dispose={dispose}
					{...options}
				/>
			);
        } catch (e) {
            console.error(e);
            throw e;
        }
    });
};

export default confirm;
