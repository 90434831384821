// @flow
import { useEffect, useCallback } from 'react';
import { Backend, get } from 'actions/backend';
import usePeopleReducer, { LIMIT } from 'hooks/public/people/usePeopleReducer';
import usePeopleSocialActions from 'hooks/public/people/usePeopleSocialActions';
import PublicUsersList from 'components/public/user/public-users-list';

const ProfilePeopleRequestsWrapper = (): React$Node => {
	const { users, loadUsers, updateUsers, resetUsers } = usePeopleReducer();
	const { addFriend, removeFriend, acceptFriend, ignoreFriend } = usePeopleSocialActions(users.data, users => updateUsers(users));

	//
	// Load inbound friend requests from backend
	//
	const onLoad = useCallback(offset => {
		get(Backend.PROFILE_FRIENDS_REQUESTS, { direction:'INBOUND', offset, limit:LIMIT })
			.then(res => loadUsers(res.data));
	}, [loadUsers]);

	//
	// Fire the initial onLoad
	//
	useEffect(() => {
		resetUsers();
		onLoad(0);
	}, [onLoad, resetUsers]);

	return (
		<PublicUsersList
			users={users.data}
			hasMore={users.hasMore}
			onLoadMore={() => onLoad(users.offset)}
			addFriend={addFriend}
			removeFriend={removeFriend}
			acceptFriend={acceptFriend}
			ignoreFriend={ignoreFriend}
		/>
	);
}

export default ProfilePeopleRequestsWrapper;
