// @flow
import type { AuditEventProps } from 'types/audit-event-props';
import UrlUtils from 'utils/helpers/url-utils';
import FileUtils from 'utils/helpers/file-utils';
import { geocoderFormat } from 'utils/helpers/google-location-utils';

export { UrlUtils, FileUtils };
export { geocoderFormat };

export function getButtonClassForVariant(variant: ?string): string {
	if ( variant === 'button' ) return "button-v-btn";
	if ( variant === 'link' ) return "button-v-link";
	if ( variant === 'nav' ) return "button-v-nav";
	return "";
}

export function getButtonClassForColor(color: ?string, variantClass: string) {
	if ( color ) return `${variantClass}--${color}`;
	return "";
}

export function getButtonClassForStyle(styling: Array<string> | ?string, variantClass: string) {
	if ( !styling ) return "";
	if ( styling.constructor === Array ) {
		return styling.map(s => `${variantClass}--${s}`).join(" ");
	}
	return `${variantClass}--${styling}`;
}

export function getClassForAuditEvent(event: AuditEventProps) {
	return event.toLowerCase().replace(/_/gi, "-");
}