// @flow
import type { LayoutWidthProps } from 'types/general/commons';
import { useMe } from 'auth';
import { Outlet, useLocation } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { Helmet } from 'react-helmet';
import LayoutModalHeader from 'components/layout/modal/layout-modal-header';
import 'scss/components/layout/modal/layout-modal.scss';
import LayoutWidth from "../../../utils/variables/layout-width";
import ConditionalWrapper from "../../general/wrapper/conditional-wrapper";

type Props = {
	width?: LayoutWidthProps,
	title?: string,
	metaTitle?: string,
	children?: React$Node,
	className?: string,
};

const LayoutModal = ({ width = LayoutWidth.FULL_WIDTH, title, metaTitle, children, className }: Props): React$Node => {
	const me = useMe();
	const location = useLocation();
	const back = location.state?.from ?? encodeURI(me?.path);
	return (
		<>
			<Helmet>
				{ !!metaTitle && <title>{metaTitle}</title> }
			</Helmet>
			<CSSTransition
				in={true}
				timeout={300}
				classNames="LayoutModal"
				unmountOnExit
				appear
			>
				<div className={`LayoutModal ${!!title ? 'LayoutModal--with-header' : ''} ${className ?? ''}`}>
					{
						!!title && <LayoutModalHeader title={title} back={back} />
					}
					<main className={`LayoutModal__main LayoutModal__main--${width}`}>
						<ConditionalWrapper
							condition={LayoutWidth.MAX_WIDTH === width}
							trueWrapper={children => <div className="LayoutModal__main__inner">{children}</div>}
						>
							{
								!!children && children
							}
							{
								!children && <Outlet />
							}
						</ConditionalWrapper>
					</main>
				</div>
			</CSSTransition>
		</>
	);
};

export default LayoutModal;
