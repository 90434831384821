// @flow
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

type ElyYesNoRadioProps = {
    name: string,
    label?: string,
    options: Array<Object>,
    value: boolean | string,
    onChange?: SyntheticEvent<HTMLInputElement> => void,
    onBlur?: SyntheticEvent<HTMLInputElement> => void
};

const ElyYesNoRadio = ({ name, label, value, options, onChange, onBlur }: ElyYesNoRadioProps) => {
    return (
        <FormControl component="fieldset">
            <FormLabel component="legend">{label}</FormLabel>
            <RadioGroup
                id={name}
                value={value && ( value === true || value === 'true' ) ? 'true' : 'false'}
                onChange={onChange}
                onBlur={onBlur}
            >
                {
                    options.map(option => <FormControlLabel key={option.key} value={option.key} control={<Radio />} label={option.label} />)
                }
            </RadioGroup>
        </FormControl>
    );
};

export default ElyYesNoRadio;