// @flow
import { useState, useCallback, useEffect } from 'react';
import { useLocation } from 'react-router';
import { useAuth } from 'auth/index';
import { Backend, get, put, post } from 'actions/backend';
import { notificationService } from 'components/general/notification';
import useSocialLogin from 'components/auth/social/useSocialLogin';
import LinkedAccounts from 'components/general/account/settings/linked-accounts';

const SocialMappings = {
	FACEBOOK: 'facebookConnected',
	TWITTER: 'twitterConnected',
	GOOGLE: 'googleConnected',
}

const LinkedAccountsWrapper = (): React$Node => {
	const location = useLocation();
	const { dominantRole } = useAuth();
	const [linkedAccounts, setLinkedAccounts] = useState({});
	const { onTwitterToken, onGoogleLogin } = useSocialLogin(dominantRole, location, 'link');

	const onFbConnect = useCallback((fbAccessToken) => {
		return post(Backend.OAUTH_FACEBOOK, { fbAccessToken }, { params: "link", showErrors: false })
			.catch(err => {
				if ( err.response.data === 'social.facebook.user.conflict' ) {
					notificationService.show({ level: "error", title: "Error", autoHide: 6000, messages: [{code: err.response.data}] });
				}
				throw err;
			})
			.then(res => {
				notificationService.show({ level: "success", autoHide: 6000, messages: [{code: 'social.facebook.link.success'}] });
				setLinkedAccounts(state => ({
					...state,
					facebookConnected: true
				}));
				return res;
			});
	}, []);

	const disconnectSocial = useCallback(provider => {
		put(Backend.PROFILE_SETTINGS_DISCONNECT_SOCIAL(provider))
			.then(() => setLinkedAccounts(state => ({
				...state,
				[SocialMappings[provider]]: false
			})));
	}, []);

	useEffect(() => {
		get(Backend.PROFILE_SETTINGS, { fields: 'linked_accounts' })
			.then(res => setLinkedAccounts(res.data));
	}, []);

	return (
		<LinkedAccounts
			user={linkedAccounts}
			onFbConnect={onFbConnect}
			onTwitterConnect={onTwitterToken}
			onGoogleConnect={onGoogleLogin}
			disconnectSocial={disconnectSocial}
		/>
	);
};

export default LinkedAccountsWrapper;
