import { PureComponent } from 'react';
import { notificationService } from 'components/general/notification/notification-service';
import Snackbar from '@mui/material/Snackbar';
import NotificationHeader from './notification-header';
import NotificationClose from './notification-close';
import NotificationContent from './notification-content';

import "scss/components/shared/notifications.scss"

const AnchorOrigin = {
	vertical: 'top',
	horizontal: 'center',
};

class Notification extends PureComponent {
    state = {
        isOpen: false,
        config: undefined
    };

    constructor(props) {
        super(props);
        notificationService.register(this);
    }

    componentWillUnmount(): void {
        notificationService.unregister();
    }

    show = config => this.setState({isOpen:true, config});
    hide = () => this.setState({isOpen:false, config:undefined});
    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.hide();
    };

    render() {
        if ( !this.state.config ) return null;
        const { isOpen } = this.state;
        const { level, title, messages, autoHide, customHtml, hideCloseButton, anchorOrigin = AnchorOrigin } = this.state.config;
        return (
            <>
                {
                    isOpen && (messages || customHtml) &&
                    <Snackbar
                        anchorOrigin={anchorOrigin}
                        open={isOpen}
                        autoHideDuration={autoHide ? autoHide : null}
                        onClose={this.handleClose}>
                        <div className={`u-notification${!title ? ' no-title' : ''} level-${level}`}>
                            {
                                title &&
                                <NotificationHeader title={title} onClose={this.handleClose}/>
                            }
                            <NotificationContent level={level} messages={messages} customHtml={customHtml} onClose={this.handleClose}/>
                            {
                                !title && !hideCloseButton &&
                                <NotificationClose onClose={this.handleClose}/>
                            }
                        </div>
                    </Snackbar>
                }
            </>
        );
    }
}

export default Notification;