export const routes = {
	HOME: '/',

	// AUTH
	AUTH: '/auth',
	get LOGIN() { return `${this.AUTH}/login` },
	get FORGOT() { return `${this.AUTH}/forgot-password` },
	get REGISTER() { return `${this.AUTH}/register/user` },
	get CHANGE_PASSWORD() { return `${this.AUTH}/change-password` },
	FOR_BUSINESS: '/for-business',

	//
	// CMS routes
	//
	CMS: '/cms',
	CMS_STORIES: '/cms/stories',

	//
	// PUBLIC routes
	//
	FEED: '/feed',
	SEARCH: '/search',
	STORIES: '/stories',
	STORIES_ADD: '/stories/add',
	STORIES_EDIT: '/stories/edit',
	STORIES_PREVIEW: '/stories/preview',
	SHOP: '/shop',
	PROFILE: '/profile',

	NOTIFICATIONS: '/notifications',
	FAVOURITES: '/favourites',
	TAGS: '/tags',
	CONTRIBUTE: '/contribute',

	SETTINGS: '/settings',
	SETTINGS_PROFILE_EDIT: '/settings/profile/edit',

	//
	// ABOUT routes
	//
	ABOUT: '/about',
	HELP: '/help',
	PRIVACY: '/privacy',
	TERMS_AND_CONDITIONS: '/legal/user/terms',
}