// @flow
import { useMemo } from 'react';
import { useLocation } from 'react-router';
import { useAuth } from 'auth/hooks';

type ReturnProps = {
	pathname?: string,
	search?: string,
	state?: string,
};

export default function useAuthRedirect(exclude?: Array<string>): ?ReturnProps {
	const location = useLocation();
	const { initialized, isAuthenticated, rolePath } = useAuth();
	const authFrom = useMemo(() => {
		const authFrom = localStorage.getItem("authFrom");
		localStorage.removeItem("authFrom");
		return JSON.parse(authFrom);
	}, []);

	// If auth not initialized or not authenticated, do not redirect
	if ( !initialized || !isAuthenticated ) return null;

	// If location.pathname matches any from exclude, do not redirect
	if ( exclude?.filter(ex => location.pathname.includes(ex)).length > 0 ) return null;

	// Calculate redirection
	if ( authFrom ) return { pathname: authFrom }
	return location.state?.from ?? { pathname: rolePath };
}
