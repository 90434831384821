import rootReducer from 'reducers';
import { createStore } from 'redux';
import { useInitializeAuth } from 'auth';
import { hydrateRoot } from 'react-dom/client';
import { setContentTypeHeader } from 'utils/axios-header';
import useInitializeLocale from 'hooks/i18n/useInitializeLocale';
import { useAuthModalInitialization } from 'components/auth/auth-modal-context';
import Providers from './providers';
import Experly from './experly';
import 'scss/style.scss';

const store = createStore(rootReducer);
setContentTypeHeader('application/json');

const App = () => {
	const authModal = useAuthModalInitialization();
    const { jwt, idToken } = useInitializeAuth();
	const { i18n, i18nLoaded } = useInitializeLocale();

    if ( !i18nLoaded ) return null;
    return (
		<Providers
			reduxStore={store}
			i18n={i18n}
			jwt={jwt}
			idToken={idToken}
			authModal={authModal}
		>
			<Experly />
		</Providers>
    );
};

const container = document.getElementById('root');
hydrateRoot(container, <App />);
