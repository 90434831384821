// @flow
import type { LocationProps } from 'types/general/location-props';
import type { ApiLocationProps } from 'types/api/general/api-location-props';

export function transformLocation(location: ApiLocationProps): LocationProps {
	if (!location) return null;
	return {
		country: location.country,
		city: location.city,
		village: location.village,
		street: location.street,
		postCode: location.postCode,
		what3words: location.what3words,
		howToGetThere: location.howToGetThere,
		geolocation: location.geolocation
	};
}

export function getAddressString(location: LocationProps): string {
	const { country, countryCode, city, village, postCode, street } = location ?? {};
	return [
		street,
		city,
		village,
		postCode,
		country ?? countryCode,
	]
		.filter(token => !!token && token !== '')
		.join(', ');
}