// @flow
//
// Material-UI library uses the native datepicker so we need to use the following extra plugin
// https://material-ui-pickers.firebaseapp.com
//
import { useMemo } from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';

type ElyDatepickerProps = {
	name:string,
	label?:string,
	placeholder?:string,
	value?:Object,
	error?: string,
	warning?: string,
	dateFormat?:string,
	disabled?:boolean,
	shouldDisableDate?:boolean,
	clearable?: boolean,
	autoOk?:boolean,
	disablePast?:boolean,
	InputProps?: Object,
	inputFormat?: string,
	variant?: 'standard' | 'outlined' | 'filled',
	open?: boolean,
	onChange?: SyntheticEvent<HTMLInputElement> => void,
	onBlur?: SyntheticEvent<HTMLInputElement> => void,
	onOpen?: SyntheticEvent<HTMLInputElement> => void,
	onClose?: SyntheticEvent<HTMLInputElement> => void
}

const ElyDatepicker = ({
	name,
	label,
	value,
	error,
	warning,
	disabled,
	shouldDisableDate,
	disablePast,
	InputProps,
	inputFormat,
	variant,
	open,
	onBlur,
	onChange,
	onOpen,
	onClose,
}: ElyDatepickerProps): React$Node => {

	const helperTextClass = useMemo(() => {
		if ( error ) return 'u-color-error';
		else if ( warning ) return 'u-color-warning';
		return '';
	}, [error, warning]);

	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<DatePicker
				id={name}
				name={name}
				label={label}
				value={value}
				open={open}
				onBlur={onBlur}
				onChange={onChange}
				onOpen={onOpen}
				onClose={onClose}
				disabled={disabled}
				disablePast={disablePast}
				shouldDisableDate={shouldDisableDate}
				InputProps={InputProps}
				inputFormat={inputFormat}
				renderInput={(props) => (
					<TextField
						{...props}
						variant={variant}
						error={!!error}
						helperText={error ?? warning ?? ''}
						FormHelperTextProps={{
							classes: {
								root: helperTextClass
							}
						}}
					/>
				)}
			/>
		</LocalizationProvider>
	);
}

const emptyObject: Object = {};
ElyDatepicker.defaultProps = {
	variant: 'standard',
	InputProps: emptyObject,
	inputFormat: 'DD/MM/YYYY',
	clearable: false,
	autoOk: true,
	disablePast: false,
}

export default ElyDatepicker;