import { useState, useEffect, useCallback } from 'react';
import { Backend, get } from 'actions/backend';
import usePeopleReducer, { LIMIT } from 'hooks/public/people/usePeopleReducer';

export default function useSearchFriends(key) {
	const [query, setQuery] = useState(undefined);
	const { users: search, initUsers, loadUsers, resetUsers } = usePeopleReducer();

	//
	// Search friends
	//
	const doSearch = useCallback((q, offset) => {
		return get(Backend.PROFILE_FRIENDS_GET(key), { query:q, offset, limit:LIMIT });
	}, [key]);

	//
	// On Search
	//
	const onSearchFriends = useCallback(values => {
		doSearch(values.search, 0)
			.then(res => {
				setQuery(values.search);
				initUsers(res.data);
			});
	}, [doSearch, initUsers]);

	//
	// Load next pages from search
	//
	const onSearchFriendsMore = useCallback(() => {
		doSearch(query, search.offset)
			.then(res => loadUsers(res.data));
	}, [query, search, doSearch, loadUsers]);

	//
	// Reset search on key change
	//
	useEffect(() => {
		setQuery(undefined);
		resetUsers();
	}, [key, resetUsers]);

	return {
		search,
		onSearchFriends,
		onSearchFriendsMore
	}
}