// @flow
import HeaderButton from 'components/layout/header-button';
import 'scss/components/layout/header-button-close.scss';

type Props = {
	to?: string | Object,
	onClick?: SyntheticEvent<HTMLButtonElement> => void,
	variant?: 'primary' | 'secondary',
	className?: string,
};

const HeaderButtonClose = ({ to, onClick, variant = 'primary', className = '' }: Props): React$Node => (
	<HeaderButton
		to={to}
		onClick={onClick}
		icon="close"
		ariaLabel="Back"
		className={`HeaderButtonClose HeaderButtonClose--${variant} ${className}`}
	/>
);

export default HeaderButtonClose;
