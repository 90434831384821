import isEmpty from 'lodash/isEmpty';
import { ajax } from './axios';
import { loadingService } from 'components/general/loading';
import { notificationService } from 'components/general/notification';
import { getErrorMessages } from 'actions/backend/error-utils';

export function batchGet(code, call, params, config) {
	return [
		code,
		() => ajax({...config, url: call.url, params, method:'get', showLoading: false})
			.catch(err => err) // we need the catch because ajax() throws error instead of returning errors on catch
	];
}

export function batchPost(code, call, data, config) {
	return [
		code,
		() => ajax({...config, url: call.url, data, method:'post', showLoading: false})
			.catch(err => err) // we need the catch because ajax() throws error instead of returning errors on catch
	];
}

export function batchPut(code, call, data, config) {
	return [
		code,
		() => ajax({...config, url: call.url, data, method:'put', showLoading: false})
			.catch(err => err) // we need the catch because ajax() throws error instead of returning errors on catch
	];
}

export function batchDel(code, call, params, config) {
	return [
		code,
		() => ajax({...config, url: call.url, params, method:'get', showLoading: false})
			.catch(err => err) // we need the catch because ajax() throws error instead of returning errors on catch
	];
}

export function batch({
    calls, success, failure, showLoading = true, showErrors = true
}) {
	showLoading && loadingService.show();
	const codes = calls.map(([code, call]) => code);
	const promises = calls.map(([code, call]) => call());
	return Promise.all(promises)
		.then(res => {
			const resFormated = {};
			const errMsgs = getErrorMessages(res).flat().filter(err => !!err);
			res.forEach((r, index) => resFormated[codes[index]] = r);
			if ( isEmpty(errMsgs) && success ) {
				success(resFormated);
			}
			else if ( !isEmpty(errMsgs) ) {
				console.log(res);
				if ( showErrors ) {
					notificationService.show({
						level: "error",
						title: "Error",
						messages: errMsgs
					});
				}
				if ( failure ) failure(resFormated);
			}
			loadingService.hide();
			return res;
		})
		.catch(err => console.log(err));
}