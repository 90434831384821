// @flow
let googleTimer;
let googlePromise;

type BuildGoogleMapsUrlParams = {
	base: string,
	apiKey: string,
	client?: string,
	libraries?: Array<string>,
	language?: string,
	version?: string,
	callback: string
}

/**
 *
 * @param base
 * @param apiKey
 * @param client
 * @param libraries
 * @param language
 * @param version
 * @param callback
 * @returns {string}
 */
function buildGoogleMapsUrl({ base, apiKey, client, libraries, language, version, callback }: BuildGoogleMapsUrlParams) {
	let url = base;

	if ( apiKey ) {
		url += `?key=${apiKey}`;
	}
	if ( client ) {
		url += `&client=${client}`;
	}
	if ( libraries && libraries.length > 0 ) {
		url += `&libraries=${libraries.join(",")}`;
	}
	if ( language ) {
		url += `&language=${language}`;
	}
	if ( version ) {
		url += `&v=${version}`;
	}
	if ( callback ) {
		url += `&callback=${callback}`;
	}

	return url;
}

type LoadGoogleMapsApiProps = {
	base?: string,
	apiKey: string,
	client?: string,
	libraries?: Array<string>,
	language?: string,
	version?: string,
	callback?: string
}

/**
 *
 * @param params
 */
function load(params: LoadGoogleMapsApiProps) {
	const apiUrl = buildGoogleMapsUrl({
		base: 'https://maps.googleapis.com/maps/api/js',
		callback: 'googleMapAPICallback',
		...params
	});

	// Asynchronously load the Google Maps script, passing in the callback reference
	// const ref = window.document.getElementsByTagName("script")[0];
	const script = document.createElement("script");
	script.type = 'text/javascript';
	script.src = apiUrl;
	script.async = true;
	document.querySelector('head')?.appendChild(script);
}

/**
 *
 * @param params
 * @returns {Promise<Object>}
 */
export default function loadGoogleMapsAPI(params: LoadGoogleMapsApiProps): Promise<any> {
	if ( window.google ) {
		return Promise.resolve(window.google);
	}

	if ( !googlePromise ) {
		googlePromise = new Promise<Object>((resolve, reject) => {
			window.googleMapAPICallback = () => {
				clearTimeout(googleTimer);
				googleTimer = undefined;
				resolve(window.google);
			};

			load(params);

			googleTimer = setTimeout(() => {
				if ( !window.google ) {
					reject(new Error('Loading google maps api over 10 seconds. Rejecting.'));
				}
			}, 10000);
		});
	}

	return googlePromise;
}
