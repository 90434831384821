import { PureComponent } from 'react';
import withI18n from 'hoc/with-i18n';

import "scss/components/shared/notifications.scss"

class NotificationContent extends PureComponent {

    render() {
        const { t } = this.props;
        return (
            <div className="u-notification__body">
	            {
					!!this.props.messages && (
			            <ul>
				            {/*capital classes come from backend*/}
				            {/*available classes: success, error, VALIDATION_ERROR*/}
				            {
					            this.props.messages.map((m, i) => {
						            if ( m.constructor === Object ) {
							            return <li key={i} className={m.level ? `level-${m.level}` : ''}>{t(m.code) }</li>;
						            }
						            else
							            return <li key={i} className={this.props.level}>{t(m)}</li>;
					            })
				            }
			            </ul>
		            )
	            }
                {this.props.customHtml ?? null}
            </div>
        );
    }
}

export default withI18n(NotificationContent);