// @flow
import { Navigate, Outlet } from 'react-router-dom';
import useAuthRedirect from 'hooks/auth/useAuthRedirect';

type Props = {
	children?: React$Node,
};

const RedirectAuthenticated = ({ children }: Props): React$Node => {
	const redirect = useAuthRedirect(['/auth/link']);

	if ( redirect ) return (
		<Navigate to={redirect} />
	);

	return (
		<>
			{children ?? <Outlet />}
		</>
	);
};

export default RedirectAuthenticated;
