// @flow
import { AuthUtils, Roles, useAuth, useMe } from 'auth';
import { routes } from 'utils/routes';
import useI18n from 'hooks/i18n/useI18n';
import { useLocation } from 'react-router-dom';
import useAuthActions from 'auth/useAuthActions';
import { ButtonLink } from 'components/general/link';
import Button, { LinkButton } from 'components/general/button';
import { AuthTypes, useAuthModal } from 'components/auth/auth-modal-context';
import {
	LayoutMainProfileDivider,
	LayoutMainProfileItem,
	LayoutMainProfileSocialItem,
	LayoutMainProfileTitle
} from 'components/layout/main/layout-main-commons';
import 'scss/components/layout/main/layout-main-profile-menu.scss';

type Props = {
	isOpen?: boolean,
	onClose?: void => void,
	className?: string,
};

const LayoutMainProfileMenu = ({ isOpen = true, onClose = () => {}, className }: Props): React$Node => {
	const me = useMe();
	const { t } = useI18n();
	const { authorities } = useAuth();
	const { pathname } = useLocation();
	const { doLogout } = useAuthActions();
	const fromState = { from: pathname };
	const { openAuth } = useAuthModal();

	const onOpenAuth = type => () => {
		openAuth(type);
		onClose?.();
	}

	if (!isOpen) return null;
	return (
		<div className={`LayoutMainProfileMenu ${className}`}>
			{
				!me && (
					<>
						<Button onClick={onOpenAuth(AuthTypes.LOGIN)} className="login">{t('layout.main.profile.login')}</Button>
						<div className="LayoutMainProfileMenu__register">
							{/*<span className="text">{t('layout.main.profile.register.description')} <ElyLink to={routes.REGISTER}>{t('layout.main.profile.register')}</ElyLink></span>*/}
							<span className="text">
								{t('layout.main.profile.register.description')}&nbsp;
								<LinkButton onClick={onOpenAuth(AuthTypes.REGISTRATION)}>{t('layout.main.profile.register')}</LinkButton>
							</span>
						</div>
					</>
				)
			}

			{
				!!me && (
					<>
						<div className="LayoutMainProfileMenu__user">
							<img src={me.photo.thumbnail} alt={me.photo.alt} className="user-image" />
							<div className="user-details">
								<span className="name">{me.name}</span>
								<span className="points">{me.points}</span>
							</div>
						</div>
						<ButtonLink to={encodeURI(me?.path)} className="view-profile">{t('layout.main.profile.view')}</ButtonLink>
						<LayoutMainProfileItem to={routes.SETTINGS_PROFILE_EDIT} state={fromState} icon="edit-outlined" title={t('profile.settings.edit')} />
						<LayoutMainProfileItem onClick={doLogout} icon="logout" title={t('profile.settings.logout')} />
						<LayoutMainProfileDivider />
						<LayoutMainProfileItem to={routes.NOTIFICATIONS} state={fromState} icon="notification-outlined" title={t('profile.settings.notifications')} />
						<LayoutMainProfileItem to={routes.FAVOURITES} state={fromState} icon="favourite-outlined" title={t('profile.settings.favourites')} />
						<LayoutMainProfileItem to={routes.TAGS} state={fromState} icon="tag" title={t('profile.settings.tags')} />
						<LayoutMainProfileItem to={routes.CONTRIBUTE} state={fromState} icon="contribute" title={t('profile.settings.contribute')} />
						<LayoutMainProfileDivider />
						<LayoutMainProfileItem to={routes.SETTINGS} state={fromState} icon="settings3" title={t('profile.settings.settings')} />
					</>
				)
			}

			<LayoutMainProfileDivider />

			<LayoutMainProfileTitle>{t('layout.main.profile.about')}</LayoutMainProfileTitle>
			<LayoutMainProfileItem to={routes.ABOUT} state={fromState} icon="help" title={t('layout.main.profile.about')} />
			<LayoutMainProfileItem to={routes.HELP} state={fromState} icon="help" title={t('layout.main.profile.help')} />
			<LayoutMainProfileItem to={routes.TERMS_AND_CONDITIONS} icon="book" state={fromState} title={t('layout.main.profile.terms.and.conditions')} />
			<LayoutMainProfileItem to={routes.PRIVACY} state={fromState} icon="book" title={t('layout.main.profile.privacy')} />

			<LayoutMainProfileDivider />

			<LayoutMainProfileTitle>{t('layout.main.profile.business')}</LayoutMainProfileTitle>
			{
				!me && (
					// <ElyLink to={routes.FOR_BUSINESS} className="for-business">{t('layout.main.profile.for.business')}</ElyLink>
          <LinkButton onClick={onOpenAuth(AuthTypes.REGISTRATION_BUSINESS)} className="for-business">{t('layout.main.profile.for.business')}</LinkButton>
				)
			}
			{
				!!me && !AuthUtils.checkRoles(authorities, [Roles.ADMIN, Roles.PARTNER, Roles.SUPPLIER]) && (
					<LinkButton onClick={onOpenAuth(AuthTypes.JOIN_BUSINESS)} className="join-business">{t('layout.main.profile.join.business')}</LinkButton>
				)
			}
			{
				AuthUtils.checkRoles(authorities, [Roles.ADMIN, Roles.PARTNER, Roles.SUPPLIER]) && (
					<LayoutMainProfileItem to={routes.CMS} state={fromState} icon="switch-account" title={t('profile.settings.switch')} />
				)
			}

			<LayoutMainProfileDivider />

			<LayoutMainProfileTitle>{t('layout.main.profile.find.us')}</LayoutMainProfileTitle>
			<ul className="LayoutMainProfileMenu__socials">
				<LayoutMainProfileSocialItem
					social="facebook"
					href="https://www.facebook.com/Experly-532048010502617/"
				/>
				<LayoutMainProfileSocialItem
					social="twitter"
					href="https://twitter.com/exper_ly"
				/>
				<LayoutMainProfileSocialItem
					social="instagram"
					href="https://www.instagram.com/exper.ly//"
				/>
			</ul>

			<LayoutMainProfileDivider />

			<span className="LayoutMainProfileMenu__copyright">&copy; 2023 EXPERLY</span>
		</div>
	);
};

export default LayoutMainProfileMenu;
