export const HttpResponse = {
	OK: 'OK',
	NOT_FOUND: 'NOT_FOUND',
	CONFLICT: 'CONFLICT'
}

export const HttpResponseCode = {
	OK: 200,
	NOT_FOUND: 404,
	CONFLICT: 409
}