// @flow
import { Component } from 'react';
import throttle from 'lodash/throttle';

type DefaultProps = {
	threshold: number,
	throttle: number,
	shouldCheckScroll: boolean,
}

type Props = {
	hasMore?: boolean,
	isLoading?: boolean,
	target?: string | HTMLElement,
	threshold?: number,
	throttle?: number,
	shouldCheckScroll?: boolean,
	onLoadMore?: void => void,
	component?: React$ComponentType<any>,
	children?: React$Node,
	render?: void => void,
};

export class InfiniteScroll extends Component<Props> {
	static defaultProps: DefaultProps = {
		threshold: 100,
		throttle: 64,
		shouldCheckScroll: true
	};
	target: ?HTMLElement;
	sentinel: ?HTMLDivElement;
	scrollHandler: () => void;
	resizeHandler: () => void;

	getScrollTarget(): ?HTMLElement {
		if ( this.props.target instanceof HTMLElement ) return this.props.target;
		if ( typeof this.props.target === 'string' ) {
			return document.querySelector(this.props.target);
		}
		return window;
	}

	componentDidMount(): void {
		if ( this.props.shouldCheckScroll ) {
			this.scrollHandler = throttle(this.checkWindowScroll, this.props.throttle);
			this.resizeHandler = throttle(this.checkWindowScroll, this.props.throttle);

			this.target = this.getScrollTarget();
			this.target?.addEventListener('scroll', this.scrollHandler);
			this.target?.addEventListener('resize', this.resizeHandler);
		}
	}

	componentWillUnmount(): void {
		if ( this.props.shouldCheckScroll ) {
			this.target?.removeEventListener('scroll', this.scrollHandler);
			this.target?.removeEventListener('resize', this.resizeHandler);
		}
	}

	checkWindowScroll: void => void = () => {
		if ( this.props.isLoading ) return;
		if (
			this.props.hasMore &&
			Number(this.sentinel?.getBoundingClientRect()?.top) - window.innerHeight <
			Number(this.props.threshold)
		) {
			this.props.onLoadMore?.();
		}
	};

	render(): React$Node {
		return (
			<div className="ely-infinite-scroll">
				<div className="ely-infinite-scroll__inner">
					{this.props.children}
				</div>
				<div
					ref={i => this.sentinel = i}
					className="ely-infinite-scroll__sentinel"
				/>
			</div>
		);
	}
}

export default InfiniteScroll;
