// @flow
import type { IdTokenProps } from 'auth/types';
import { createContext } from 'react';

export const InitialIdToken: IdTokenProps = {
	initialized: false,
	user: null,
	account: null
};

const IdTokenContext: React$Context<IdTokenProps> = createContext(InitialIdToken);
export default IdTokenContext;
