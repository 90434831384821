import { BASE_URL } from 'utils/variables/env';
import logo from "img/logo-experly.svg";

const METADATA = {
	"/": {
		title: "Experly | Dive into a world of unique featured",
		description: "Experly | Dive into a world of unique featured",
		image: logo,
		imageWidth: 175,
		imageHeight: 46,
		url: BASE_URL
	},
	"/auth/login": {
		title: "Login | Experly",
		description: "Login",
		image: `${BASE_URL}/static/media/logo-experly.37b07a39.svg`,
		imageWidth: 175,
		imageHeight: 46,
		url: `${BASE_URL}/auth/login`
	},
	"/auth/register/user": {
		title: "Register | Experly",
		description: "Register",
		image: `${BASE_URL}/static/media/logo-experly.37b07a39.svg`,
		imageWidth: 175,
		imageHeight: 46,
		url: `${BASE_URL}/auth/register/user`
	},
	"/auth/register/partner": {
		title: "Register as Partner | Experly",
		description: "Register as an Experly partner and manage your suppliers and services efficiently",
		image: `${BASE_URL}/static/media/logo-experly.37b07a39.svg`,
		imageWidth: 175,
		imageHeight: 46,
		url: `${BASE_URL}/auth/register/partner`
	},
	"/auth/register/supplier": {
		title: "Register as Supplier | Experly",
		description: "Register as an Experly supplier and gain global audience visibility",
		image: `${BASE_URL}/static/media/logo-experly.37b07a39.svg`,
		imageWidth: 175,
		imageHeight: 46,
		url: `${BASE_URL}/auth/register/supplier`
	},
	"/for-business": {
		title: "For Business | Experly",
		description: "For business",
		image: `${BASE_URL}/static/media/logo-experly.37b07a39.svg`,
		imageWidth: 175,
		imageHeight: 46,
		url: `${BASE_URL}/for-business`
	},
	"/about": {
		title: "About | Experly",
		description: "About",
		image: `${BASE_URL}/static/media/logo-experly.37b07a39.svg`,
		imageWidth: 175,
		imageHeight: 46,
		url: `${BASE_URL}/about`
	},
	"/privacy": {
		title: "Privacy Policy | Experly",
		description: "Privacy policy",
		image: `${BASE_URL}/static/media/logo-experly.37b07a39.svg`,
		imageWidth: 175,
		imageHeight: 46,
		url: `${BASE_URL}/privacy`
	},
	"/contribute": {
		title: "Contribute | Experly",
		description: "Contribute in Experly and get rewarded",
		image: `${BASE_URL}/static/media/logo-experly.37b07a39.svg`,
		imageWidth: 175,
		imageHeight: 46,
		url: `${BASE_URL}/contribute`
	},
	"/newsletter/confirmation": {
		title: "Confirm Subscription | Experly",
		description: "Confirm your email for newsletter subscription",
		image: `${BASE_URL}/static/media/logo-experly.37b07a39.svg`,
		imageWidth: 175,
		imageHeight: 46,
		url: `${BASE_URL}/newsletter/confirmation`
	},
	"/favourites": {
		title: "My Favourites | Experly",
		description: "My favourite featured",
		image: `${BASE_URL}/static/media/logo-experly.37b07a39.svg`,
		imageWidth: 175,
		imageHeight: 46,
		url: `${BASE_URL}/favourites`
	}
};

export default METADATA;