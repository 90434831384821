// @flow
import { Link } from 'react-router-dom';
import ConditionalWrapper from "components/general/wrapper/conditional-wrapper";

type ConditionalLinkWrapperProps = {
	to?: string | Object,
	className?: string,
	children: React$Node,
}

const ConditionalLinkWrapper = ({ to, className, children, ...other }: ConditionalLinkWrapperProps) => (
	<ConditionalWrapper
		condition={!!to}
		trueWrapper={children => <Link to={to} className={className} {...other}>{children}</Link>}
		falseWrapper={children => <div className={className} {...other}>{children}</div>}
	>
		{children}
	</ConditionalWrapper>
);

export default ConditionalLinkWrapper;