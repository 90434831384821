// @flow
type ModalTriggerProps = {
	label: string,
	className?: string,
	onOpen: void => void
};

const ModalTrigger = ({ label, className, onOpen }: ModalTriggerProps) => (
	<button
		type="button"
		onClick={onOpen}
		className={`ely-modal__trigger ${className || ''}`}
	>
		{label}
	</button>
);

export default ModalTrigger;