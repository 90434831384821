// @flow
import type { RoleProps } from 'auth/types';
import { useState, useMemo, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { Roles } from 'auth/index';
import { Formik, Form } from 'formik';
import ReCAPTCHA from 'react-google-recaptcha';
import FormHelperText from '@mui/material/FormHelperText/FormHelperText';
import ElyLink from 'components/general/link/ely-link';
import { Button, LinkButton } from 'components/general/button';
import { ElyTextField, ElyPasswordField, ElyCheckboxField } from 'components/general/input/fields';
import useSocialLogin from 'components/auth/social/useSocialLogin';
import SocialButtonFacebook from 'components/auth/social/social-button-facebook';
import SocialButtonTwitter from 'components/auth/social/social-button-twitter';
import SocialButtonGoogle from 'components/auth/social/social-button-google';
import 'scss/components/auth/register/registration-form.scss';

type Props = {
	role: RoleProps,
	onSubmit: Object => Promise<any>,
	onLogin: void => void,
	t: string => string,
};

const InitialValues = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    repeatPassword: '',
    acceptTerms: false,
    reCaptcha: false
};

const RegistrationForm = ({ role, onSubmit, onLogin, t }: Props): React$Node => {
	const location = useLocation();
    const [error, setError] = useState(undefined);
    const { onFbLogin, onTwitterToken, onGoogleLogin } = useSocialLogin(role, location);
    const roleLocal = useMemo(() => role?.replace("role.", ""), [role]);

    const _onSubmit = useCallback(values => {
		onSubmit(values)
			.catch(err => {
				console.log(err);
				setError(err.response.status);
			});
    }, [onSubmit]);

    return (
        <Formik
            initialValues={{...InitialValues, role}}
            validate={validate}
            onSubmit={_onSubmit}
        >
            {
                ({ errors, setFieldValue }) => (
                    <Form className="RegistrationForm">
                        {
                            role !== Roles.USER &&
                            <>
                                <ElyTextField
                                    required={true}
                                    name="firstName"
                                    label="First Name"
                                />
                                <ElyTextField
                                    required={true}
                                    name="lastName"
                                    label="Last Name"
                                />
                            </>
                        }
                        <ElyTextField
                            required={true}
                            name="email"
                            label="Email"
                        />
                        <ElyPasswordField
                            name="password"
                            label="Password"
                        />
                        <ElyPasswordField
                            name="repeatPassword"
                            label="Repeat Password"
                        />
                        <div className="RegistrationForm__accept">
                            <ElyCheckboxField
                                id="acceptTerms"
                                name="acceptTerms"
                                label={(
                                    <label htmlFor="acceptTerms">
                                        I accept the <ElyLink className="terms-link" target="_blank" to={`/legal/${roleLocal}/terms`}>Terms and Conditions</ElyLink>
                                    </label>
                                )}
                                onChange={e => setFieldValue("acceptTerms", e.currentTarget.checked)}
                            />
                        </div>
                        <div className="RegistrationForm__captcha">
                            <ReCAPTCHA
                                onChange={value => setFieldValue("reCaptcha", value)}
                                sitekey="6LdPRnQUAAAAAJr-BJzIePOJoA7z2_N7QqaXuCLn"
                            />
                            { !!errors["reCaptcha"] && <FormHelperText className="u-color-problem">{errors["reCaptcha"]}</FormHelperText> }
                        </div>
                        <div className="RegistrationForm__submit">
                            {
                                error &&
                                <span className="RegistrationForm__submit__error">{t(`registration.error.${error}`)}</span>
                            }
                            <Button type="submit" style={{width:'100%'}}>{t('registration.credentials.submit')}</Button>
                        </div>
	                    <div className="RegistrationForm__join-with-social">
		                    <span> Or you can join with</span>
	                    </div>
                        <div className="RegistrationForm__social">
                            <div className="RegistrationForm__social__item RegistrationForm__social__item--facebook">Login with
                                <SocialButtonFacebook
                                    scope='email'
                                    onConnect={onFbLogin}
                                />
                            </div>
                            <div className="RegistrationForm__social__item RegistrationForm__social__item--twitter">Login with
                                <SocialButtonTwitter
                                    role={role}
                                    onConnect={onTwitterToken}
                                />
                            </div>
                            <div className="RegistrationForm__social__item RegistrationForm__social__item--google">Login with
                                <SocialButtonGoogle
	                                onClick={onGoogleLogin}
                                />
							</div>
                        </div>
                        <div className="RegistrationForm__login">
                            <span>Already have an account? <LinkButton onClick={onLogin}>Login</LinkButton></span>
                        </div>
                    </Form>
                )
            }
        </Formik>
    );
}

function validate(values) {
    const errors = {};

    if ( values.role !== Roles.USER ) {
        if (!values.firstName) {
            errors.firstName = 'First Name is required!';
        } else if ( values.firstName.length < 2 ) {
            errors.firstName = 'First Name should be at least 2 characters';
        }
        if (!values.lastName) {
            errors.lastName = 'Last Name is required!';
        } else if ( values.lastName.length < 2 ) {
            errors.lastName = 'Last Name should be at least 2 characters';
        }
    }

    if (!values.email) {
        errors.email = 'Email is required!';
    }
    // eslint-disable-next-line
    else if ( !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(values.email) ) {
        errors.email = 'Invalid email address!';
    }
    if (!values.password) {
        errors.password = 'Enter Password!';
    } else if ( values.password.length < 8 ) {
        errors.password = 'Password must be at least 8 characters!';
    }
    if (!values.repeatPassword) {
        errors.repeatPassword = 'Enter Password again!';
    }
    if ( values.password && values.repeatPassword && values.password !== values.repeatPassword ) {
        errors.repeatPassword = 'Passwords do not match!';
    }
    if (!values.acceptTerms) {
        errors.acceptTerms = 'You must read and accept the Terms and Conditions!';
    }
    if (!values.reCaptcha) {
        errors.reCaptcha = 'Please verify that you are not a robot!';
    }
    return errors;
}

export default RegistrationForm;
