import { ElyTimePicker } from 'components/general/input';

const FormikTimePicker = ({ field, form:{setFieldValue, setFieldTouched}, ...props}) => (
	<ElyTimePicker
		{...field}
		{...props}
		onChange={time => {
			setFieldValue(field.name, time);
			setFieldTouched(field.name, true);
		}}
		onClose={() => setFieldTouched(field.name, true)}
	/>
);

export default FormikTimePicker;