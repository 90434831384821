import { useCallback } from 'react';
import isEmpty from 'lodash/isEmpty';
import { arrayMove } from '@dnd-kit/sortable';
import { Backend, del } from 'actions/backend';
import MediaType from 'utils/variables/media-type';
import useImageApi from 'hooks/general/image/use-image-api';
import { notificationService } from 'components/general/notification';
import confirm from 'components/general/modal/confirm';

export default function useImageDrop(owner, { overlays = [], override = false, ajaxOnSort = true, ajaxOnDelete = true }) {
	const { onUpload, onSort } = useImageApi();

	const showErrorMsg = useCallback(msg => {
		notificationService.show({
			level: "warning",
			messages: [{code: 'error', message: msg}]
		});
	}, []);

	//
	// On Image Drop
	//
	const onImageDrop = useCallback((files, rejectedFiles, config) => {

		if ( !isEmpty(rejectedFiles) ) {
			showErrorMsg('Only jpeg and png file types supported');
			return;
		}

		const filesData = [];
		let size = config?.count ?? 0;
		files.forEach((f, index) => {
			const currentIndex = size + index;
			filesData.push({
				file: f,
				owner,
				mediaType: overlays?.[currentIndex]?.code ?? MediaType.IMAGE,
				order: currentIndex,
				override
			})
			size++;
		});

		return Promise.all(onUpload(filesData))
			.then(res => ({ data: res.flatMap(r => r.data) }));
	}, [owner, overlays, override, showErrorMsg, onUpload]);

	//
	// On Image Sort
	//
	const onImageSort = useCallback((images, callback) => ({oldIndex, newIndex}) => {
		if ( oldIndex === newIndex ) return;
		const photosSorted = arrayMove(images, oldIndex, newIndex)
			.map((image, index) => ({
				...image,
				order: index,
				mediaType: overlays?.[index]?.code ?? MediaType.IMAGE,
			}));

		(ajaxOnSort ? onSort(photosSorted) : Promise.resolve())
			.then(() => callback?.(photosSorted));
	}, [overlays, ajaxOnSort, onSort]);

	//
	// On Image Delete
	//
	const onImageDelete = useCallback((index, key) => {
		return confirm({message: 'Are you sure you want to delete this photo?'})
			.then(() => ajaxOnDelete && del(Backend.MEDIA, key))
			.then(() => index);
	}, [ajaxOnDelete]);

	return [onImageDrop, onImageSort, onImageDelete];
}