// @flow
import { Button, LinkButton } from 'components/general/button';

type GoogleLoginProps = {
    variant?: string,
    className?: string,
    onClick: void => void,
    children?: React$Node
}

const SocialButtonGoogle = ({ variant = "link", className, onClick, children }: GoogleLoginProps) => (
    <>
        {
            variant === "link" &&
            <LinkButton className={className} onClick={onClick}>
                {children || 'Google'}
            </LinkButton>
        }
        {
            variant === "button" &&
            <Button className={className} onClick={onClick}>
                {children || 'Google'}
            </Button>
        }
    </>
);

export default SocialButtonGoogle;