import { useState, useCallback } from 'react';
import { notificationService } from 'components/general/notification';
import { Button } from 'components/general/button';

import "scss/components/general/cookie-consent.scss";

const CookieConsent = ({ onCookieSubmit }) => {
    const [sendAnalytics, setSendAnalytics] = useState(false);
    const onSubmit = useCallback(() => {
        onCookieSubmit(!sendAnalytics);
        notificationService.hide();
    }, [sendAnalytics, onCookieSubmit]);

    return (
        <div className="cookie-consent">
            <p>Experly uses cookies, which help us deliver our services. For more details, see our Cookie policy. You can
                enable and disable cookies by selecting the options below.</p>
            <ul>
                <li>
                    <input
                        id="mandatoryCookies"
                        name="mandatoryCookies"
                        type="checkbox"
                        checked={true}
                        disabled={true}
                    />
                    <label htmlFor="mandatoryCookies">Mandatory Cookies</label>
                </li>
                <li>
                    <input
                        id="analyticsCookies"
                        name="analyticsCookies"
                        type="checkbox"
                        checked={sendAnalytics}
                        onChange={() => setSendAnalytics(state => !state)}
                    />
                    <label htmlFor="analyticsCookies">Analytics</label>
                </li>
            </ul>
            <Button onClick={onSubmit}>Ok, I agree</Button>
        </div>
    );
};

export default CookieConsent;