const ThirdParty = {
	TWITTER: { code: "TWITTER", label: "Twitter", url: "https://www.twitter.com" },
	FACEBOOK: { code: "FACEBOOK", label: "Facebook", url: "https://facebook.com" },
	INSTAGRAM: { code: "INSTAGRAM", label: "Instagram", url: "https://www.instagram.com" }
};

const THIRD_PARTIES = [
	ThirdParty.TWITTER,
	ThirdParty.FACEBOOK,
	ThirdParty.INSTAGRAM
];

/**
 * REGEXs from https://github.com/lorey/social-media-profiles-regexs
 *
 * @param thirdParty ThirdParty code for which to find regex
 * @returns {RegExp} The regular expresion found for {@param thirdParty}
 */
function getThirdPartyRegex(thirdParty) {
	if ( thirdParty === ThirdParty.FACEBOOK.code )
		return /(?:https?:)?\/\/(?:www\.)?(?:facebook|fb)\.com\/(?<profile>(?![A-z]+\.php)(?!marketplace|gaming|watch|me|messages|help|search|groups)[A-z0-9_\-.]+)\/?/i;
	if ( thirdParty === ThirdParty.INSTAGRAM.code )
		return /(?:https?:)?\/\/(?:www\.)?(?:instagram\.com|instagr\.am)\/(?<username>[A-Za-z0-9_](?:(?:[A-Za-z0-9_]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_]))\/?)/i;
	if ( thirdParty === ThirdParty.TWITTER.code )
		return /(?:https?:)?\/\/(?:[A-z]+\.)?twitter\.com\/@?(?<username>[A-z0-9_]+)\/?/i;
}

export { ThirdParty, THIRD_PARTIES, getThirdPartyRegex };