// @flow
import { Link } from 'react-router-dom';
import Icon from 'components/general/icon';
import 'scss/components/layout/main/layout-main-commons.scss';

//
// Layout main profile divider
//
export const LayoutMainProfileDivider = (): React$Node => (
	<div className="LayoutMainProfileDivider" />
)

//
// Profile main profile title
//
type LayoutMainProfileTitleProps = {
	children: React$Node
};
export const LayoutMainProfileTitle = ({ children }: LayoutMainProfileTitleProps): React$Node => (
	<h3 className="LayoutMainProfileTitle">{children}</h3>
);

//
// Layout main profile item
//
type LayoutMainProfileItemProps = {
	icon?: string,
	title: string,
	to?: string,
	onClick?: void => void,
	state?: Object,
	className?: string,
};
export const LayoutMainProfileItem = ({ icon, title, to, onClick, state, className = '' }: LayoutMainProfileItemProps): React$Node => {
	return (
		<div className={`LayoutMainProfileItem ${className}`}>
			{
				!!to && (
					<Link to={to} className="LayoutMainProfileItem__link" state={state}>
						{ icon && <Icon code={icon}/> }
						<span className="LayoutMainProfileItem__title">{title}</span>
					</Link>
				)
			}
			{
				!!onClick && (
					<button type="button" onClick={onClick} className="LayoutMainProfileItem__button">
						{ icon && <Icon code={icon} /> }
						<span className="LayoutMainProfileItem__title">{title}</span>
					</button>
				)
			}
		</div>
	);
}

//
// Layout main profile social item
//
type LayoutMainProfileSocialItemProps = {
	social: string,
	href: string,
};
export const LayoutMainProfileSocialItem = ({ social, href }: LayoutMainProfileSocialItemProps): React$Node => (
	<li className="LayoutMainProfileSocialItem">
		<a
			target="_blank"
			rel="noopener noreferrer"
			href={href}
			className="LayoutMainProfileSocialItem__link"
		>
			<Icon code={social} />{social}
		</a>
	</li>
);