// @flow
import { useState, useCallback } from 'react';
import useI18n from 'hooks/i18n/useI18n';
import { Formik, Form } from 'formik';
import { Backend, post } from 'actions/backend';
import { Button } from 'components/general/button';
import AuthLayout from 'components/auth/auth-layout';
import { ElyTextField } from 'components/general/input/fields';
import { useAuthModal } from 'components/auth/auth-modal-context';
import 'scss/components/auth/login/forgot-password.scss';

type Props = {
	isModal?: boolean,
};

const ForgotPassword = ({ isModal = true }: Props): React$Node => {
    const { t } = useI18n();
    const [error, setError] = useState(undefined);
    const [reset, setReset] = useState(false);
	const { closeAuth } = useAuthModal();

    const onSubmit = useCallback(values => {
        post(Backend.OAUTH_FORGOT_PASSWORD, undefined, { params: values.email, showErrors: false })
            .then(() => setReset(true))
            .catch(err => setError(err.response.status));
    }, []);

    return (
        <AuthLayout
	        title={t('forgot.title')}
	        subtitle={t('forgot.subtitle')}
	        description={t('forgot.description')}
	        onClose={isModal ? closeAuth : undefined}
        >
            {
                !reset &&
                <Formik
                    initialValues={{ email: "" }}
                    validate={validate}
                    onSubmit={onSubmit}
                >
                    {
                        () => (
                            <Form className="ForgotPassword">
                                <ElyTextField
                                    name="email"
                                    label="Enter your email"
                                    required={true}
                                />

	                            {
		                            error &&
		                            <div className="ForgotPassword__error">
			                            <span>{t(`forgot.error.${error}`)}</span>
		                            </div>
	                            }

	                            <Button type="submit" color="primary" className="ForgotPassword__submit">Reset Password</Button>
                            </Form>
                        )
                    }
                </Formik>
            }
            {
                reset &&
                <div className="login-box">
                    <p className="login-box__info">{t('forgot.success')}</p>
                </div>
            }
        </AuthLayout>
    );
};

function validate(values) {
    const errors = {};

    if (!values.email) {
        errors.email = "Please enter your email!";
    }

    return errors;
}

export default ForgotPassword;
