// @flow
import "scss/components/public/general/generic-not-found.scss";

const GenericNotFound = (): React$Node => (
	<div className="generic-not-found">
		<h1>404 Not Found.</h1>
		<p>
			<a className="go-back" href="/">Go back</a> to the main site.
		</p>
	</div>
);

export default GenericNotFound;
