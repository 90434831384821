// @flow
import useI18n from 'hooks/i18n/useI18n';
import { Button } from 'components/general/button';
import Emote from 'components/general/emote';
import ConditionalLinkWrapper from 'components/general/wrapper/conditional-link-wrapper';
import "scss/components/public/user/public-user-teaser.scss";

export type PublicUserTeaserProps = {|
	isMe: boolean,
	image: string,
	name: string,
	path: string,
	state?: Object,
	feel: ?Object,
	isFriend: ?Object,
	addFriend: void => void,
	removeFriend: void => void,
	acceptFriend: void => void,
	ignoreFriend: void => void,
	size?: 'default' | 'small',
	className?: string,
|}

const PublicUserTeaser = ({
	isAuthenticated,
	isMe,
	image,
	name,
	feel,
	path,
	state,
	isFriend,
	addFriend,
	removeFriend,
	acceptFriend,
	ignoreFriend,
	size = 'default',
	className = '',
}: PublicUserTeaserProps): React$Node => {
	const { t } = useI18n();
	return (
		<div className={`profile-tab-friends-item ${size} ${className}`}>
			<div className="profile-tab-friends-item__left">
				<ConditionalLinkWrapper to={path} state={state} className="friend-photo">
					<img className="friend-photo__img" src={image} alt={name} />
					<Emote
						code={feel?.tag?.title?.toLowerCase()}
						title={feel?.tag?.title}
						className="friend-photo__emote"
					/>
				</ConditionalLinkWrapper>
				<div className="friend-info">
					<ConditionalLinkWrapper to={path} state={state} className="friend-info__name">
						<span>{name}</span>
					</ConditionalLinkWrapper>
					{ feel?.status && <span className="friend-info__status"><q>{`${feel.status}`}</q></span> }
				</div>
			</div>

			{
				isAuthenticated && (
					<div className="profile-tab-friends-item__actions">
						{
							!isMe && !isFriend && addFriend &&
							<Button styling={["round", "small"]} onClick={addFriend} className="action-btn">{t('profile.user.add.friend')}</Button>
						}
						{
							!isMe && isFriend && isFriend.active &&
							<Button color="hollow" styling={["round", "small"]} onClick={removeFriend} className="action-btn">{t('profile.user.remove.friend')}</Button>
						}
						{
							!isMe && isFriend && !isFriend.active && isFriend.isOutbound &&
							<Button color="hollow" styling={["round", "small"]} onClick={removeFriend} className="action-btn">{t('profile.user.cancel.friend')}</Button>
						}
						{
							!isMe && isFriend && !isFriend.active && !isFriend.isOutbound &&
							<>
								<Button color="hollow" styling={["round", "small"]} onClick={acceptFriend}>{t('profile.user.accept.friend')}</Button>
								<Button
									color="hollow"
									styling={["round", "small"]}
									onClick={ignoreFriend}
									className="profile-tab-friends-item__actions__ignore action-btn"
								>
									{t('profile.user.ignore.friend')}
								</Button>
							</>
						}
					</div>
				)
			}
		</div>
	);
}

export default PublicUserTeaser;