// @flow
import { useEffect, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Backend, get } from 'actions/backend';
import usePeopleReducer, { LIMIT } from 'hooks/public/people/usePeopleReducer';
import usePeopleSocialActions from 'hooks/public/people/usePeopleSocialActions';
import ProfilePeopleSearch from 'components/public/profile/people/profile-people-search';

const ProfilePeopleSearchWrapper = (): React$Node => {
	const [searchParams, setSearchParams] = useSearchParams();
	const { users, initUsers, loadUsers, updateUsers } = usePeopleReducer();
	const { addFriend, removeFriend, acceptFriend, ignoreFriend } = usePeopleSocialActions(users.data, users => updateUsers(users));

	//
	// Make the search call to backend
	//
	const doSearch = useCallback((query, offset) => {
		get(Backend.SEARCH_PEOPLE_OLD, { query, offset, limit:LIMIT })
			.then(res => {
				if ( offset === 0 ) initUsers(res.data);
				else loadUsers(res.data);
				// setUsers(new Map(transformFriends(res.data.content).map(d => [d._key, d])))
			});
	}, [initUsers, loadUsers]);

	const doSearchRecommended = useCallback((offset) => {
		get(Backend.SEARCH_PEOPLE_RECOMMENDED, { offset, limit:LIMIT })
			.then(res => {
				if ( offset === 0 ) initUsers(res.data);
				else loadUsers(res.data);
			})
	}, [initUsers, loadUsers]);

	const onLoadMore = useCallback(() => {
		const q = searchParams.get('q');
		if ( q ) doSearch(q, users.offset);
		else doSearchRecommended(users.offset);
	}, [searchParams, users.offset, doSearch, doSearchRecommended]);

	//
	// On search click update url query
	//
	const onSearch = useCallback(values => {
		const q = searchParams.get('q');
		if ( !q && !values.search ) return;
		if ( q === values.search ) return;
		setSearchParams({ q: values.search })
	}, [searchParams, setSearchParams]);

	//
	// Fire doSearch on url query change
	//
	useEffect(() => {
		const q = searchParams.get('q');
		if ( q ) doSearch(q, 0);
		else doSearchRecommended(0);
	}, [searchParams, doSearch, doSearchRecommended]);

	return (
		<ProfilePeopleSearch
			users={users.data}
			hasMore={users.hasMore}
			onSearch={onSearch}
			onLoadMore={onLoadMore}
			addFriend={addFriend}
			removeFriend={removeFriend}
			acceptFriend={acceptFriend}
			ignoreFriend={ignoreFriend}
		/>
	)
}

export default ProfilePeopleSearchWrapper;
