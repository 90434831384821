// @flow
import type { NodeTypeAliasProps } from 'types/general/commons';
import type { LocationProps } from 'types/general/location-props';
import useI18n from 'hooks/i18n/useI18n';
import Tag from 'components/general/tag';
import ConditionalLinkWrapper from 'components/general/wrapper/conditional-link-wrapper';
import 'scss/components/public/node/node-teaser.scss';

export const NodeTeaserVariant = {
	PRIMARY: 'PRIMARY',
	SECONDARY: 'SECONDARY',
	SECONDARY_NO_BORDER: 'SECONDARY-NO-BORDER',
};
type NodeTeaserVariantProps = $Keys<typeof NodeTeaserVariant>;

type Props = {
	nodeTypeAlias?: NodeTypeAliasProps,
	image: string,
	title: string,
	location: LocationProps,
	to?: string | Object,
	state?: Object,
	target?: string,
	variant?: NodeTeaserVariantProps,
	className?: string
};

const NodeTeaser = ({
	nodeTypeAlias,
	image,
	title,
	location,
	to,
	state,
	target,
	variant = NodeTeaserVariant.PRIMARY,
	className = ''
}: Props): React$Node => {
	const { t } = useI18n();
	const variantClass = `NodeTeaser--${variant.toLowerCase()}`;
	return (
		<ConditionalLinkWrapper
			to={to}
			state={state}
			target={target}
			className={`NodeTeaser ${variantClass} ${className ?? ''}`}
		>
			<img
				src={image}
				alt={title}
				className="NodeTeaser__img"
			/>
			<div className="NodeTeaser__details">
				<span className="NodeTeaser__details__title">
					{title}
				</span>
				{
					location &&
					<span className="NodeTeaser__details__location">
						{`${location.city}, ${location.country}`}
					</span>
				}
				{
					nodeTypeAlias &&
					<Tag
						type={nodeTypeAlias.toLowerCase()}
						title={t(nodeTypeAlias)}
						variant="primary"
					/>
				}
			</div>
		</ConditionalLinkWrapper>
	);
};

export default NodeTeaser;
