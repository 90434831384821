// @flow
import type { RoleProps, PermissionProps, AuthProps, JwtProps, IdTokenProps, AccessTokenApiProps, IdTokenApiProps, AuthApiProps } from 'auth/types';
import { decodeJwt, getDominantRole, getRolePath, isExpired } from 'auth/JwtUtils';
import AuthService from 'auth/AuthService';
import { transformPhoto } from 'utils/transform';
import { getUserImagePlaceholder } from 'utils/transform/transform-media';

function transformAccessToken(access_token: AccessTokenApiProps): JwtProps {
	const accessDecoded = decodeJwt(access_token);
	const dominantRole = getDominantRole(accessDecoded.authorities);
	return {
		isAuthenticated: !!access_token,
		accessToken: access_token,
		expiration: accessDecoded.exp,
		authorities: accessDecoded.authorities,
		permissions: accessDecoded.permissions,
		dominantRole,
		rolePath: getRolePath(dominantRole),
	};
}

function transformIdToken(id_token): IdTokenProps {
	return {
		user: {
			...id_token.user,
			photo: id_token.user.photo
				? transformPhoto({ filename: id_token.user.photo }, "profile")
				: getUserImagePlaceholder('profile')
		},
		account: id_token.account,
	};
}

export default class AuthUtils {
	static service: AuthService;
	static refreshPromise: Promise;
	static load = (authApi: AuthApiProps, config): AuthProps => {
		if ( !authApi ) return undefined;

		const jwt = transformAccessToken(authApi.access_token);
		const idToken = transformIdToken(authApi.id_token);
		const auth = { jwt, idToken };

		AuthUtils.service.load(auth, config);
	}
	static loadIdToken = (id_token: IdTokenApiProps): void => {
		const idToken = transformIdToken(id_token);
		AuthUtils.service.setIdToken(idToken);
	}
	static logout = (notifyStorage:boolean = true) => AuthUtils.service.logout(notifyStorage);
	static isTokenExpired = () => isExpired(AuthUtils.service?.expiration);
	static checkRoles = (authorities: Array<RoleProps>, roles: Array<RoleProps>): boolean => {
		return roles?.filter(role => authorities?.includes(role)).length > 0;
	}
	static hasPermission = (userPermissions: Array<PermissionProps>, permissionToCheck: PermissionProps) => {
		return userPermissions?.filter(p => p === permissionToCheck)?.length > 0;
	}
}