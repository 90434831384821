// @flow
import { useCallback, useEffect, useState } from 'react';
import Polyglot from 'node-polyglot';
import { locales } from 'context/i18n-context';

type ReturnProps = {
	i18n: {
		locale: string,
		t: string => string,
		setLocale: string => void,
	},
	i18nLoaded: boolean,
}

export default function useInitializeLocale(): ReturnProps {
	const [i18n, setI18n] = useState(undefined);
	const [i18nLoaded, setI18nLoaded] = useState(false);

	const loadLocale = useCallback(locale => {
		console.log("Loading locale: " + locale);
		import("../../i18n/" + locale + ".js").then(i18n => {

			const polyglot = new Polyglot({locale: locale, phrases: i18n.default});
			const t = polyglot.t.bind(polyglot);

			setI18n({locale, t, setLocale: loadLocale});
			setI18nLoaded(true);
		});
	}, []);

	useEffect(() => {
		loadLocale(locales.en);
	}, [loadLocale]);

	return {
		i18n,
		i18nLoaded,
	};
}