// @flow
import axios from 'axios';
import qs from 'query-string';
import Globals from 'utils/variables/globals';
import { GRANT_TYPE_PASSWORD, GRANT_TYPE_REFRESH_TOKEN, SCOPE_TOKEN_IDTOKEN } from 'utils/variables';
import type { AuthProps } from 'auth/types';
import AuthUtils from 'auth/AuthUtils';
import { Backend, post } from 'actions/backend';

//
// Registration
//
export function registerConfirmation(token: string): Promise<AuthProps> {
    return post(Backend.REGISTER_CONFIRM, undefined, { params: token })
        .then(res => AuthUtils.load(res.data));
}

//
// Authentication
//
export function login(username: string, password: string): Promise<AuthProps> {
    const data = qs.stringify({
        grant_type: GRANT_TYPE_PASSWORD,
        client_id: Globals.OAuthClientId,
        client_secret: Globals.OAuthClientSecret,
        scope: SCOPE_TOKEN_IDTOKEN,
        username, password
    });
    const config = {
        headers: {'Content-Type': 'application/x-www-form-urlencoded'}
    };
    return post(Backend.LOGIN, data, config)
        .then(res => AuthUtils.load(res.data));
}

export function loginRefresh(shouldLoadId: boolean): Promise<any> {
    const data = qs.stringify({
        grant_type: GRANT_TYPE_REFRESH_TOKEN,
        client_id: Globals.OAuthClientId,
        client_secret: Globals.OAuthClientSecret,
        scope: SCOPE_TOKEN_IDTOKEN
    });
    const config = {
        headers: {'Content-Type': 'application/x-www-form-urlencoded'}
    };
    return axios.post(Backend.LOGIN.url, data, config)
        .then(res => AuthUtils.load(res.data, { shouldLoadId }))
        .catch(() => AuthUtils.logout());
}

export function logout(): Promise<any> {
    // First logout locally to remove bearer token from axios headers
    AuthUtils.logout()
    return post(Backend.LOGOUT);
}

//
// Social Authentication
//
export function twitterSignIn(data: Object): AuthProps {
    return post(Backend.OAUTH_TWITTER, data, { params: "connect" })
        .then(res => AuthUtils.load(res.data));
}
export function facebookSingIn(role: string, fbAccessToken: string): AuthProps {
    return post(Backend.OAUTH_FACEBOOK, { role, fbAccessToken }, { params: "connect"})
        .then(res => AuthUtils.load(res.data));
}
export function googleSignIn(role: string, authenticationCode: string): AuthProps {
    return post(Backend.OAUTH_GOOGLE, { role, authenticationCode }, { params: "connect" })
        .then(res => AuthUtils.load(res.data));
}
