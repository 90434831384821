// @flow
import type { UserProps } from 'types/user-props';
import { Formik, Form } from 'formik';
import { Button } from 'components/general/button';
import SettingsCard from 'components/general/account/settings/settings-card';
import BasicInformation from 'components/general/account/settings/basic-information';
import PersonalInterests from 'components/general/account/settings/personal-interests';
import SocialLinks from 'components/general/account/settings/social-links';
import 'scss/components/general/account/settings/profile-edit.scss';

type Props = {
	user: UserProps,
	interestOptions: Array<Object>,
	onSubmit: Object => void,
	onImageDrop: any => (Array<Object>, Array<Object>, Object) => Array<Promise<any>>,
	onImageDelete: string => Promise<any>
};

const ProfileEdit = ({ user, interestOptions, onSubmit, onImageDrop, onImageDelete }: Props): React$Node => {
	return (
		<Formik
			initialValues={user}
			validate={validate}
			onSubmit={onSubmit}
			enableReinitialize={true}
		>
			{
				({ values, setFieldValue }) => (
					<Form className="profile-settings__form">
						<SettingsCard>
							<BasicInformation
								photos={values?.photos}
								onImageDrop={onImageDrop(setFieldValue)}
								onImageDelete={onImageDelete}
							/>
						</SettingsCard>
						<SettingsCard>
							<PersonalInterests
								interests={values.interests ?? []}
								interestOptions={interestOptions}
								setFieldValue={setFieldValue}
							/>
						</SettingsCard>
						<SettingsCard>
							<SocialLinks
								values={values.socialLinks}
								setFieldValue={setFieldValue}
							/>
						</SettingsCard>
						<div className="profile-settings__buttons">
							<Button type="submit" className="profile-settings__buttons__submit">Save</Button>
						</div>
					</Form>
				)
			}
		</Formik>
	);
};

function validate(values) {
	const errors = {};

	if ( values.firstName && values.firstName.length < 2 ) {
		errors.firstName = 'First Name should be at least 2 characters';
	}
	if ( values.lastName && values.lastName.length < 2 ) {
		errors.lastName = 'Last Name should be at least 2 characters';
	}
	if (!values.email) {
		errors.email = 'Email is required!';
	} else if ( !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(values.email) ) {
		errors.email = 'Invalid email address!';
	}

	return errors;
}

export default ProfileEdit;
