import { useState, useEffect, useCallback } from 'react';
import { Backend, get, post } from 'actions/backend';
import PasswordChange from 'components/general/account/settings/password-change';

const PasswordChangeWrapper = () => {
	const [isNativeLogin, setNativeLogin] = useState(undefined);

	useEffect(() => {
		get(Backend.PROFILE_SETTINGS_NATIVE_LOGIN)
			.then(res => setNativeLogin(!!res.data?.nativeLogin));
	}, []);

	const onChangePassword = useCallback(values => {
		return post(Backend.OAUTH_CHANGE_PASSWORD, values)
			.then(res => setNativeLogin(true));
	}, []);

	if ( isNativeLogin === undefined ) return null;
	return (
		<PasswordChange
			hasPassword={isNativeLogin}
			onChangePassword={onChangePassword}
		/>
	)
};

export default PasswordChangeWrapper;