// @flow
import { useState, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AuthUtils, Roles } from 'auth';
import { routes } from 'utils/routes';
import useI18n from 'hooks/i18n/useI18n';
import { Backend, post } from 'actions/backend';
import { useAuthModal } from 'components/auth/auth-modal-context';
import HeaderButtonClose from 'components/layout/header-button-close';
import JoinBusinessRole from 'components/auth/join/join-business-role';
import JoinBusinessTutorial from 'components/auth/join/join-business-tutorial';
import JoinBusinessSupplierPartner from 'components/auth/join/join-business-supplier-partner';
import experlyLogo from 'img/logo-experly.svg';
import 'scss/components/auth/join/join-business-wizard.scss';

type Props = {
  isRegister: boolean,
};

const JoinBusinessWizard = ({ isRegister }: Props): React$Node => {
	const { t } = useI18n();
	const [step, setStep] = useState('initial');
	const { closeAuth } = useAuthModal();
	const navigate = useNavigate();

	const onAddRole = async role => {
		const jwt = await post(Backend.OAUTH_ROLES, { role, acceptTerms: true });
		AuthUtils.load(jwt.data);
		setStep('tutorial1')
	}

  const onRegister = useCallback(async values => {
    const jwt = await post(Backend.REGISTER, values, { showErrors: false });
    AuthUtils.load(jwt.data)
    setStep('tutorial1');
  }, []);

	const goToDashboard = () => {
		navigate(routes.CMS);
		closeAuth();
	}

	return (
		<div className="JoinBusinessWizard">
			{
				step === 'initial' && (
					<svg className="JoinBusinessWizard__wave-initial" xmlns="http://www.w3.org/2000/svg" width="1280" height="928"
					     viewBox="0 0 1280 928" fill="none">
						<path fillRule="evenodd" clipRule="evenodd"
						      d="M1966.97 138.094L1307.96 1287.22C1307.96 1287.22 831.029 1482.72 596.098 1119.07C361.167 755.408 800.395 654.111 559.982 287.581C319.57 -78.9479 869.854 -491.082 869.854 -491.082L1966.97 138.094Z"
						      fill="#143799"/>
					</svg>
				)
			}
			{
				(step === 'partner' || step === 'supplier') && (
					<svg className="JoinBusinessWizard__wave-terms" version="1.1" id="Layer_1" x="0px" y="0px" width="616px" height="929px" viewBox="0 0 616 929" enableBackground="new 0 0 616 929" >
						<defs>
							<filter id="Adobe_OpacityMaskFilter" filterUnits="userSpaceOnUse" x="8.493" y="0" width="607.507" height="929">
								<feColorMatrix type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"/>
							</filter>
						</defs>
						<g mask="url(#mask0)">
							<path fill="#EAF3FF" d="M616,0H111.075c73.6,331.493-250.852,503.33-15.506,899c6.168,10.37,12.474,20.361,18.902,30H616V0z"/>
						</g>
					</svg>
				)
			}

			<header className="JoinBusinessWizard__header">
				<Link className="JoinBusinessWizard__header__logo" to="/">
					<img className="img" alt="experly logo" src={experlyLogo}/>
				</Link>
				<span className="JoinBusinessWizard__header__purpose">for business</span>
				<HeaderButtonClose variant="secondary" onClick={closeAuth} className="JoinBusinessWizard__header__close" />
			</header>
			<main className="JoinBusinessWizard__main">
				{
					step === 'initial' && (
						<JoinBusinessSupplierPartner onClick={step => setStep(step)} />
					)
				}
				{
					step === 'partner' && (
            <JoinBusinessRole
              role={Roles.PARTNER}
              isRegister={isRegister}
              onAcceptTerms={() => onAddRole(Roles.PARTNER)}
              onRegister={onRegister}
            />
						// <JoinBusinessTermsPartner onClick={() => onAddRole(Roles.PARTNER)} />
					)
				}
				{
					step === 'supplier' && (
            <JoinBusinessRole
              role={Roles.SUPPLIER}
              isRegister={isRegister}
              onAcceptTerms={() => onAddRole(Roles.SUPPLIER)}
              onRegister={onRegister}
            />
						// <JoinBusinessTermsSupplier onClick={() => onAddRole(Roles.SUPPLIER)} />
					)
				}
				{
					step === 'tutorial1' && (
						<JoinBusinessTutorial
							activeStep={1}
							title={t('join.business.tutorial1.title')}
							description={t('join.business.tutorial1.description')}
							nextLabel={t('join.business.tutorial1.next')}
							onClick={() => setStep('tutorial2')}
						/>
					)
				}
				{
					step === 'tutorial2' && (
						<JoinBusinessTutorial
							activeStep={2}
							title={t('join.business.tutorial2.title')}
							description={t('join.business.tutorial2.description')}
							nextLabel={t('join.business.tutorial2.next')}
							onClick={goToDashboard}
						/>
					)
				}
				{/*<svg viewBox="0 0 500 200">*/}
				{/*	<path d="M 0 50 C 150 150 300 0 500 80 L 500 0 L 0 0" fill="rgb(57, 27, 112)"></path>*/}
				{/*</svg>*/}
			</main>
		</div>
	);
};

export default JoinBusinessWizard;
