// @flow
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import OptionalLabel from 'components/general/input/ely/optional-label';

type ElyListTextProps = {
	name: string,
	label?: string,
	placeholder?: string,
	value: string,
	required?: boolean,
	multiline?: boolean,
	disabled?: boolean,
	inputRef?: Object,
	error?: string,
	warning?: string,
	className?: string,
	variant?: 'standard' | 'outlined' | 'filled',
	onChange?:(e: SyntheticEvent<HTMLInputElement>) => void,
	onBlur?:(e: SyntheticEvent<HTMLInputElement>) => void,
	remove?:(e: SyntheticEvent<HTMLButtonElement>) => void,
};

// TODO: Move the css from the form to the component
const ElyListText = ({
	name,
	label,
	placeholder,
	value,
	required,
	multiline,
	disabled,
	inputRef,
	error,
	warning,
	className = '',
	variant = 'standard',
	onChange,
	onBlur,
	remove
}: ElyListTextProps): React$Node => (
	<FormControl error={!!error} className={`input-toggle ${className}`}>
		<TextField
			inputRef={inputRef}
			id={name}
			name={name}
			label={<OptionalLabel label={label} required={required} />}
			placeholder={placeholder}
			value={value}
			multiline={multiline}
			disabled={disabled}
			error={!!error}
			onChange={onChange}
			onBlur={onBlur}
			variant={variant}
		/>
		<button type="button" className="input-toggle__remove icon-delete" onClick={remove} />
		{error && <FormHelperText className="u-color-problem">{error}</FormHelperText>}
		{warning && <FormHelperText className="u-color-warning">{warning}</FormHelperText>}
	</FormControl>
);

export default ElyListText;
