// @flow
import { useState } from 'react';
import useI18n from 'hooks/i18n/useI18n';
import useClickOutside from 'hooks/general/useClickOutside';
import { Formik, Form, Field } from 'formik';
import Emote from 'components/general/emote';
import 'scss/components/public/profile/profile-feeling.scss';

const InitialValues = {
	tag: null,
	status: "",
};

type ProfileFeelingProps = {
	feelings: ?Array<Object>,
	onSubmit: Object => void,
	className?: string,
};

const ProfileFeeling = ({ feelings, onSubmit, className = '' }: ProfileFeelingProps) => {
	const { t } = useI18n();
	const [emotesOpen, setEmotesOpen] = useState(false);
	const { elementRef, addListener, removeListener } = useClickOutside(() => setEmotesOpen(false));

	const onStatusChange = setFieldValue => e => {
		if (e.target.value.length <= 140) {
			setFieldValue("status", e.target.value);
		}
	};
	const onEmotesOpen = () => {
		setEmotesOpen(true);
		addListener();
	};
	const onEmoteSelect = (feeling, setFieldValue) => () => {
		setFieldValue("tag", feeling);
		setEmotesOpen(false);
		removeListener();
	};

	return (
		<div className={`profile-feeling ${className}`}>
			<Formik
				initialValues={InitialValues}
				onSubmit={(values, {resetForm}) => {
					resetForm();
					onSubmit(values);
				}}
				validate={validate}
			>
				{
					({values, setFieldValue}) => (
						<Form className="profile-feeling__form">
							<Field
								name="status"
								placeholder="How are you feeling?"
								type="text"
								onChange={onStatusChange(setFieldValue)}
								className="profile-feeling__status"
							/>
							<div className="profile-feeling__actions">
								<button
									type="submit"
									className="profile-feeling__actions__submit"
								>
									{t('profile.feeling.submit')}
								</button>
								<button
									type="button"
									className="profile-feeling__actions__mood"
									onClick={onEmotesOpen}
								>
									<Emote
										code={values.tag?.title?.toLowerCase() ?? "happy"}
										title={values.tag?.title ?? "happy"}
										className="profile-feeling__actions__mood__emote"
									/>
									{t('profile.feeling.mood')}
								</button>
								{
									emotesOpen &&
									<div ref={elementRef} className="profile-feeling__emotes-select">
										{
											feelings?.map(feeling => (
												<button
													type="button"
													key={feeling.id}
													onClick={onEmoteSelect(feeling, setFieldValue)}
													className="profile-feeling__emotes-select__item"
												>
													<Emote
														code={feeling.title.toLowerCase()}
														title={feeling.title}
														className="profile-feeling__emotes-select__icon"
													/>
													<span
														className="profile-feeling__emotes-select__icon">{feeling.title}</span>
												</button>
											))
										}
									</div>
								}
							</div>
						</Form>
					)
				}
			</Formik>
		</div>
	);
}

function validate(values) {
	const errors = {};
	if ( !values.status || values.status === '' ) errors.status = 'Required';
	if ( !values.tag ) errors.tag = 'Required';
	return errors;
}

export default ProfileFeeling;