// @flow
import { useCallback } from 'react';
import _map from 'lodash/map';
import EXIF from 'exif-js/exif';

// Convert degrees, minutes, seconds and direction to decimal number
function convertDMSToDD(degrees, minutes, seconds, direction) {
	if ( !degrees || !minutes || !seconds || !direction ) return;
	const dd = degrees + (minutes/60) + (seconds/3600);
	return (direction === 'S' || direction === 'W')
		? dd * -1
		: dd;
}

// Extract location data from Image
function extractExif(image) {
	return new Promise((resolve, reject) => {
		EXIF.getData(image, function() {

			const latDegree = EXIF.getTag(this, "GPSLatitude")?.[0]?.numerator;
			const latMinute = EXIF.getTag(this, "GPSLatitude")?.[1]?.numerator;
			const latSecond = (({numerator, denominator}) => numerator / denominator)(EXIF.getTag(this, "GPSLatitude")?.[2] ?? {});

			const lngDegree = EXIF.getTag(this, "GPSLongitude")?.[0]?.numerator;
			const lngMinute = EXIF.getTag(this, "GPSLongitude")?.[1]?.numerator;
			const lngSecond = (({numerator, denominator}) => numerator / denominator)(EXIF.getTag(this, "GPSLongitude")?.[2] ?? {});

			const latDirection = EXIF.getTag(this, "GPSLatitudeRef");
			const lngDirection = EXIF.getTag(this, "GPSLongitudeRef");

			const latitude = convertDMSToDD(latDegree, latMinute, latSecond, latDirection);
			const longitude = convertDMSToDD(lngDegree, lngMinute, lngSecond, lngDirection);

			const exif = {};
			if ( latitude && longitude ) {
				exif.geolocation = { latitude, longitude };
			}
			resolve(exif);
		});
	});
}

type ReturnProps = {
	extract: FileList => Promise<any>,
};

export default function useExtractExif(): ReturnProps {

	const extract = useCallback((images: FileList): Promise<any> => {
		return Promise.all(_map(images, image => extractExif(image)));
	}, []);

	return { extract };
}
