// @flow
import "scss/global/base/icons.scss";

type Props = {
	code: string,
	className?: string,
	style?: Object,
};

const Icon = ({ code, className = '', style }: Props): React$Node => (
	<span className={`icon icon-${code} ${className}`} style={style} />
);

export default Icon;
