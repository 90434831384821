import { PureComponent } from 'react';
import { loadingService } from './loading-service';
import experlyJustLogo from 'img/experly-just-logo.svg';

class Loading extends PureComponent {

    state={
        count: 0,
        force: false,
        renderLoading: false
    };

    constructor(props) {
        super(props);
        loadingService.register(this);
    }

    componentWillUnmount() {
        loadingService.unregister();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ( this.state.count === 0 && this.timer ) {
            clearTimeout(this.timer);
            this.timer = undefined;
        }
        if ( this.state.renderLoading === false && this.state.count > 0 ) {
            this.timer = setTimeout(() => this.setState(state => ({renderLoading: state.count > 0})), 200);
        }
    }

    set show(show) {
        if ( this.state.force ) return;
        this.setState(state => {
            if ( show )
                return { count: state.count+1 }
            else if ( !show && state.count > 1 )
                return { count: state.count-1 }
            else
                return { count: 0, renderLoading: false }
        });
    }

    set force(show) {
        if ( show )
            this.setState({force:show, count:1});
        else
            this.setState({force:show, count:0, renderLoading:false});
    }

    render() {
        const { renderLoading } = this.state;
        if ( !renderLoading ) return null;
        return (
            <div className="loader">
                <svg className="loader__filter" version="1.1">
                    <defs>
                        <filter id="gooeyness">
                            <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur"/>
                            <feColorMatrix in="blur" mode="matrix"
                                           values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 20 -10"
                                           result="gooeyness"/>
                            <feComposite in="SourceGraphic" in2="gooeyness" operator="atop"/>
                        </filter>
                    </defs>
                </svg>
                <div className="loader__dots">
                    <div className="loader__dot loader--mainDot">
                        <img alt="" className="loader__first-img loader-img" src={experlyJustLogo}/>
                    </div>
                    <div className="loader__dot"/>
                    <div className="loader__dot"/>
                    <div className="loader__dot"/>
                    <div className="loader__dot">
                        <img alt="" className="loader__second-img" src={experlyJustLogo}/>
                    </div>
                </div>
            </div>
        );
    }
}

export default Loading;