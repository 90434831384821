// @flow
import { Button, LinkButton } from 'components/general/button';

type TwitterConnectProps = {
    variant?: string,
    className?: string,
    children?: React$Node,
    onConnect: void => Promise<Object>
}

const SocialButtonTwitter = ({ variant = "link", className, children, onConnect }: TwitterConnectProps) => (
    <>
        {
            variant === "link" &&
            <LinkButton className={className} onClick={onConnect}>{children || 'Twitter'}</LinkButton>
        }
        {
            variant === "button" &&
            <Button className={className} onClick={onConnect}>{children || 'Twitter'}</Button>
        }
    </>
);

export default SocialButtonTwitter;