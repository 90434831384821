import { getIn } from 'formik';
import ElyCheckbox from 'components/general/input/ely/ely-checkbox';

const FormikCheckbox = ({field, form: {touched, errors}, ...props}) => {
	const error = (getIn(touched, field.name) && getIn(errors, field.name));
	return (
		<ElyCheckbox
			{...field}
			{...props}
			value={field.value === "true" || field.value === true || false}
			error={error}
		/>
	);
};

export default FormikCheckbox;