// @flow
import axios from 'axios';
import qs from 'query-string';
import AuthUtils from 'auth/AuthUtils';
import { loginRefresh } from 'auth/auth-actions';
import type { BackendProps } from 'actions/backend/backend';
import { getErrorMessages } from 'actions/backend/error-utils';
import { loadingService } from 'components/general/loading';
import { notificationService } from 'components/general/notification';

type AjaxProps = {
	url: string,
	params?: any | Object,
	data?: any,
	showLoading?: boolean,
	success?: (res: Object) => void,
	failure?: (err: Object) => void
};

type ConfigProps = {
	params?: Object | string | number,
	data?: any,
	showLoading?: boolean,
	showErrors?: boolean,
	success?: Object => void,
	failure?: Object => void
}

export async function ajax({showLoading = true, success, failure, ...config}: AjaxProps): Promise<any> {

	// If there is no pending refresh promise and there is an existing access token but is expired
	if ( !AuthUtils.refreshPromise && !!AuthUtils.service?.accessToken && AuthUtils.isTokenExpired() ) {
		AuthUtils.refreshPromise = loginRefresh(false);
	}

	// If there is a pending refresh promise wait for it to resolve
	if ( AuthUtils.refreshPromise ) {
		await AuthUtils.refreshPromise;
		AuthUtils.refreshPromise = null;
	}

	const url = config.params && typeof config.params !== 'object'
		? `${config.url}/${config.params}`
		: config.url;

	const params = config.params && typeof config.params === 'object'
		? new URLSearchParams(qs.stringify(config.params))
		: undefined;

	// Continue with the actual backend call now that refresh token has been dealt with
	showLoading && loadingService.show();
	return axios({ ...config, url, params })
		.then(res => {
			if ( success ) success(res);
			return res;
		})
		.catch(err => {
			if ( failure ) failure(err);
			throw err; // we need this cause to propagate the error
		})
		.finally(() => {
			showLoading && loadingService.hide();
		});
}

export function get(call: BackendProps, params?: ?(string | Object) , config?: ConfigProps): Promise<any> {
	return ajax({...config, url: call.url, params, method:'get'})
			.catch(getCatch(config));
}

export function post(call: BackendProps, data?: ?(string | Object) , config?: ConfigProps): Promise<any> {
	return ajax({...config, url: call.url, data, method:'post'})
			.catch(getCatch(config));
}

export function put(call: BackendProps, data?: ?(string | Object) , config?: ConfigProps): Promise<any> {
	return ajax({...config, url: call.url, data, method:'put'})
			.catch(getCatch(config));
}

export function del(call: BackendProps, params?: ?(string | Object) , config?: ConfigProps): Promise<any> {
	return ajax({...config, url: call.url, params, method:'delete'})
			.catch(getCatch(config));
}

export function getCatch(config: Object = {}): Object => void {
	const { showErrors = true, showNotifications = true } = config;
	return (err: Object) => {
		if ( showErrors ) {
			const errMsgs = getErrorMessages([err]).flat().filter(error => !!error);
			console.log(errMsgs);
			showNotifications && notificationService.show({
				level: "error",
				title: "Error",
				messages: errMsgs
			});
		}
		throw err;
	}
}
